import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function Checkout() {
    
    const location = useLocation();

    React.useEffect(() => {
        window.location.replace(`${location.state.payment_page}`)
      }, [])

  return (
    <div>
      <p style={{textAlign:"center"}}>You will be redirected to payment screen.</p>
     
    </div>
  )
}
