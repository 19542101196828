import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'
import { country_code_array } from '../functions';
import { Helmet } from 'react-helmet';
export default function Address_book() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const navigate = useNavigate()
    const userid = sessionStorage.getItem('userid')
    const [countries, setcountries] = useState(country_code_array);
    const [collectionDetails, setcollectionDetails] = useState({
        country:'AE',
        name:'xx'
    })
    const [collectionaddress, setcollectionaddress] = useState()
    const [deliveryaddress, setdeliveryaddress] = useState()
    const [editcollectionaddress, seteditcollectionaddress] = useState()
    const [collectioncount, setcollectioncount] = useState()
    const [deliverycount, setdeliverycount] = useState()
  const [editform, seteditform] = useState(false)
  const [addform, setaddform] = useState(false)
  const [addressdisplay, setaddressdisplay] = useState(true)
  const [editformdelivery, seteditformdelivery] = useState(false)
  const [addformdelivery, setaddformdelivery] = useState(false)
  const [addressdisplaydelivery, setaddressdisplaydelivery] = useState(true)
const [type, settype] = useState('collection')
  function showaddressform(val){
    if(val=='collection'){
        setaddform(true)
        seteditform(false)
        setaddressdisplay(false)
        setaddressdisplaydelivery(false)
    }else{
       navigate('/my-account/delivey-address')
    }
   
  }

    function savecollection(){
      
        const API_PATH=`${SERVER_URL}save_address.php`;
        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: {user:userid,collectionDetails:collectionDetails,type:'collection'}
        })
        .then((res)=>{
            console.log(res.data)
            window.location.reload();
    
        })
        .catch(err=>console.log(err))
    }

    const addresspath=`${SERVER_URL}get_address.php`;
    useEffect(()=>{
        axios({
          method:"post",
          url:`${addresspath}`,
          data:userid
        })
        .then((res)=>{
       
            setcollectionaddress(res.data.collection)
            setcollectioncount(res.data.collection.length)
            setdeliveryaddress(res.data.delivery)
            setdeliverycount(res.data.delivery.length)
         
        })
        .catch((err)=>console.log(err))
        },[])

        function editAddress(val,type){
            setcollectionDetails(val)
            settype(type)
            seteditform(true)
            setaddform(false)
            setaddressdisplay(false)
            setaddressdisplaydelivery(false)
        
        }

      

        function updateaddress(){
          
            const API_PATH=`${SERVER_URL}save_address.php?type=update`;
            axios({
                method: 'post',
                url: `${API_PATH}`,
                data: {user:userid,collectionDetails:collectionDetails,type:type}
            })
            .then((res)=>{
                console.log(res.data)
                // navigate('/my-account/address-book')
                window.location.reload();
        
            })
            .catch(err=>console.log(err))
        }

        function deleteAddress(val,type){
           
            window.confirm('Are you sure you wish to delete this item?') ? deletethisaddress(val,type) : deletethisaddress(0,type)

        }
       
        
        function deletethisaddress(val,type){
            if(val>0){
               
           const API_PATH=`${SERVER_URL}save_address.php?type=delete`;
            axios({
                method: 'post',
                url: `${API_PATH}`,
                data: {user:userid,collectionDetails:val,type:type}
            })
            .then((res)=>{
                console.log(res.data)
                // navigate('/my-account/address-book')
               window.location.reload();
        
            })
            .catch(err=>console.log(err))
           
        }
        }
    return (


        <div className="wallet-plan addressbook">
            <Helmet>
        <title>Time Express | Address Book</title>
      </Helmet>
            <div className="container myparctab" >

                <div className="container-fluid my_quote">
                    <div className="row">

                        <Sidebar />
                        <div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">
{addressdisplay?
                            <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb", marginTop: "2%", borderRadius: "6px" }}>
                                <h3 className="panel-title comaprison_hedding" style={{ padding: "2%", background: "#f5f5f5" }}>My Transactions</h3>
                                <a className="add_btn" onClick={()=>showaddressform('collection')}> + Add Collection Address</a>
                                <div className="table-responsive " style={{ padding: '25px' }}>


                                    <table className="table-bordered table-striped table-hover table wallet-plan">
                                        <thead>
                                            <tr>
                                                <th>NAME</th>
                                                <th>EMAIL</th>
                                                <th>PHONE</th>
                                                <th>ADDRESS</th>
                                                <th>COUNTRY</th>
                                                <th colSpan={4}>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>






                                         {
              [...Array(collectioncount)].map((e, i) =><>
              {collectionaddress && collectionaddress[i]!=''?(
              <tr key={i}>
                <td>{collectionaddress[i]['name']}</td>
                <td>{collectionaddress[i]['email']}</td>
                <td>{collectionaddress[i]['phone']}</td>
                <td>{collectionaddress[i]['address']}</td>
                <td>{countries[`${collectionaddress[i]['country']}`]}</td>
                <td><a className="btn noradius" onClick={()=>editAddress(collectionaddress[i],'collection')}>Edit</a></td>
                <td><a className="btn noradius" onClick={()=>deleteAddress(collectionaddress[i]['id'],'collection')}>Delete</a></td>
              </tr>
              ):null
}
</>  
             )
            }  

                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>:<></>
}
                            {/* Add Collection Address */}

                            {addform?

                            <div className="col-md-12 col-sm-12 col-xs-12" style={{border: "1px solid #c3bbbb", marginTop: "2%", borderRadius: "6px" }}>
                                <h3 className="panel-title comaprison_hedding" style={{ padding: "2%", background: "#f5f5f5" }}>Collection Address</h3>


                                <form>
                                    <div className="row" style={{ marginLeft: "15px", marginRight: "15px" }}>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" name="collection_name" onChange={(e) => setcollectionDetails({ ...collectionDetails, name: e.target.value })} id="collection_name" maxLength={50} placeholder="Name" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Email<span className="required">*</span></label>
                                                <input type="text" className="form-control" name="collection_email" onChange={(e) => setcollectionDetails({ ...collectionDetails, email: e.target.value })} id="collection_email" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Phone<span className="required">*</span></label>
                                                <div className="input-group new-group">
                                                    <div className="input-group-addon" style={{ paddingTop: "6%" }}></div>
                                                    <input type="text" className="form-control" name="collection_phone" id="collection_phone" onChange={(e) => setcollectionDetails({ ...collectionDetails, phone: e.target.value })} placeholder="Phone" style={{ margin: 0 }} />
                                                    <input type="hidden" name="collection_phone_code" id="collection_phone_code" defaultValue="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" style={{ marginLeft: "15px", marginRight: "15px" }}>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Address</label>
                                                <input type="text" className="form-control" name="collection_address" onChange={(e) => setcollectionDetails({ ...collectionDetails, address: e.target.value })} id="collection_address" placeholder="Address" />
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">City/Town</label>
                                                        <input type="text" className="form-control" name="collection_city" onChange={(e) => setcollectionDetails({ ...collectionDetails, city: e.target.value })} id="collection_city" placeholder="City/Town" />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Country<span className="required">*</span></label>

                                                        <select className="form-select select-export-package" name="delivery_to" onChange={(e) => setcollectionDetails({ ...collectionDetails, country: e.target.value })}>
                                                            {

                                                                Object.entries(countries).map(([k, v]) => (
                                                                    <option key={k} value={k} >{v}</option>
                                                                ))
                                                            }

                                                        </select>

                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Zipcode</label>
                                                        <input type="text" className="form-control cd" name="collection_zipcode" onChange={(e) => setcollectionDetails({ ...collectionDetails, zipcode: e.target.value })} id="collection_zipcode" placeholder="Zipcode" />
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-12 text-right" > 
                                    <input type="button" defaultValue="Submit" name="save" onClick={savecollection} className="btn btn-success pull-right" id="save" style={{float:"right",margin:"3%"}} />
                                    </div>
                                    </div>
                                  
                                </form>
                                
                            </div>:null
                            }

  {/* Edit Collection Address */}
                            { editform ?
                        
                          <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb", marginTop: "2%", borderRadius: "6px" }}>
                          <h3 className="panel-title comaprison_hedding" style={{ padding: "2%", background: "#f5f5f5" }}>Edit Address</h3>


                          
                              <div className="row" style={{ marginLeft: "15px", marginRight: "15px" }}>
                                  <div className="col-md-4">
                                      <div className="form-group">
                                          <label htmlFor="exampleInputEmail1">Name<span className="required">*</span></label>
                                          <input type="text" className="form-control" name="collection_name" value={collectionDetails.name} onChange={(e) => setcollectionDetails({ ...collectionDetails, name: e.target.value })} id="collection_name" maxLength={50} placeholder="Name" />
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="form-group">
                                          <label htmlFor="exampleInputEmail1">Email<span className="required">*</span></label>
                                          <input type="text" className="form-control" name="collection_email" value={collectionDetails.email} onChange={(e) => setcollectionDetails({ ...collectionDetails, email: e.target.value })} id="collection_email" placeholder="Email" />
                                      </div>
                                  </div>
                                  <div className="col-md-3">
                                      <div className="form-group">
                                          <label htmlFor="exampleInputEmail1">Phone<span className="required">*</span></label>
                                          <div className="input-group new-group">
                                              <div className="input-group-addon" style={{ paddingTop: "6%" }}></div>
                                              <input type="text" className="form-control" name="collection_phone" value={collectionDetails.phone} id="collection_phone" onChange={(e) => setcollectionDetails({ ...collectionDetails, phone: e.target.value })} placeholder="Phone" style={{ margin: 0 }} />
                                              <input type="hidden" name="collection_phone_code" id="collection_phone_code" value="" />
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="row" style={{ marginLeft: "15px", marginRight: "15px" }}>
                                  <div className="col-md-4">
                                      <div className="form-group">
                                          <label htmlFor="exampleInputEmail1">Address</label>
                                          <input type="text" className="form-control" name="collection_address"  value={collectionDetails.address} onChange={(e) => setcollectionDetails({ ...collectionDetails, address: e.target.value })} id="collection_address" placeholder="Address" />
                                      </div>
                                  </div>

                                  <div className="col-md-8">
                                      <div className="row">
                                          <div className="col-md-4">
                                              <div className="form-group">
                                                  <label htmlFor="exampleInputEmail1">City/Town</label>
                                                  <input type="text" className="form-control" name="collection_city" value={collectionDetails.city}  onChange={(e) => setcollectionDetails({ ...collectionDetails, city: e.target.value })} id="collection_city" placeholder="City/Town" />
                                                  
                                              </div>
                                          </div>
                                          <div className="col-md-5">
                                              <div className="form-group">
                                                  <label htmlFor="exampleInputEmail1">Country<span className="required">*</span></label>

                                                  <select className="form-select select-export-package" name="delivery_to" onChange={(e) => setcollectionDetails({ ...collectionDetails, country: e.target.value })}>
                                                      {

                                                          Object.entries(countries).map(([k, v]) => (
                                                              <option key={k} value={k} selected={k === collectionDetails.country}>{v}</option>
                                                          ))
                                                      }

                                                  </select>

                                              </div>
                                          </div>
                                          <div className="col-md-3">
                                              <div className="form-group">
                                                  <label htmlFor="exampleInputEmail1">Zipcode</label>
                                                  <input type="text" className="form-control cd" name="collection_zipcode" value={collectionDetails.zipcode}  onChange={(e) => setcollectionDetails({ ...collectionDetails, zipcode: e.target.value })} id="collection_zipcode" placeholder="Zipcode" />
                                              </div>
                                          </div>


                                      </div>
                                  </div>

                                  <div className="col-md-12 col-sm-12 text-right" > 
                              <input type="button" defaultValue="Submit" name="save" onClick={updateaddress} className="btn btn-success pull-right" id="save" style={{float:"right",margin:"3%"}} />
                              </div>
                              </div>
                            
                        
                          
                      </div>:<></>
                   
                        }

                        </div>


         {/* delivery address */}  
         <div className="col-md-3"></div>                 
<div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">
{addressdisplaydelivery?
    <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb", marginTop: "2%", borderRadius: "6px" }}>
        <h3 className="panel-title comaprison_hedding" style={{ padding: "2%", background: "#f5f5f5" }}>Delivery Address</h3>
        <a className="add_btn" onClick={()=>showaddressform('delivery')}> + Add Delivery Address</a>
        <div className="table-responsive " style={{ padding: '25px' }}>


            <table className="table-bordered table-striped table-hover table wallet-plan">
                <thead>
                    <tr>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>PHONE</th>
                        <th>ADDRESS</th>
                        <th>COUNTRY</th>
                        <th colSpan={4}>ACTION</th>
                    </tr>
                </thead>
                <tbody>






                 {
[...Array(deliverycount)].map((e, i) =><>
{deliveryaddress && deliveryaddress[i]!=''?(
<tr key={i}>
<td>{deliveryaddress[i]['name']}</td>
<td>{deliveryaddress[i]['email']}</td>
<td>{deliveryaddress[i]['phone']}</td>
<td>{deliveryaddress[i]['address']}</td>
<td>{countries[`${deliveryaddress[i]['country']}`]}</td>
<td><a className="btn noradius" onClick={()=>editAddress(deliveryaddress[i],'delivery')}>Edit</a></td>
<td><a className="btn noradius" onClick={()=>deleteAddress(deliveryaddress[i]['id'],'delivery')}>Delete</a></td>
</tr>
):null
}
</>  
)
}  

                    
                </tbody>
            </table>
        </div>
    </div>:<></>
}
    {/* Add Delivery Address */}



</div>










                    </div>


                </div>
            </div>

        

            



        </div>
    )
}
