import React, { useEffect, useState } from 'react'
import '../../Help.css';
import { useNavigate, Link , useParams, Route, Routes} from 'react-router-dom'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import banner from '../../images/banners/contact.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhone,faEnvelope} from '@fortawesome/free-solid-svg-icons'

const phoneicon = <FontAwesomeIcon icon={faPhone} style={{color:'black',border:'none',height:'14px'}}/>
const emailicon = <FontAwesomeIcon icon={faEnvelope} style={{color:'black',border:'none',height:'14px',padding:'1px'}}/>
export default function Contact() {
    const [tabpath, settabpath] = useState('fuels')
    const [count, setCount] = useState(0)
    //  let role=sessionStorage.getItem('userid')
    const [help, sethelp] = useState()
    
    return (

        <>
          <Helmet>
        <title>Time Express | Contact</title>
      </Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;0,600;0,700;1,500&display=swap" rel="stylesheet" />
            <section id="cd-intro">
                <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 your_business text-center">
                                <div className="business_content">
                                <h1 className='h1-title'>CONTACT TIME EXPRESS </h1>
                                    <p>We're here to help</p>
                                    {/* <p>Search for answers or explore regular help topics below</p> */}
                                </div>
                            </div>
                            {/* <div className="form-help">
                                <form role="search" method="get" id="searchform" className="searchform" action="https://www.timexpress.ae/">
                                    <div><label className="screen-reader-text" htmlFor="s">Search for:</label>
                                        <input type="text" defaultValue='' name="s" id="s" placeholder="Ask a question or search a topic" />
                                        <input type="submit" id="searchsubmit" defaultValue="Search" /></div>
                                </form>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="container contact mt-5">
          <h6 className="section-title h1" style={{textAlign:"center",padding:"3%"}}>Contact channels to suit you</h6>
          <div className="row">
            <div className="col-xs-12 helpnav ">
              {/* <nav> */}
                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                 <a className="nav-item nav-link " onClick={() => setopen('call')}   >Contact Time Express</a>
                  <a className="nav-item nav-link" onClick={() => setopen('help')}   aria-selected="false">Help</a>
                  <a className="nav-item nav-link" onClick={() => setopen('tracking')}  role="tab"  aria-selected="false">Your Tracking</a>
                  
                  </div>

                 
                  
                  { count=="0" && tabpath=='call'?(
                    <div className="details">
 <div  className="tab-content py-4 px-4 px-sm-0" id="nav-tabContent"  >
 <h2> Contact Time Express</h2>
<div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
  Time Express Parcels<br/>
  24, 61A Street, Garhoud<br/>
  Dubai, United Arab Emirates<br/>
  P.O. Box: 80266<br/>
  {phoneicon} +(971) 4 255 5563<br/>
{emailicon} info@timexpress.ae<br/>
  Opening times: Monday to Friday: 8am – 7pm. Saturday: 8am – 5pm.
 
  
{/* Want to speak to us? If you haven’t found your answer on the website or you just need to speak to someone, you can call us on: +(971) 4 255 5563. Remember to have your parcel tracking ID ready. Opening times: Monday to Saturday 8am – 7pm. Saturday 9am – 1pm. */}
<br/>
<a className style={{textDecoration:"none" , padding: '10px 23px', color: '#fff', backgroundColor: '#7f3f98', display: 'inline-block', marginTop: '12px'}} href="tel:600503327">Call Now</a>
</div>
</div>
</div>
                  ):null
                  }

{ count=="0" && tabpath=='help'?(
  <div className="details">
  <div  className="tab-content py-4 px-4 px-sm-0" id="nav-tabContent"  >
 <h2> Courier return package</h2>
<div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
Full of information, right at your fingertips! If you are unsure of what to do next, where your parcel is or what the tracking is telling you then have a look at the <Link to="/help" target="_blank" rel="noopener noreferrer">Time Express help</Link> centre. It's full of information that should answer most of the questions you have.
</div>
</div>
</div>
                  ):null
                  }
                 
                {  count=="0" && tabpath=='tracking'?(
                   <div className="details">
 <div  className="tab-content py-4 px-4 px-sm-0" id="nav-tabContent"  >
 <h2>Your Tracking</h2>
<div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
See where your parcel is, when you want! This is the best way to see where your parcel is and when it's planned to arrive. Just visit <Link to="/track" target="_blank" rel="noopener noreferrer">Track</Link>,
 you will need your parcel reference number or your "Sorry we missed you" card ID.

</div>

</div>
</div>

                  ):null
                  }
                   
                   
                 
             
              
                  
              
              
              {/* </nav> */}
             
             
              
            </div>
          </div>
        </div>
            </section>

            <section id="tabs">
       
      </section>
         

 



        </>
    )

    function setopen(tab){
     
      if(tabpath===tab){ // already displayed
        
        if(count==0){
          setCount(1);
        }else{
          setCount(0)
        }
       
      }else{
        setCount(0);
        settabpath(tab)
      }
      console.log(tab)
      if(count==0){
        axios.get(`http://127.0.0.1:3000/${tab}`)
        .then((res)=>sethelp(res.data))
        .catch(err=>console.log(err))
        console.log(help)
        return (
          <div className="details">
              <h2>Fuel {tabpath}</h2>
              <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                      <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                       11111111 ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
                      </div>
                 
                    </div>
          </div>
       
       )
         
        }

    }

  
      
     
}





