import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import Sidebar from './Sidebar'

export default function After_wallet_payment() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [searchParams, setSearchParams] = useSearchParams();
    const orderno=searchParams.get("ref")

    const [paymentstats, setstatus] = useState()
    const API_payment=`${SERVER_URL}check_wallet_payment.php`;
    useEffect(() => {
      axios({
        method: 'post',
        url: `${API_payment}`,
        data: orderno
      })
      .then((res)=>{
        console.log(res.data)
      setstatus(res.data)
      
      })
      .catch((err)=>console.log(err))
        }, [])
    return (
        <div className="my_account_tracking">
          <Helmet>
        <title>Time Express | After Payment</title>
      </Helmet>
                    <div className="container myparctab" >
        
                        <div className="container-fluid my_quote">
                            <div className="row">
        
                                <Sidebar />
                                <div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">
                                  <h3>{paymentstats}</h3>
                                  <Link style={{backgroundColor: '#2c4079', color: '#fff', padding: '14px 16px', display: 'inline-block', fontSize: '16px', fontWeight: 'bold'}} to="/my-account/my-wallet">Go to My Wallet</Link>
                                  </div>
                                    </div>
                                    </div>
                              </div>
        
            </div>
          )
}
