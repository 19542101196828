import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import '../../Pricing.css'
import banner from '../../images/banners/pricing.jfif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck,faXmark} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import axios from 'axios'
const check = <FontAwesomeIcon icon={faCheck} style={{color:'#117711'}} />
const cross = <FontAwesomeIcon icon={faXmark} style={{color:'red'}} />
export default function Pricing() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const userid = sessionStorage.getItem('userid');
    const [is_corporate, setis_corporate] = useState(0)
    const SubscribedAPI = `${SERVER_URL}get_subscription.php`;
    const [planexist, setplanexist] = useState()
    const [planenable1, setplanenable1] = useState('')
    const [planenable2, setplanenable2] = useState('')
    const [planenable3, setplanenable3] = useState('')
    const [planebtn1, setplanebtn1] = useState('BUY')
    const [planebtn2, setplanebtn2] = useState('BUY')
    const [planebtn3, setplanebtn3] = useState('BUY')
    
 
    useEffect(() => {
      if (userid && userid > 0) {
        axios({
          method: "post",
          url: `${SubscribedAPI}`,
          data: userid
        })
          .then((res) => {
            console.log('subscription')
            console.log(res.data)
            setplanexist(res.data.plan_name)
            if(res.data.plan_name=='personal'){
              setplanebtn1('RENEW')
              setplanenable2('disabled')
              setplanenable3('disabled')
            }
            if(res.data.plan_name=='basic'){
              setplanenable1('disabled')
              setplanebtn2('RENEW')
              setplanenable3('disabled')
            }
            if(res.data.plan_name=='premium'){
              setplanenable1('disabled')
              setplanenable2('disabled')
              setplanebtn3('RENEW')
            }
    
          })
          .catch((err) => console.log(err))
      }
    }, [])

   useEffect(() => {
    if(sessionStorage.getItem('is_corporate')){
      setis_corporate(sessionStorage.getItem('is_corporate'))
    }
   }, [])
   

console.log(planenable2)
  return (
    <div className="improvelive">
       <Helmet>
        <title>Time Express | Pricing</title>
      </Helmet>
       <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',backgroundPosition:'top',maxHeight:'100px' }}>
                   <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead mainhead2">DISCOUNTED SME’s PRICING  </h1>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
              {is_corporate==0?(

              
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content">
              <div className="become_driver im_lives">

              <div className="row">
        <div className="col-xs-12 col-md-4">
          <div className="pricing pricing-primary">
             <div className="cnrflash">
             <div className="pricing-heading">
              <h2 className="pricing-title">
                Personal</h2>
                <p>Great for freelancers</p>
                <p style={{fontWeight:'bold'}}>5 Parcels</p>
            </div>
              <div className="cnrflash-inner">
                <span className="cnrflash-label">UP TO 
                  <br />
                  5 Parcels/ Mth</span>
              </div>
            </div>
            
            <div className="pricing-body">
              <div className="the-price">

                <h1>Pay 50 AED</h1>
                <h5>& Get 10% Off!</h5>
              </div>
              <table className="table table2">
                <tbody><tr className='instruction'>
                    <td>
                    Parcels & Documents Up to 5/Month
                    </td>
                  </tr>
                  <tr className="active instruction">
                    <td>
                    Weight Up to 5 kg
                    </td>
                  </tr>
                  <tr className="active countrycheck">
                    <td>
                   GCC &nbsp; {check} 
                    </td>
                  </tr>
                  <tr className='countrycheck'>
                    <td>
                   Europe/UK &nbsp; {check} 
                    </td>
                  </tr>
                  <tr className='countrycheck'>
                    <td>
                    USA/Canada &nbsp; {cross} 
                    </td>
                  </tr>
                  <tr className="active countrycheck">
                    <td>
                   Asia &nbsp; {cross} 
                    </td>
                  </tr>
                 
                  <tr className="active countrycheck">
                    <td>
                    Africa &nbsp; {cross} 
                    </td>
                  </tr>
                
                  <tr className="active instruction">
                    <td>
                    Import &nbsp; {cross} 
                    </td>
                  </tr>
                  <tr className="active instruction">
                    <td>
                  Export &nbsp; {check}  
                    </td>
                  </tr>
                </tbody></table>
            </div>
            <div className="pricing-footer">
               <h3>Billed Monthly - 50 AED</h3>
               {userid && userid >0?(
                <Link to="/subscribe/personal"  className="btn btn-sm buy_btn" role="button" style={{pointerEvents: planenable1 === 'disabled' ? 'none' : ''}}>{planebtn1}</Link>
               ):(
                <Link to="/pricing-login"  className="btn btn-sm buy_btn" role="button" >{planebtn1}</Link>
               ) }
              
             </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-4">
          <div className="pricing pricing-success">
            <div className="cnrflash">
            <div className="pricing-heading">
              <h2 className="pricing-title">
                Basic</h2>
                <p>Ideal for small teams</p>
                <p style={{fontWeight:'bold'}}>10 Parcels</p>
            </div>
              <div className="cnrflash-inner">
              <span className="cnrflash-label">UP TO 
                  <br />
                  10 Parcels/ Mth</span>
              </div>
            </div>
            
            <div className="pricing-body">
              <div className="the-price">
              <h1>Pay 300 AED</h1>
                <h5>& Get 20% Off!</h5>
               
              </div>
              <table className="table table2">
                <tbody><tr className='instruction'>
                    <td>
                    Parcels & Documents Up to 10/Month
                    </td>
                  </tr>
                  <tr className="active instruction">
                    <td>
                    Weight Up to 5 kg
                    </td>
                  </tr>
                  <tr className="active countrycheck">
                    <td>
                    GCC &nbsp; {check} 
                    </td>
                  </tr>
                  <tr className='countrycheck'>
                    <td>
                    Europe/UK &nbsp; {check} 
                    </td>
                  </tr>
                  <tr className='countrycheck'>
                    <td>
                    USA/Canada &nbsp; {check} 
                    </td>
                  </tr>
                  <tr className="active countrycheck">
                    <td>
                    Asia &nbsp; {cross} 
                    </td>
                  </tr>
                
                  <tr className="active countrycheck">
                    <td>
                    Africa &nbsp; {cross} 
                    </td>
                  </tr>
                
                  <tr className="active instruction">
                    <td>
                    Import &nbsp; {check} 
                    </td>
                  </tr>
                  <tr className="active instruction">
                    <td>
                    Export &nbsp; {check} 
                    </td>
                  </tr>
                </tbody></table>
            </div>
            <div className="pricing-footer">
               <h3>Billed Monthly - 300 AED</h3>
               {userid && userid >0?(
                <Link to="/subscribe/basic"  className="btn btn-sm buy_btn" role="button" style={{pointerEvents: planenable2 === 'disabled' ? 'none' : ''}} >{planebtn2}</Link>
               ):(
                <Link to="/pricing-login"  className="btn btn-sm buy_btn" role="button" disabled={planenable2}>{planebtn2}</Link>
               ) }
              </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-4">
          <div className="pricing pricing-info">
         
          <div className="cnrflash">
          <div className="pricing-heading">
              <h2 className="pricing-title">
                Premium</h2>
                <p>Most popular!</p>
                <p style={{fontWeight:'bold'}}>15 Parcels</p>
            </div>
              <div className="cnrflash-inner">
              <span className="cnrflash-label">UP TO 
                  <br />
                  15 Parcels/ Mth</span>
              </div>
            </div>
          
            <div className="pricing-body">
              <div className="the-price">
              <h1>Pay 500 AED</h1>
                <h5>& Get 30% Off!</h5>
               
              </div>
              <table className="table table2">
                <tbody><tr className='instruction'>
                    <td>
                    Parcels & Documents Up to 15/Month
                    </td>
                  </tr>
                  <tr className="active instruction">
                    <td>
                    Weight Up to 5 kg
                    </td>
                  </tr>
                  <tr className="active countrycheck">
                    <td>
                     GCC &nbsp; {check}
                    </td> 
                  </tr>
                  <tr className='countrycheck'>
                    <td>
                    Europe/UK &nbsp; {check} 
                    </td>
                  </tr>
                  <tr className='countrycheck'>
                    <td>
                    USA/Canada &nbsp; {check} 
                    </td>
                  </tr>
                  <tr className="active countrycheck">
                    <td>
                    Asia &nbsp; {check} 
                    </td>
                  </tr>
                 
                  <tr className="active countrycheck">
                    <td>
                    Africa &nbsp; {check} 
                    </td>
                  </tr>
                
                  <tr className="active instruction">
                    <td>
                    Import &nbsp; {check} 
                    </td>
                  </tr>
                  <tr className="active instruction">
                    <td>
                    Export &nbsp; {check} 
                    </td>
                  </tr>
                </tbody></table>
            </div>
            <div className="pricing-footer">
                <h3>Billed Monthly - 500 AED</h3>
                {userid && userid >0?(
                <Link to="/subscribe/premium"  className="btn btn-sm buy_btn" role="button" style={{pointerEvents: planenable3 === 'disabled' ? 'none' : ''}}>{planebtn3}</Link>
               ):(
                <Link to="/pricing-login"  className="btn btn-sm buy_btn" role="button">{planebtn3}</Link>
               ) }
              
               </div>
          </div>
        </div>
      </div>
              
  </div>
 
  </div>
            </main>
            </div>
            ):<>
            <div className='corporate' style={{padding:'3%',textAlign:'center'}}>
            <p style={{background: '#d8bfbf',padding: '3%'}}>Your account is already integrated with corporate discount.</p>
           </div>
           </>
}
            </div>

            </div>
            </div>
    </div>
  )
}
