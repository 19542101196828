import React from 'react'
import { Helmet } from 'react-helmet'
import '../../Custom.css'
import banner from '../../images/banners/login.jpg'
export default function Rearrange_delivery() {
  return (
    <div className="improvelive">
        <Helmet>
        <title>Time Express | Re-arrange A Delivery</title>
      </Helmet>
       <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">RE-ARRANGE A DELIVERY</h1>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content"><div className="become_driver im_lives">
            <div className="entry-content"><h1 style={{display: 'none'}}>Re-arrange a Delivery</h1><h3>Get the Parcel You Missed Redelivered</h3><p>If we missed you when we tried to deliver your parcel, you can schedule redelivery online using the peach-colored slip left by your carrier to look up your items and reschedule delivery.</p><p>For same day redelivery, make sure your request is submitted by 10am Saturday – Thursday. Redelivery same day may not be available outside Dubai city limits.</p><p>Please check our customer service for pickup and delivery information.</p></div>
  </div>
  <h1 style={{display: 'none'}}>medical services</h1>
  
  </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
