import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import banner from '../../images/banners/login.jpg'
import { useLocation } from "react-router-dom"
import logo from '../../images/logo-purple.png'
import { country_code_array } from './functions';
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
export default function BulkAWBAramex() {
    sessionStorage.removeItem('batch_id');
    const location = useLocation();
    const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [created, setcreated] = useState('');
    let newDate = new Date()
    let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
    const today=`${year}/${month}/${date}`
    const [countries, setcountries] = useState(country_code_array);
    const ref = useRef();
    const [parceldetailscount, setparceldetailscount] = useState(0)
    const [parceldetailscontent, setparceldetailscontent] = useState('')
    useEffect(()=>{
        const API_PATH2 = `${SERVER_URL}get_batch_order_details.php`;
        const data= new FormData();
        data.append('batch_id',location.state.batch_id);
        data.append("userid", location.state.userid);
        data.append("current", "");
      
        axios.post(API_PATH2,data,{})
          .then((res) => {
            console.log(res.data.response_arr)
            setparceldetailscontent(res.data.response_arr)
  setparceldetailscount(res.data.response_arr.length)

  res.data.response_arr.forEach(orders => {
    send_label(orders.orderid)
   
  });
          })
          .catch((err) => console.log(err))
    },[])
   

    function send_label(orderids) {
      console.log('email funct ', orderids)
      const API_email = `${INTEGRA_URL}label_email.php`;
      axios({
        method: 'post',
        url: `${API_email}`,
        data: orderids
      })
        .then((res) => {
          console.log(res.data)
          //navigate(res.data)
  
  
        })
        .catch((err) => console.log(err));
    }
    return(
        <div className="awblabel ">
            <Helmet>
                <title>Time Express | Bulk Order Confirmation</title>
            </Helmet>

            <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 your_business text-center">
                            <div className="business_content">                    
                                <h1 className='h1-title'>BULK ORDER DETAIL</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <style type="text/css" media="print" dangerouslySetInnerHTML={{__html: "\n  @page { size: landscape; }\n   @page { margin: 0; size: A4;}\n   @media print {\n  footer {\n    position: fixed;\n    bottom: 0;\n  }\n\n  .content-block, p {\n    page-break-inside: avoid;\n  }\n\n  html, body {\n    width: 210mm;\n    height: 297mm;\n  }\n}\n" }} />
   
            <div className="servies-content become_driver last_order thank-you-page parsel_container pages_content" >
<p className="received_text" style={{textAlign: 'left',width:'100%'}}>
Please print the label and attach to your package, our driver will come shortly to collect your parcel. A PDF copy of your label is also available to download from your confirmation email. </p>

      <div className="table-responsive " ref={ref}>
            {
                             [...Array(parceldetailscount)].map((e,i)=><>{
                                parceldetailscount && parceldetailscount[i]!=''?(
                                    
                                    <div className="table-responsive " ref={ref}>
                                        <p className="ss" ><a href={parceldetailscontent[i]['aramex_label']} download="Label" target="_blank">Download / Print Label </a> </p>
 
                                    <embed src={`${parceldetailscontent[i]['aramex_label']}#toolbar=0`} style={{width: "100%",height: "600px",border:"none"}}></embed>
                                    </div>
                  
                              ):null

                            }</>)
                          }
                 </div>
            
     
        </div>
        </div>
    )
}