import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'
import Modal from 'react-modal';
import walletimg from '../../../images/wallet-1.jpg'
import { Helmet } from 'react-helmet';
export default function My_subscriptions() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const navigate = useNavigate()
const [amount, setamount] = useState()
const [wallet, setwallet] = useState()
const [current, setcurrent] = useState(10)
const [page, setpage] = useState(0)
const userid=sessionStorage.getItem('userid')
function payamount(){

    const API_PATH=`${SERVER_URL}generate_wallet_payment.php`;
    axios({
        method: 'post',
        url: `${API_PATH}`,
        data: {userid:userid,amount:amount}
    })
    .then((res)=>{
        console.log(res.data)
       navigate('/checkout', { state: { orderno: 1, payment_page: res.data } })

    })
    .catch(err=>console.log(err))
}
const [showbooking, setshowbooking] = useState(true)
const [showtrans, setshowtrans] = useState(false)
const [transactions, settransactions] = useState()
const [counttransactions, setcounttransactions] = useState()
const [booking, setbooking] = useState()
const [countbooking, setcountbooking] = useState()
const [parcelcount2, setparcelcount2] = useState(0)
const [parcel_pending, setparcel_pending] = useState(0)
const [remain_days, setremain_days] = useState(0)
const [plan_name, setplanname] = useState()
const [noplan, setnoplan] = useState(false)

const capitalizeFirst = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
const SubscribedAPI = `${SERVER_URL}get_subscription.php`;
useEffect(()=>{
    axios({
      method:"post",
      url:`${SubscribedAPI}`,
      data:userid
    })
    .then((res)=>{
     if(res.data==0){
        setnoplan(true)
     }else{
  
        setparcel_pending(res.data.count_send)
        setremain_days(res.data.remain_days)
        setplanname(capitalizeFirst(res.data.plan_name))
     }

     subscription_orders()

   
    })
    .catch((err)=>console.log(err))
    },[])
    const API_PATH2=`${SERVER_URL}get_subscription_orders.php`;
    function subscription_orders(){
        axios({
            method:"post",
            url:`${API_PATH2}`,
            data:{userid:userid,current:"",type:'normal'}
          })
          .then((res)=>{
          
      console.log('orders',res.data)
          //   setwallet(res.data.wallet)
           settransactions(res.data.transactions)
             setcounttransactions(res.data.transactions.length)
             setbooking(res.data.bookings)
            setcountbooking(res.data.bookings.length)
             setparcelcount2(res.data.totparcel.length)
          })
          .catch((err)=>console.log(err))
    }

   

    function showfun(val){
        if(val=='booking'){
            setshowbooking(true)
            setshowtrans(false)
        }else{
            setshowbooking(false)
            setshowtrans(true)
        }

    }
    const [modalIsOpen,setModalIsOpen] = useState(false);
    const setModalIsOpenToTrue =()=>{
        setModalIsOpen(true)
    }

    const setModalIsOpenToFalse =()=>{
        setModalIsOpen(false)
    }

     function nextpage(val1){

        axios({
    
          method:"post",
          url:`${API_PATH2}`,
          data:{userid:userid,current:val1,type:'normal'}
        })
        .then((res)=>{
          
          if(res.data.parcel!=""){
            if(val1>page){
              setcurrent(current+10)
            }
            if(val1<page){
              setcurrent(current-10)
            }
        setpage(val1)
        setbooking(res.data.bookings)
      setcountbooking(res.data.bookings.length)
           
             }
         
        })
        .catch((err)=>console.log(err))
     
      
    }

    function endSubscription(){
           
      window.confirm('Are you sure to end your current plan?') ? endSubscriptionConfirm(1) : endSubscriptionConfirm(0)

  }

    function endSubscriptionConfirm(val){
      if(val==1){
        axios({
            method:"post",
            url:`${API_PATH2}`,
            data:{userid:userid,current:"",type:'end'}
          })
          .then((res)=>{
          console.log(res.data)
          window.location.reload();
          })
          .catch((err)=>console.log(err))
        }
    }

    function subscribeNewplan(val,type){
           
      window.confirm('Are you sure to end your current plan and Subscribe New Plan?') ? subscribeNewplanConfirm(1) : subscribeNewplanConfirm(0)

  }

    function subscribeNewplanConfirm(val){
      if(val==1){
       
        axios({
            method:"post",
            url:`${API_PATH2}`,
            data:{userid:userid,current:"",type:'end'}
          })
          .then((res)=>{
          console.log(res.data)
          navigate('/pricing')
          })
          .catch((err)=>console.log(err))
        }
    }

    function renewPlan(){
      navigate('/pricing')
    }

    return (


        <div className="wallet-plan">
            <Helmet>
        <title>Time Express | My Wallet</title>
      </Helmet>
            <Modal isOpen={modalIsOpen} ariaHideApp={false}><div >
           
    <button onClick={setModalIsOpenToFalse} style={{float:"right"}}>x</button>
    <h5 classname="modal-title title-mod" id="exampleModalLongTitle" style={{color: '#d85b12',textTransform:"uppercase",fontWeight:"bold",marginBottom:"5%"}}>To add money to you wallet</h5>
           
    </div>
    <>
    
           <div className="container">
           <div className="modal-body title-mod-body">
<div className="col-md-12"><img src={walletimg} height="auto" style={{width:"100%"}}/>
</div>
<div className="col-md-12">
<h4 style={{marginBottom: 0}}>Ship cheaper and earn more with Pay By Wallet</h4>
<p>1. Select exact amount you want to add to your wallet.</p>
<p>2. Choose one of Bulk Buy Bonus schemes and save more!</p>
<p>Note: Use your Pay By Wallet credits to send your parcels at checkout page.</p>
{/* <p>Note: After payment success your wallet money will add automatically to "My Balance".</p> */}
</div>
</div>
           </div>
         
            </>
</Modal>
            <div className="container myparctab" >

                <div className="container-fluid my_quote">
                    <div className="row">

                        <Sidebar />
                        <div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">

                            <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb" }}>
                                <div className="head" style={{ background: "#f5f5f5", padding: "1%" }}>
                                {plan_name && plan_name!=0?( <h3 className="comaprison_hedding">My Subscriptions - {plan_name}</h3>):(
                                   <h3 className="comaprison_hedding">My Subscriptions</h3>
                                ) }
                                   
                                    {/* <a onClick={setModalIsOpenToTrue} style={{color:"#d85b12",cursor:"pointer"}}>How to use</a> */}
                                </div>
                                <div className="row" style={{ textAlign: "center", padding: "15px", margin: "15px" }}>
                                  {noplan?(<>
                                  
                                    <div className="col-md-12">
        <div style={{paddingTop: '10px'}}>   
          <Link to="/pricing" className="panel-title my-wallet-rec" style={{margin: '0 0 10px', position: 'relative', display: 'inline-block', backgroundColor: '#d85b12', color: '#fff'}}>Subscribe Now</Link>
        </div>
      </div>
                                  </>):(<>
                                   <div className="col-md-6">
                                   <div style={{border: '1px solid #d85b12'}}>
                                     <h5 className="panel-title" style={{padding: '7px', backgroundColor: '#d85b12', color: '#fff', textAlign: 'center',fontSize:'18px'}}>No. of Parcel/Document to send</h5>
                                     <p style={{textAlign: 'center', padding: '25px 10px', fontWeight: '700', fontSize: '65px', color: '#c9c9d6', lineHeight: '76px'}}>{parcel_pending}</p>
                                   </div>										
                                 </div>										
                                 <div className="col-md-6">
                                   <div style={{border: '1px solid #d85b12'}}>
                                     <h5 className="panel-title" style={{padding: '7px', backgroundColor: '#d85b12', color: '#fff', textAlign: 'center',fontSize:'18px'}}>Remaining Days</h5>
                                     <p style={{textAlign: 'center', padding: '25px 10px', fontWeight: '700', fontSize: '65px', color: '#c9c9d6', lineHeight: '76px'}}>{remain_days}</p>
                                   </div>										
                                 </div>
                         
                                 <div className="col-md-12">
                                 <div style={{paddingTop: '10px'}}> 
                               
                                   <a className="panel-title my-wallet-rec " onClick={renewPlan} style={{margin: '0 0 10px', position: 'relative', display: 'inline-block', backgroundColor: '#d85b12', color: '#fff'}}>Renew Plan </a> 
                                   <a  className="panel-title my-wallet-rec " onClick={endSubscription} id="endsubscript" style={{margin: '0 0 10px', position: 'relative', display: 'inline-block', backgroundColor: '#d85b12', color: '#fff'}}>End Subscription </a> 
                                   <a  className="panel-title my-wallet-rec " onClick={subscribeNewplan} style={{margin: '0 0 10px', position: 'relative', display: 'inline-block', backgroundColor: '#d85b12', color: '#fff'}}>Subscribe New Plan</a>
                                 </div>
                               </div></>

                                  )}  
                               


                                 
                                </div>
                            </div>

                     

                            {/* transaction history */}

                            <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb",marginTop:"2%", borderRadius: "6px" }}>

<div className="table-responsive " style={{ padding: '25px' }}>
    <h3 className="panel-title comaprison_hedding">My Transactions</h3>
    <div className="wallettrans">
    <a className="booking" onClick={()=>showfun('booking')}>BOOKINGS</a>
    <a className="onlinewallet" onClick={()=>showfun('online')}>ONLINE PAYMENT</a>
    </div>
    {showbooking?(
        <>
        <table className="table-bordered table-striped table-hover table wallet-plan">
        <thead>
            <tr>
                <th>DATE</th>
                <th>ORDER ID</th>
                <th>AMOUNT</th>
                <th>STATUS</th>
            </tr>
        </thead>
        <tbody>
        {
              [...Array(countbooking)].map((e, i) =><>
{booking && booking[i]!=''?(
            <tr key={i}>
                <td>{booking[i].updated_on}</td>
                <td>{booking[i].id}</td>
                <td>{booking[i].total_price} PAID</td>
                <td>BOOKING</td>
            </tr>
):null}
</>
)}
          
        </tbody>
    </table>
    <div className="pagination-sec fw bookingpage"> 
        <nav aria-label="Page navigation">
          <ul className="pagination">
          {current>10?(<>
      
            <li>
              <a onClick={()=>nextpage(page-1)} aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
         
              <li>
              <a className="page_list" onClick={()=>nextpage(page-1)}>{page}</a>
            </li>
            </>
            )
          :null}
            
            {/*?php
                            }?*/}
            <li>
              <a className="page_list active" onClick={()=>nextpage(page)} >{page+1}</a>
            </li>
           {current<parcelcount2?(
<>
<li>
              <a className="page_list" onClick={()=>nextpage(page+1)}>{page+2}</a>
            </li>
            <li>
              <a onClick={()=>nextpage(page+1)}  aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
</>
           ):null}
           
            {/*?php
                            }?*/}
          </ul>
        </nav>
      </div>

    </>
    
    ):(
        <table className="table-bordered table-striped table-hover table wallet-plan">
        <thead>
            <tr>
                <th>DATE</th>
                <th>ORDER ID</th>
                <th>AMOUNT</th>
                <th>STATUS</th>
            </tr>
        </thead>
        <tbody>
        {
              [...Array(counttransactions)].map((e, i) =><>
{transactions && transactions[i]!=''?(
            <tr key={i}>
                <td>{transactions[i].date_on}</td>
                <td>{transactions[i].id}</td>
                <td>{transactions[i].amount} PAID</td>
                <td>SUCCESS</td>
            </tr>
):null}
</>
)}
          
        </tbody>
    </table>
    )}
    
   

  
</div>
</div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
