import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../../Business.css';
import banner from '../../images/banners/business.jpg'

export default function Business() {
    const navigate=useNavigate();
    const [parcels, setparcels] = useState()
   const [sendpath, setsendpath] = useState()
   function showbusiness(){
       if(!parcels){
           alert("Please select options")
       }else{
        switch(parcels){
            case "1": 
            navigate("/your-business/individuals/")
            break;//individual
        
            case "2": navigate("/your-business/smes/")
             break;//sme
            default:navigate("/your-business/corporates/")
            break;//corporate
        }
       }
      
   }
  return (
    <div className="mybusiness">
          <Helmet>
        <title>Time Express | Business</title>
      </Helmet>

        <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 your_business text-center track-parcel">
                            <div className="business_content">
                            <h1 className='h1-title'><span>Discover the right courier service</span><br /> for your business</h1></div>
                            <form action="javascript:void(0);" method="post" name="myform" id="myform">
                                <ul className="business_region buss"><li>
                                     <label>Parcels Per Month</label>
                                 <select className="form-control" id="mySelect"  onChange={(e)=>setparcels(e.target.value)}>
                                     <option value id="please_select">please select</option>
                                     <option data-tray="one"  value={1}>1-499</option>
                                     <option data-tray="two"  value={2}>500-4,999</option>
                                     <option data-tray="three" value={3}>Above 5,000</option> 
                                     </select>
                                     <p className="p_error" style={{display: 'none'}} /></li>
                                     <li> <label>Region</label>
                                      <select className="form-control" id="mySelect1">
                                          <option value id="please_select1">Please select your Country</option>
                                          <option>Dubai</option>
                                          <option>Sharjah</option>
                                          <option>Abu Dhabi</option>
                                          <option>Ajman</option>
                                          <option>Fujeirah</option>
                                          <option>Al Ain</option>
                                           </select>
                                           <p className="c_error" style={{display: 'none'}} />
                                           </li>
                                           <li>
                                               <button type="submit" onClick={showbusiness} className="btn btn-primary form-control noradius" id="select_go" style={{ minWidth:" 100%", borderRadius:" 0px !important",marginBottom:"5px"}}>Go</button>
                                               </li>
                                               <p className="error" />
                                               </ul>
                            </form>
                           </div>
                    </div>
                </div>
            </section>
            <section className="business_needs_sec text-center fw">
            <div className="container">
            <div className="row"><div className="col-md-12 col-sm-12 text-center understand-text ">
                <h3 className="cta-header">Let's understand your business in more detail
                
                 </h3>
                 {/* <Link className="btn  btn--black  salesbtn" style={{border:"1px solid"}} to="/sales-enquries/">Sales<br /> 
                 Enquiries</Link> */}
                 <Link className="btn  btn--black  salesbtn" style={{border:"1px solid"}} to="/pricing/">Bundle<br /> 
                 Offers</Link>
                 </div>
            </div>
            <div className="business_needs_sec row">
            <h2>WE HAVE YOUR BUSINESS NEEDS COVERED</h2>
            </div>

            <div className="row">
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Individuals</h3>
                                        <p></p>
                                        <p>SHOPPING MADE EASY GET THE BEST DEALS TAKE THE HASSTLE OUT OF SHIPPING
</p><p>
CONSUMERS WANT CONVENIENCE, SPEED, AND EMPOWERED CHOICE. WE MAKE IT HAPPEN.</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/your-business/individuals/">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img business_img2" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">SME’s</h3>
                                        <p></p>
                                        <p>BUILD ON YOUR SUCCESS TAP INTO NEW MARKETS OPEN YOURSELF UP TO TENS OF MILLIONS OF ADDITIONAL POTENTIAL CUSTOMERS
                                        </p><p>
WE MAKE SHIPPING SIMPLE & EASY. ONLINE SHOPPING IS LIMITED TO NOTHING, LET THE WORLD BE YOUR MARKET PLACE.</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/your-business/smes/">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img business_img3" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Corporates</h3>
                                        <p></p>
                                        <p>POWERFUL AND ECONOMICAL INTERNATIONAL COURIER SERVICE SOLUTIONS. CLOUD-BASED TOOLS AND INTEGRATIONS. SAVE TIME INCREASE EFFICIENCY IMPROVE PERFORMANCE</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/your-business/corporates/">Find out more</Link></div>
                                </div>
                            </div>
                        </div>

                    </div>
            </div>
            </section>
    </div>
  )
}
