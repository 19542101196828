import React from 'react'
import { Helmet } from 'react-helmet'
import '../../Custom.css'
import banner from '../../images/banners/privacy.jpg'
export default function Privacy_cookies() {
 
  return (
    <div className="improvelive">
      <Helmet>
        <title>Time Express | Privacy & Cookies</title>
      </Helmet>
         <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                  <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                         
                            <h1 className='h1-title'>PRIVACY & COOKIES</h1>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content">
<div className="become_driver">
<div className="border_text">
<h3>Our Policy:</h3>
<p>Welcome to the website (the “Site”) of Time Express Parcels  Delivery LLC, Inc. (“Time Express Parcels Delivery LLC”,  “we”, “us” and/or “our”). This Site is operated by Time Express  Parcels Delivery LLC and has been created to provide  information about our company and our packaging and shipping  services, mobile application, and related services (together with  the Site, the “Services”) to our Service visitors (“you”, “your”).  This Privacy Policy sets forth Time Express Parcels Delivery  LLC’s policy with respect to information including personally  identifiable data (“Personal Data”) and other information that is  collected from visitors to the Site and Services.</p>
</div>
<div className="border_text">
<h3>Information We Collect:</h3>
<p>When you interact with us through the Services, we may collect  Personal Data such as name, email, address and other information (if applicable) from you, as further described below: </p>
</div>
<div className="border_text">Personal Data That You Provide Through the Services: We  collect Personal Data from you when you voluntarily provide  such information, such as when you contact us with inquiries,  respond to one of our surveys, register for access to the Services  or use certain Services. Wherever Time Express Parcels  Delivery LLC collects Personal Data we make an effort to  provide a link to this Privacy Policy.<p />
<p>By voluntarily providing us with Personal Data, you are  consenting to our use of it in accordance with this Privacy  Policy. If you provide Personal Data to the Services, you  acknowledge and agree that such Personal Data may be  transferred from your current location to the offices and servers  of Time Express Parcels Delivery LLC and the authorized third  parties referred to herein located in the United States.</p>
</div>
<div className="border_text">
<h3>Other Information:</h3>
<p>Non-Identifiable Data: When you interact with Time Express  Parcels Delivery LLC through the Services, we receive and store  certain personally non-identifiable information. Such  information, which is collected passively using various  technologies, cannot presently be used to specifically identify  you. Time Express Parcels Delivery LLC may share such  information with third parties for any lawful purpose, or such  information may be stored or included in databases owned and  maintained by Time Express Parcels Delivery LLC affiliates,  agents, or service providers. The Services may use such  information and pool it with other information to track, for  example, the total number of visitors to our Site, the number of  visitors to each page of our Site, and the domain names of our  visitors’ Internet service providers. It is important to note that no  Personal Data is available or used in this process. In operating  the Services, we may use a technology called “cookies.” A  cookie is a piece of information that the computer that hosts our  Services gives to your browser when you access the Services.</p>
<p>Our cookies help provide additional functionality to the Services  and help us analyze Service usage more accurately. For  instance, our Site may set a cookie on your browser that allows  you to access the Services without needing to remember and  then enter a password more than once during a visit to the Site.  In all cases in which we use cookies, we will not collect  Personal Data except with your permission. On most web  browsers, you will find a “help” section on the toolbar. Please  refer to this section for information on how to receive  notifications when you are receiving a new cookie and how to  turn cookies off. We recommend that you leave cookies turned  on because they allow you to take advantage of some of the  Service features.</p>
</div>
<div className="border_text">
<h3>Aggregated Personal Data:</h3>
<p>In an ongoing effort to better  understand and serve the users of the Services, Time Express  Parcels Delivery LLC often conducts research on its customer  demographics, interests, and behavior based on the Personal Data  and other information provided to us. This research maybe  compiled and analyzed on an aggregate basis, and Time Express  Parcels Delivery LLC may share this aggregate data with its  affiliates, agents, and business partners. This aggregate  the information does not identify you personally. Time Express  Parcels Delivery LLC may also disclose aggregated user  statistics in order to describe our services to current and  prospective business partners, and to other third parties for other  lawful purposes.</p>
</div>
<div className="border_text">
<h3>Location Information:</h3>
<p>Our Service may collect and use your  location information (for example, by using the GPS on your  mobile device) to provide certain functionality of our Service. If  you choose to enable our location features, your location  information may be publicly displayed within the Service. We  may also use your location information in a non-identifiable or  aggregate way, as described above in the “Non-Identifiable  Data” and “Aggregated Personal Data” sections.</p>
</div>
<div className="border_text">
<h3>Third-Party Services:</h3>
<p>The Services may collect certain  Personal Data which you or third parties provide to various  social networking services like Facebook or LinkedIn (“Social  Networking Services”) or other third-party merchants  applications with whom we partner (collectively, “Third Party  Service(s)”). By directly integrating your Third Party Service(s),  we make your experience with the Service richer and more  relevant. We may ask you to log in or grant us permission via  the relevant Third Party Service. When you add a Third Party  Service account to the Service or log into the Service using your  Third-Party Service account, we will collect relevant  information necessary to enable the Service to access that Third  Party Service, information that you have provided to the Third  Party Service, and Personal Information relating to third parties  which such third parties have made available to you through the  Third-Party Service. We will use, store and disclose such  information in accordance with this Privacy Policy. However,  please remember that the manner in which the relevant Third  Party Service(s) use, store and disclose your information is  governed by the policies of such third parties, and Time Express Parcels Delivery LLC shall have no liability or responsibility for
the privacy practices or other actions of any Third Party Service  that may be enabled within the Service.  You may also have the option of posting your Services activities  to the Third Party Service when you access content through the  Services (for example, you may post to Facebook that you  performed an activity on the Service); you acknowledge that if  you choose to use this feature, your friends, followers and  subscribers on any Social Networking Services you have  enabled will be able to view such activity.</p>
</div>
<div className="border_text">
<h3>Mobile Device Address Book:</h3>
<p>The Services may also collect  the Personal Data relating to third parties stored in your mobile  device address book, including without limitation email  addresses and phone numbers. Depending on your mobile  device operating system, the Services may request your  permission to access such Personal Data.</p>
</div>
<div className="border_text">
<h3>Our Use of Your Personal Data and Other Information:</h3>
<p>Time Express Parcels Delivery LLC uses the Personal Data you  provide in a manner that is consistent with this Privacy Policy. If  you provide Personal Data for a certain reason, we may use the  Personal Data in connection with the reason for which it was  provided. For instance, if you contact us by email, we will use  the Personal Data you provide to answer your question or  resolve your problem. Also, if you provide Personal Data about  yourself, or have permitted the Services to collect Personal Data  of third parties from Third Party Services or your mobile device  address book (“Third Party Personal Data”), in order to obtain  access to the Services or to make use of particular features of the</p>
<p>Services, we will use your Personal Data and Third Party  Personal Data to provide you with access to such services and to  monitor your use of such services.  The Services may permit you to connect or communicate with  third parties using Third-Party Personal Data, including to refer  such third party to the services or to share tracking information.  If you use the Services to connect with a third party, Time  Express Parcels Delivery LLC may use Third Party Personal  Data to contact such third party by email or SMS messaging,  and may also send subsequent reminders to such third party  from time to time, which third parties may “opt-out” of  receiving.</p>
<p>You may have the option of creating a unique username  (“Username”) using the Services. When you create a Username,  third parties users may view and access your name and your city  and state of residence. You may change your settings to restrict  which parties can access the Personal Data connected with your  Username.</p>
<p>Time Express Parcels Delivery LLC and its subsidiaries and  affiliates (the “Related Companies”) may also use your Personal  Data and other personally non-identifiable information collected  through the Services to help us improve the content and  the functionality of the Services, to better understand our users and  to improve the Services. Time Express Parcels Delivery LLC  and its affiliates may use this information to contact you in the  future to tell you about services we believe will be of interest to  you. If we do so, each marketing communication we send you</p>
<p>will contain instructions permitting you to “opt-out” of receiving  future marketing communications. In addition, if at any time you  wish not to receive any future marketing communications or you  wish to have your name deleted from our mailing lists, please  contact us as indicated below.</p>
<p>If Time Express Parcels Delivery LLC intends on using any  Personal Data in any manner that is not consistent with this  Privacy Policy, you will be informed of such anticipated use  prior to or at the time at which the Personal Data is collected.</p>
<p>You are allowed to request for deletion of your data from our database, you just need to contact us by email(info@timexpress.ae) and we will delete those information from our databases.</p>
</div>
<div className="border_text">
<h3>Our Disclosure of Your Personal Data and Other  Information:</h3>
<p>Time Express Parcels Delivery LLC is not in the business of  selling your information. We consider this information to be a  vital part of our relationship with you. There are, however,  certain circumstances in which we may share your Personal  Data with certain third parties without further notice to you, as  set forth below:</p>
<p>All credit/debit cards’ details and personally identifiable information will NOT be stored, sold, shared, 
rented or leased to any third parties accordance with this Privacy Policy.</p>
</div>
<div className="border_text">
<h3>Business Transfers:</h3>
<p>As we develop our business, we might sell  or buy businesses or assets. In the event of a corporate sale,  merger, reorganization, dissolution or similar event, Personal  Data may be part of the transferred assets.</p>
</div>
<div className="border_text">
<h3>Related Companies:</h3>
<p>We may also share your Personal Data  with our Related Companies for purposes consistent with this  Privacy Policy.</p>
</div>
<div className="border_text">
<h3>Agents, Consultants, and Related Third Parties:</h3>
<p>Time Express  Parcels Delivery LLC, like many businesses, sometimes hires  other companies to perform certain business-related functions.  Examples of such functions include mailing information,  maintaining databases and processing payments. When we  employ another entity to perform a function of this nature, we  only provide them with the information that they need to  perform their specific function. Similarly, we may work with  business partners in connection with delivering our Services to  you. For example, if you use Time Express Parcels Delivery  LLC’s Services to send or return something to one of our  business partners (like a retailer), we may disclose your relevant  information (name, email address, package tracking number and  the like) to that business partner to help facilitate Time Express  Parcels Delivery LLC’s Services, including package tracking  and confirmation of delivery.</p>
</div>
<div className="border_text">
<h3>Legal Requirements:</h3>
<p>Time Express Parcels Delivery LLC may  disclose your Personal Data if required to do so by law or in the  good faith belief that such action is necessary to (i) comply with  a legal obligation, (ii) protect and defend the rights or property  of Time Express Parcels Delivery LLC, (iii) act in urgent  circumstances to protect the personal safety of users of the  Services or the public, or (iv) protect against legal liability.</p>
</div>
<div className="border_text">
<h3>Your Choices:</h3>
<p>You can visit the Site without providing any Personal Data. If  you choose not to provide any Personal Data, you may not be  able to use certain Services.</p>
</div>
<div className="border_text">
<h3>Exclusions:</h3>
<p>This Privacy Policy does not apply to any Personal Data  collected by Time Express Parcels Delivery LLC other than  Personal Data collected through the Services. This Privacy  policy shall not apply to any unsolicited information you  provide to Time Express Parcels Delivery LLC through the  Services or through any other means. This includes, but is not  limited to, information posted to any public areas of the  Services, such as forums, any ideas for new products or  modifications to existing products, and other unsolicited  submissions (collectively, “Unsolicited Information”). All  Unsolicited Information shall be deemed to be non-confidential  and Time Express Parcels Delivery LLC shall be free to  reproduce, use, disclose, and distribute such Unsolicited  Information to others without limitation or attribution.</p>
</div>
<div className="border_text">
<h3>Children:</h3>
<p>Time Express Parcels Delivery LLC does not knowingly collect  Personal Data from children under the age of 13. If you are  under the age of 13, please do not submit any Personal Data  through the Services. We encourage parents and legal guardians  to monitor their children’s Internet usage and to help enforce our  Privacy Policy by instructing their children never to provide  Personal Data on the Services without their permission. If you  have reason to believe that a child under the age of 13 has  provided Personal Data to Time Express Parcels Delivery LLC  through the Services, please contact us, and we will endeavor to  delete that information from our databases.</p>
</div>
<div className="border_text">
<h3>Links to Other Web Sites:</h3>
<p>This Privacy Policy applies only to the Services. The Services</p>
<p>may contain links to other websites not operated or controlled  by Time Express Parcels Delivery LLC (the “Third Party  Sites”). The policies and procedures we described here do not  apply to the Third-Party Sites. The links from the Services do  not imply that Time Express Parcels Delivery LLC endorses or  has reviewed the Third-Party Sites. We suggest contacting those  sites directly for information on their privacy policies.</p>
</div>
<div className="border_text">
<h3>Security:</h3>
<p>Time Express Parcels Delivery LLC takes reasonable steps to  protect the Personal Data provided via the Services from loss,  misuse, and unauthorized access, disclosure, alteration, or  destruction. However, no Internet or email transmission is ever  fully secure or error-free. In particular, email sent to or from the  Services may not be secure. Therefore, you should take special  care in deciding what information you send to us via email.  Please keep this in mind when disclosing any Personal Data to  Time Express Parcels Delivery LLC via the Internet.</p>
<h3>Other Terms and Conditions:</h3>
<p>Your access to and use of the Services is subject to the Terms of  Service at https://www.timexpress.ae/terms-conditions.  Changes to Time Express Parcels Delivery LLC’s Privacy</p>
</div>
<div className="border_text">
<h3>Policy:</h3>
<p>The Services and our business may change from time to  time. As a result, at times it may be necessary for Time Express  Parcels Delivery LLC to make changes to this Privacy Policy.  Time Express Parcels Delivery LLC reserves the right to update  or modify this Privacy Policy at any time and from time to time  without prior notice. Please review this policy periodically, and</p>
<p>especially before you provide any Personal Data. This Privacy  Policy was last updated on the date indicated above. Your  continued use of the Services after any changes or revisions to  this Privacy Policy shall indicate your agreement with the terms  of such revised Privacy Policy.</p>
<p>Access to Information; Contacting Time Express Parcels  Delivery LLC: To keep your Personal Data accurate, current,  and complete, please contact us as specified below. We will take  reasonable steps to update or correct Personal Data in our  possession that you have previously submitted via the Services.</p>
<p>Please also feel free to contact us if you have any questions  about Time Express Parcels Delivery LLC’s Privacy Policy or  the information practices of the Services.</p>
<p>You may contact us as follows: info@timexpress.ae.</p>
</div>
</div>
<h1 style={{display: 'none'}}>Privacy &amp; Cookies</h1>
</div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
