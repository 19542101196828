import React from 'react'
import banner from '../../images/banners/smartsend5.jfif'
import { faCircleCheck ,faCircleExclamation} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import checkout from '../../images/shopify/checkout.PNG'
import ship4 from '../../images/shopify/ship4.png'
import { Helmet } from 'react-helmet'
const checkmark = <FontAwesomeIcon icon={faCircleCheck} />
const info = <FontAwesomeIcon icon={faCircleExclamation} />
export default function Magento() {
  
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
       const [passerror, setpasserror] = useState('')
const [passsuccess, setpasssuccess] = useState('')
const [loadingshow, setloadingshow] = useState(false)
const [enquiry, setenquiry] = useState({
    name:'',
    phone:'',
    platform:''
    })
    const [sector, setsector] = useState([])

    const [stsmsg, setstsmsg] = useState()
    //const API_PATH='http://localhost:8000/smart_send_enquiry.php';
    const API_PATH=`${SERVER_URL}smart_send_enquiry.php`;
   function sendEnquiry(){
    if(enquiry.name!="" && enquiry.phone!="" && enquiry.company_name!="" && enquiry.platform!=""&& enquiry.address!=""&& enquiry.city!="" && sector!=""){
        setstsmsg('')
        setloadingshow(true)
    axios({
        method:"post",
        url:`${API_PATH}`,
        data:{enquiry:enquiry,sector:sector}
    })
    .then((res)=>{
    console.log(res.data)
    setloadingshow(false)
   setstsmsg(res.data.message)
    })
    .catch(err=>console.log(err))
   }else{
    setstsmsg('Please enter the required fields')
   }
}

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }
  return (
    <div>
       <Helmet>
        <title>Time Express | Magento</title>
      </Helmet>
          <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
             <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                            
                            <h1 className="mainhead">Magento</h1>
                            <p>Enable your e-commerce shipments with time express.</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            
            <div className="pages_content magento">
            <div className="container">
                
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver2">
           <FadeInSection key='1' >
            <div className="row">
            <h3 style={{marginTop:'3%'}}>Why Time Express?</h3>
            <div className="row">
<div className="col-md-8">

<p>Time Express provide merchants a seamless integration on order confirmation, dispatch, and tracking by selecting Time Express as your delivery partner. Simply download the “Time Express Parcels” from Magento market place to select and add Time Express as your courier partner on your online portal. At the checkout page customers can select Time Express to take advantage of their preferential rates, an order confirmation automatically will be sent for order execution and delivery to your assigned destination location</p>
           
</div>
<div className="col-md-4"> <img src={ship4} style={{width:'80%'}}/></div>

            </div>
           
            </div>
          
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"></link>
         
           <div className="row smartsendmain" style={{marginTop:'1%'}}>
           <div className="col-md-4 shopify1" style={{textAlign:'center'}}>
   
            <h5>Easy Integration</h5>
          <p>Integrate Time Express by following these 3 steps; 1. Register 2. Install Time Express app 3. Assign carrier service to shipping zones</p>
           </div>
           <div className="col-md-4 shopify" style={{textAlign:'center'}}>
           
          <h5>Real Time Shipping Rates</h5>
          <p>Real time shipping rates are available upon checkout. Rates are calculated based on the product weight, package dimension, and destination</p>
           </div>
           <div className="col-md-4 shopify1" style={{textAlign:'center'}}>
          
           <h5>Order Processing & Tracking</h5>
          <p>New orders assigned to Time Express will be processed automatically, live tracking can be obtained using the provided tracking URL</p>
           </div>
        </div>
        </FadeInSection>
    

        <FadeInSection key='2' >
        <h3 style={{marginTop:'3%'}}>How it works?</h3>
  
         <p>{checkmark} &nbsp; Register with Time Express (timex.ae).</p>
         <p>{checkmark} &nbsp;Install Time Express Parcels extension from Magento Marketplace.</p>
         <p>{checkmark} &nbsp;Login to Time Express Parcels extension via Sales -&gt; Time Express Parcels -&gt; Account using the registered username and password.</p>
         <p>{checkmark} &nbsp;Change the settings for shipping display, order processing(if not selected default settings will work).</p>
         <p>{checkmark} &nbsp;Choose from the available shipping services.</p>
         <p>{checkmark} &nbsp;You are done.</p>
       
        </FadeInSection>

        <FadeInSection key='3' >
             <img src={checkout} style={{marginTop:'3%',width:'100%'}}/>
        </FadeInSection>

        <FadeInSection key='4' >
            <div className="div" style={{marginBottom:'3%'}}>
             <h3 style={{marginTop:'3%'}}>Special Notes</h3>
             <p>{info} &nbsp;Real-time rate calculation is done based on product weight, package dimensions, and destination. Package weight is calculated based on volumetric or actual weight whichever is greater.
</p>
<p>{info} &nbsp;New orders will be automatically processed by Time Express, you can also select manual option to push your orders by changing the order processing method in app dashboard.
</p>

</div>
        </FadeInSection>
          
    </div>
    </div>
    </main>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
