import React from 'react'
import { Helmet } from 'react-helmet';
import banner from '../../images/banners/collect.jpg'
export default function Collect_drop() {
  return (
    <div>
          <Helmet>
        <title>Time Express | Collect OR Drop-Off Your Parcel</title>
      </Helmet>
       <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
             
                            <h1 className='h1-title'>COLLECT OR DROP-OFF YOUR PARCEL</h1>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
           <div className="entry-content">
<h1>Collect &amp; Drop-off Your Parcel</h1>
<p>Tired of missing a parcel delivery to your home? Want the convenience to collect your parcel when you want?</p>
<p>We offer you fast delivery of your parcels to a convenient collection point near your home, office work, or holiday resort! You will be notified by SMS, email, or phone as soon as your parcel has arrived at the delivery point.</p>
<p>Take advantage of great convenience by choosing one of our collection and drop off location options.</p>
</div>
             </div>


            </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
