import React, { useEffect, useState } from 'react'
import '../../Help.css';
import { useNavigate, Link, useParams, Route, Routes } from 'react-router-dom'
import axios from 'axios';
import banner from '../../images/banners/collect.jpg'
import { Helmet } from 'react-helmet';

export default function Help() {
  const [tabpath, settabpath] = useState('fuels')
  const [count, setCount] = useState(0)
  //  let role=sessionStorage.getItem('userid')
  const [help, sethelp] = useState()

  return (

    <>
      <Helmet>
        <title>Time Express | Help</title>
      </Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;0,600;0,700;1,500&display=swap" rel="stylesheet" />
      <section id="cd-intro">


        <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 your_business text-center">
                <div className="business_content">
                <h1 className="mainhead">Help</h1>
                  {/* <p>Search for answers or explore regular help topics below</p> */}
                </div>
              </div>
              {/* <div className="form-help">
                                <form role="search" method="get" id="searchform" className="searchform" action="https://www.timexpress.ae/">
                                    <div><label className="screen-reader-text" htmlFor="s">Search for:</label>
                                        <input type="text" defaultValue='' name="s" id="s" placeholder="Ask a question or search a topic" />
                                        <input type="submit" id="searchsubmit" defaultValue="Search" /></div>
                                </form>
                            </div> */}
            </div>
          </div>
        </div>

        <div className="container help mt-5">

          <h6 className="section-title h1" style={{ textAlign: "center", padding: "3%" }}>HELP TOPIC AREA</h6>
          <div className="row">
            <div className="col-xs-12 helpnav ">
              {/* <nav> */}
              <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                <a className="nav-item nav-link " onClick={() => setopen('fuel')}   >Fuel Surcharge</a>
                <a className="nav-item nav-link" onClick={() => setopen('size')} aria-selected="false">Size Limits</a>
                <a className="nav-item nav-link" onClick={() => setopen('tracking')} role="tab" aria-selected="false">Tracking</a>
                <a className="nav-item nav-link" onClick={() => setopen('deliveries')} role="tab" aria-selected="false">Deliveries</a>

              </div>



              {count == "0" && tabpath == 'fuel' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2> What is a fuel surcharge?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      A fuel surcharge is a way of adjusting the amount paid to move freight (for us parcels) by taking into account significant variation in fuel prices, compared to historical levels.
                      Dont worry, it’s nothing out of the ordinary and most carriers and shippers participate in a fuel surcharge program of some kind.
                      <br />
                    </div>
                  </div>
                </div>
              ) : null
              }

              {count == "0" && tabpath == 'size' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2> Do you have any size limits?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      Cutting to the chase, if you know the dimensions of your parcel then just put theme in the very short quote form and that will give you a list of services that can handle your large package.
                      <br /> At the parcels booked via our courier quotes system have to have their volumetric weight calculated. This establishes a value that all courier companies use to determine the ‘bulkiness’ of a parcel and hence set prices. A very small item that weighs 25kg may not cost the same to ship as a 5kg parcel. Until you add your requirements into our courier quotes form we can give you an idea if your parcel is too heavy to go through our parcel courier suppliers. As you can appreciate, we can only give indicative weight limits. If you’re looking to send parcels that are 1kg, 2kg, 3kg, even 5kg and 10kg you should be fine. Even parcels that are 12kg, 15kg and heavy items such as boxes of books at 20kg should, generally, be no problem. Individual items approaching 25kg and over 30kg would need special handling. Simply complete the courier quotes form with the dimensions and we’ll be able to give you the cheapest prices and a list of suitable services.<br />
                    </div>
                  </div>
                </div>
              ) : null
              }

              {count == "0" && tabpath == 'tracking' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2>How do I Track my parcel?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      Im expecting a delivery, how can I track its status?
                      <br />You can <Link to="/track" target="_blank" rel="noopener noreferrer">track</Link> your parcel on our site. You’ll need your parcel number. It’s really easy!
                      <br />Just enter your details here: <Link to="/track" target="_blank" rel="noopener noreferrer">Track</Link> and off you go.

                    </div>
                    <br />
                    <h2>Why is there no tracking against my parcel?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      This could be down to a few things…
                      <br />If you have just ordered your goods it may take a short time for the parcel information to update on our systems.
                      If you have had your parcel number for more than a day and there is no tracking information, please contact the sender to verify the number is correct and that the parcel has been passed over to Time Express.
                      <br />
                    </div>

                    <br />
                    <h2>There has been no tracking update for several days, what should I do?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      If there has been no update for a couple of days and it has gone beyond the expected delivery date, please use the webchat facility and an advisor will be able to help you.
                    </div>
                    <br />
                    <h2>Will I be notified of any planned delays to routes?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      We will notify you by email if we are aware of any bad weather or road closures that will affect your collections or deliveries. Please speak to your account manager however if you have any individual queries.
                    </div>
                  </div>
                </div>

              ) : null
              }

              {count == "0" && tabpath == 'deliveries' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2> What is a parcel ID or AWB number?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      It’s a unique code that allows us to track your parcel. It’s usually between 8 and 20 digits long.
                      Here are some examples: JD0002123456789012 8YDR098765432
                      0980982.1
                      <br />
                      You may have been sent a parcel number when you ordered your goods, when your parcel was dispatched or on the day of delivery.
                      <br />
                      If we missed you and left a card there will be an 8-digit reference number printed on there that you can use.
                      <br />
                      If you don’t have a parcel number please speak to the sender of the parcel who will be able to help you.


                    </div>
                    <h2>Can I change my delivery time?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      Unfortunately, you are unable to change the delivery time window online. Please contact our customer service team who will be happy to assist you.
                      <br />
                    </div>
                    <br />
                    <h2>Why was my delivery time missed?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      We’re sorry if this has caused you any inconvenience.
                      <br />
                      Our estimated two-hour delivery window is generated by the route the driver will take to deliver all parcels for that day. Sometimes the driver may experience traffic, road closures or deliveries may take longer than usual.
                      <br />
                      If we know that we’re not going to make it we’ll let you know and give you a new two hour delivery window.
                      <br />
                    </div>
                    <br />
                    <h2>Why can’t you give me a delivery time?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      Well it depends on the service purchased…
                      <br />
                      The types of sms/email notifications that you receive depends on what service has been purchased by the sender.
                      Some of our services offer a two-hour delivery window. If you do have a two-hour window you will be notified by text and/or email on the delivery day. If not then unfortunately you cannot currently upgrade your delivery but don’t worry, it will still reach you by 9pm on or before your expected delivery date.<br />
                    </div>
                  </div>
                </div>
              ) : null
              }



              <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist" style={{ marginTop: "2%" }}>
                <a className="nav-item nav-link " onClick={() => setopen('rearrange')}   >Rearranging Delivery</a>
                <a className="nav-item nav-link" onClick={() => setopen('damage')} aria-selected="false">Damages</a>
                <a className="nav-item nav-link" onClick={() => setopen('return')} role="tab" aria-selected="false">Returns</a>
                <a className="nav-item nav-link" onClick={() => setopen('exchange')} role="tab" aria-selected="false">Exchange</a>

              </div>





              {count == "0" && tabpath == 'rearrange' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2> Schedule your pick up and delivery time</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      Depending on the service that has been purchased by the sender and if you are having a busy schedule and want the convenience of selecting a specific 2 hour time slot for delivering your parcel this option is for you. Simply click on the date and time to let us know your preference, we will do our best to meet your request.
                      <br />
                      If the sender has not used a service that provides a two hour delivery window then it won’t be available.
                      <br />
                      To re-schedule your pick up and delivery time click <Link to="/track/schedule-your-pick-up-and-delivery-time" target="_blank" rel="noopener noreferrer">here</Link>
                    </div>
                    <br />
                    <h2> Get the Parcel You Missed Redelivered.</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      If we missed you when we tried to deliver your parcel, you can schedule redelivery online using the peach-colored slip left by your carrier to look up your items and reschedule delivery.
                      <br />
                      For same day redelivery, make sure your request is submitted by 10am Saturday – Thursday. Redelivery same day may not be available outside Dubai city limits.
                      <br />
                      Please check our customer service for pickup and delivery information.
                    </div>
                    <br />
                    <h2> The tracking is telling me something different than what has actually happened with my parcel. What should I do?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      This could be down to a few things…
                      <br />
                      It may be that the latest scan about your parcel’s delivery has not made it to our system yet. It can take a few minutes. If you are concerned however, please speak to one of our advisors using the webchat facility.
                    </div>
                    <br />
                    <h2> The driver tried to deliver before the time slot I was given, why?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      We’re sorry if this caused you any inconvenience.
                      <br />
                      Sometimes the driver may get ahead of schedule and will get to you before the delivery window.</div>
                  </div>
                </div>
              ) : null
              }

              {count == "0" && tabpath == 'damage' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2>My parcel has arrived damaged.</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      It is the responsibility of the sender to ensure your parcel arrives in good condition. Please contact the company who sent you the item.
                      <br />
                      They will enable you to arrange for a replacement item or obtain a refund for your purchase.

                    </div>
                    <br />
                    <h2>My parcel has been returned to sender as it was damaged Damaged items get sent back to the sender</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      It may be your parcel has been damaged before it got to us meaning that we are unable to deliver the damaged item. In this case we will send the item back to the sender on your behalf.
                    </div>

                    <br />
                    <h2>There has been no tracking update for several days, what should I do?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      If there has been no update for a couple of days and it has gone beyond the expected delivery date, please use the webchat facility and an advisor will be able to help you.
                    </div>
                    <br />
                    <h2>Will I be notified of any planned delays to routes?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      We will notify you by email if we are aware of any bad weather or road closures that will affect your collections or deliveries. Please speak to your account manager however if you have any individual queries.
                    </div>
                  </div>
                </div>

              ) : null
              }
              {count == "0" && tabpath == 'return' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2>I want to return a parcel, how?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      Check the documentation that arrived with your parcel. Instructions from your merchant will be in there. If you have purchased a product online, you will need to contact the sender to provide you with a return approval code and arrange a return collection. They can provide all the necessary labels and information to return your parcel. A return collection can take place anytime between 8am-6pm.
                    </div>

                    <br />
                    <h2>Is there a cost to return a parcel?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">This will depend on who has sent your parcel. We suggest that you check with your parcel’s sender for this.</div>


                    <br />
                    <h2>How long will my return take?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">That depends on your sender
                      <br />

                      The return of your parcel will take no more than a couple of days but the sender will have their own process in terms of replacements, refunds or otherwise. These can take longer and you’ll need to speak to the sender to understand their timescales.
                    </div>

                    <br />
                    <h2>Can I track my return?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      Of course you can!
                      <br />
                      You can, all parcels sent through the Time Express network can be tracked. Our driver will give you a card as a receipt of your collection, with your return’s parcel number on for you to <Link to="/track" target="_blank" rel="noopener noreferrer"> track</Link>  as normal.
                    </div>

                    <br />
                    <h2>Where do I send the return item to?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      Your sender will provide this information for you. Please ensure that any label used is completely flat and is unobscured. Please do not cover the barcode with any tape, including clear tape, as this may prevent us from scanning it.
                    </div>

                    <br />
                    <h2>Can I return it any time I want?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      This will depend on who has sent your parcel. We suggest that you check with your parcel’s sender for this.
                    </div>
                    <br />
                    <h2>Am I eligible for a return?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      This will depend on who has sent your parcel. We suggest that you check with your parcel’s sender for this.

                    </div>
                  </div>
                </div>
              ) : null
              }

              {count == "0" && tabpath == 'exchange' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2>I’m told I need to attach a label to my exchange, where do I get this?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      You should have been provided with a label to attach to your return parcel. If this is not the case, you will need to contact the parcel’s sender to arrange for a new label.
                    </div>
                  </div>
                </div>
              ) : null
              }




              <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist" style={{ marginTop: "2%" }}>
                <a className="nav-item nav-link " onClick={() => setopen('restrictions')}   >Restrictions</a>
                <a className="nav-item nav-link" onClick={() => setopen('clients')} aria-selected="false">Clients</a>
                <a className="nav-item nav-link" onClick={() => setopen('prohibited')} role="tab" aria-selected="false">Prohibited Items</a>
                <a className="nav-item nav-link" onClick={() => setopen('packaging')} role="tab" aria-selected="false">Packaging Advice</a>

              </div>





              {count == "0" && tabpath == 'restrictions' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2>Are there any size/ weight restrictions?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      Yes we do have weight and size restrictions. Visit <Link to="/your-business" target="_blank" rel="noopener noreferrer">Your Business</Link>  to contact us and one of our team will get back to you.
                    </div>
                  </div>
                </div>

              ) : null
              }

              {count == "0" && tabpath == 'clients' ? (<div className="details">
                <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                  <h2>What service is right for me?</h2>
                  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                    We have a range of services available to meet all of our clients’ needs.
                    <br />
                    If you are a business, you can visit the <Link to="/your-business" target="_blank" rel="noopener noreferrer"> Your Business page</Link> , where you can find out information on Time Express services. If you only want to send a few parcels
                    then <Link to="/track/same-day-delivery/" target="_blank" rel="noopener noreferrer">Time Direct</Link> could be the right choice for you.
                  </div><br />

                  <h2>I’m interested in using Time Express’s services, who should I speak to?</h2>
                  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                    Great!
                    <br />
                    You can visit the <Link to="/your-business" target="_blank" rel="noopener noreferrer"> Your Business page</Link>, there you can find out what the right service is for you as well as details of our service propositions. Then, complete the account opening form and we will get in touch with you.
                    <br />
                    If you only want to send a few parcels then <Link to="/track/same-day-delivery/" target="_blank" rel="noopener noreferrer">Time Direct</Link>  could be the right service for you.
                  </div><br />

                  <h2>How do I become a Time Express Client?</h2>
                  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                    You can visit the <Link to="/your-business" target="_blank" rel="noopener noreferrer"> Your Business page</Link>, there you can find out what the right service is for you as well as details of our service propositions as well as benefit from our excellent international parcels prices. Then you can complete the contact form and we will get in touch with you.
                    <br />
                    If you only want to send a few parcels then Time Direct could be the right service for you.
                  </div>

                </div>
              </div>

              ) : null
              }
              {count == "0" && tabpath == 'prohibited' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2>Are there any prohibited items?</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      A full list of prohibited items can be found <Link to="/prohibited-restricted-items" target="_blank" rel="noopener noreferrer">here</Link>
                      <br />
                      Yes, we have a list of prohibited items<br />
                      If you want to send an item that is on the prohibited items list, please get in
                      touch so we can help you further. We also have <Link to="/packaging-advice" target="_blank" rel="noopener noreferrer">packaging guidelines</Link> for many items that go through our network, so please check them out too.</div>

                  </div>
                </div>
              ) : null
              }

              {count == "0" && tabpath == 'packaging' ? (
                <div className="details">
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
                    <h2>Packaging Advice</h2>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      You can find some advice on how to package your parcel <Link to="/packaging-advice" target="_blank" rel="noopener noreferrer">here.</Link></div>
                  </div>
                </div>
              ) : null
              }



              {/* </nav> */}



            </div>
          </div>
        </div>
      </section>

      <section id="tabs">

      </section>






    </>
  )

  function setopen(tab) {

    if (tabpath === tab) { // already displayed

      if (count == 0) {
        setCount(1);
      } else {
        setCount(0)
      }

    } else {
      setCount(0);
      settabpath(tab)
    }
    console.log(tab)
    if (count == 0) {
      axios.get(`http://127.0.0.1:3000/${tab}`)
        .then((res) => sethelp(res.data))
        .catch(err => console.log(err))
      console.log(help)
      return (
        <div className="details">
          <h2>Fuel {tabpath}</h2>
          <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              11111111 ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
            </div>

          </div>
        </div>

      )

    }

  }




}





