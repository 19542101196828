import React, { useState ,forwardRef, useRef, useEffect, createRef } from 'react'

import axios from 'axios';

import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import banner from '../../images/banners/login.jpg'
import logo from '../../images/logo-purple.png'
import { country_code_array } from './functions';
// import TagManager from 'react-gtm-module'
// const tagManagerArgs = {
//   gtmId: 'GTM-NR8WXLS'
// }
// TagManager.initialize(tagManagerArgs)
 
const ComponentToPrint = React.forwardRef((props, ref) => {
    const { value } = props;
    return (
      <div className="print-source" ref={ref}>
         {value.name}
      </div>
    );
  });

  const ComponentToPrintWrapper = ({ item }) => {
    const componentRef = useRef();
  const [details, setdetails] = useState({
    name:'laiji',
    number:'3'
  })

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;
const display_currency=sessionStorage.getItem('display_currency');
    let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
const today=`${year}/${month}/${date}`
const [parcelcount, setparcelcount] = useState(1)
    const location = useLocation();
    const [order, setorder] = useState('')
    const [parcelcontent, setparcelcontent] = useState('')
    const [countries, setcountries] = useState(country_code_array);
    const [codebar, setcodebar] = useState(1)
    const [orderdata, setorderdata] = useState(false)
    const [downloadlink, setdownloadlink] = useState()
    useEffect(() => {
      if(order==""){

     
       
    const API_PATH2=`${INTEGRA_URL}get_order_details.php`;
    axios({
        method: 'post',
        url: `${API_PATH2}`,
        data: location.state.orderno
      })
      .then((res)=>{
    
    if(res.data !=""){
      setorderdata(true);
    
      setorder(JSON.parse(JSON.stringify(res.data)))
      if(res.data.parcel!=""){
     setparcelcontent(res.data.parcel)
      setparcelcount(res.data.parcel.length)
      }
    
      setdownloadlink('https://timexpress.ae/label/pdf-download-awbno.php?awb='+location.state.awbdetail.awbdetails.awbNo)
      Afterdata()
    
    }
  
      })
      .catch((err)=>console.log(err))
    }else{
     
    }
  }, [])

      const ref = useRef();
      const ref1 = useRef();

function Afterdata(){
      window.dataLayer = window.dataLayer || [];  
      window.dataLayer.push({
        'event':'purchase',
        'order_value':order.orderdetails.total_price,
        'order_id':location.state.orderno,
        'enhanced_conversion_data': {
        "email": order.collectiondetails.phone
        }
      })
    }

    return (
      <div style={{ display: "flex" }}>
       
        <ReactToPrint
          trigger={() => <button type="primary">Print</button>}
          content={() => componentRef.current}
        />
        <ComponentToPrint ref={componentRef} value={details} />
      </div>
    );
  };

export default function Test() {
    
const numbers = [1, 2, 3, 4, 5];

  return (
    <>
      <div class="awblabel ">

<Helmet>
        <title>Time Express | Order Confirmation</title>
      </Helmet>
        <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
        <div className="container"><div className="row">
          <div className="col-md-12 col-sm-12 your_business text-center">
            <div className="business_content">
              
              <h1 className='h1-title'>ORDER DETAIL</h1>
            </div>
          </div>
        </div>
        </div>
      </section>
      {numbers.map(function (item, index) {
        return <ComponentToPrintWrapper key={index} item={item} />;
      })}
      </div>
    </>
  );
       
        
}
