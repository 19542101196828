
import React, { useEffect, useState } from 'react'
import {  Link, useNavigate } from 'react-router-dom'
import humburgor from '../../../images/humburgor.png'
import closeicon from '../../../images/close.png'
import banner from '../../../images/banners/business.jpg'
export default function Dashboard_banner() {
    const [showmenu, setshowmenu] = useState(false)
const [menuicon, setmenuicon] = useState(`${humburgor}`)
function displayMenu(){
  if(showmenu==false){
    setshowmenu(true)
    setmenuicon(`${closeicon}`)
  }else{
    setshowmenu(false)
    setmenuicon(`${humburgor}`)
  }
  
}
const navigate=useNavigate()
function dashboard_logout(){
  sessionStorage.removeItem('accountno')
 // alert("user logged out successfully");
  navigate('/client-zone')
}
  return (
    
    <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 your_business text-center client_zone">
          <div className="business_content">
            <h2><span>DASHBOARD</span></h2></div>
         
        </div>
      </div>

      <div className="row" style={{position: "absolute",bottom: "1px"}}>
<div className="col-md-3">
            <div className="dropdown">
            <img src={menuicon} alt=""  onClick={displayMenu} />
   
    {showmenu?( <div className="dropdown-menu" style={{padding:"3%",display:"block"}} aria-labelledby="dropdownMenuButton" >
      <Link className="dropdown-item" to="/dashboard">Dashboard</Link>
      <Link className="dropdown-item" to="/dashboard/domestic-orders">Domestic Order</Link>
      <Link className="dropdown-item" to="/dashboard/international-orders">International Order</Link>
      <a className="dropdown-item" onClick={dashboard_logout}>Logout</a>
    </div>):null
    }
   
  </div>
  </div>
            </div>
    </div>
  </section>
  )
}
