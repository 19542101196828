import React from 'react'
import { Helmet } from 'react-helmet'
import '../../Custom.css'
import banner from '../../images/banners/collect.jpg'
export default function Friday_service() {
  return (
    <div className="improvelive">
          <Helmet>
        <title>Time Express | Sunday Delivery</title>
      </Helmet>
        <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
              <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                            <h1 className="mainhead">SUNDAY DELIVERY</h1>
                            <p>We're here to help</p>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="single_content">
 <article id="post-272" className="post-272 track type-track status-publish has-post-thumbnail hentry"><div className="become_driver post_driver"><div className="entry-content">
 <h1 style={{display: 'none'}}>Sunday Delivery</h1>
 <h4>Sure Thing.</h4>
 <p>You can also have your parcels delivered on a Sunday with Time Express.</p>
 <p>If you wish to have your parcel delivered on a Sunday, we will collect it on Saturday. Can’t your parcel be delivered on a Sunday because the recipient is not at home? In that case, we will try again on the following working day.</p>
 <p>The benefits of Sunday service delivery comes at a premium by sender selecting for this option on AWB.</p>
 <p>At a glance our Sunday delivery service benefits:<br /> Your client does not need to take parcel delivery into account in his weekday planning.<br /> Your client is more likely to be at home on a Sunday.</p>
 </div>
 </div>
 <footer className="entry-footer " /></article>
 <div className /></div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
