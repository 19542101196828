import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faStar} from '@fortawesome/free-solid-svg-icons'
import angela from '../../images/testimonials/angela.png'
import aibel from '../../images/testimonials/aibel.png'
import brenda from '../../images/testimonials/brenda.png'
import ciju from '../../images/testimonials/ciju.png'
import reem from '../../images/testimonials/reem.png'
import savio from '../../images/testimonials/savio.png'
const star = <FontAwesomeIcon icon={faStar} />
export default function Testimonial() {

    const settings = {
        initialSlide: 2,
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 320,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
            },
            {
              breakpoint: 1024,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
            }
          ]
    };
    return (

        <Slider {...settings}>
            <link
                rel="stylesheet"
                type="text/css"
                charset="UTF-8"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />

            <div className="testimonail">
                <div className="text">
                    <div className="pfp">
                        <img src={aibel} alt="ss" />
                    </div>

                    <div className="quote testi_name">Aibel Briones</div>
                    <p className="review">
                    The delivery and installation are very good and the guys are very polite and nice. Overall the service was really good from the ordering to delivery and installation
                    </p>
                  <p> {star} {star}  {star}  {star}  {star} </p>
                </div>
                <div className="info">
                    <div className="information">
                       
                        
                    </div>
                </div>
            </div>

              <div className="testimonail">
                <div className="text">
                    <div className="pfp">
                        <img src={reem} alt="ss" />
                    </div>

                    <div className="quote testi_name">Reem Abutalib</div>
                    <p className="review">
                    Honestly so happy with this service, the tracking is constantly updated and they’re so fast with the delivery!
                    </p>
                    <p> {star} {star}  {star}  {star}  {star} </p>
                </div>
                <div className="info">
                    <div className="information">
                       
                        
                    </div>
                </div>
            </div>

            <div className="testimonail">
                <div className="text">
                    <div className="pfp">
                        <img src={brenda} alt="ss" />
                    </div>

                    <div className="quote testi_name">Brenda Kirkland</div>
                    <p className="review">
                    My experience with this company was excellent. They have notice before the delivery and the items were delivered on time and professionally.
                    </p>
                    <p> {star} {star}  {star}  {star}  {star} </p>
                </div>
                <div className="info">
                    <div className="information">
                        <span className="name"></span>
                        
                    </div>
                </div>
            </div>

            
            <div className="testimonail">
                <div className="text">
                    <div className="pfp">
                        <img src={ciju} alt="ss" />
                    </div>

                    <div className="quote testi_name">Ciju chandran</div>
                    <p className="review">
                    Very competitive pricing and excellent service. Fully satisfied and very easy to recommend.
                    </p>
                    <p> {star} {star}  {star}  {star}  {star} </p>
                </div>
                <div className="info">
                    <div className="information">

                        
                    </div>
                </div>
            </div>
            
            <div className="testimonail">
                <div className="text">
                    <div className="pfp">
                        <img src={angela} alt="ss" />
                    </div>

                    <div className="quote testi_name">Angela Carlos</div>
                    <p className="review">
                    If I were to describe the customer service at Time Express Parcels LLC, I would call it exceptional. Highly Recommended for the service provided especially Mark who attended and follow up my transaction! And the price is way more better than others. Thank you for providing us an excellent service looking forward for more shipment ahead. Cheers!
                    </p>
                    <p> {star} {star}  {star}  {star}  {star} </p>
                </div>
                <div className="info">
                    <div className="information">
                       
                        
                    </div>
                </div>
            </div>
           
            <div className="testimonail">
                <div className="text">
                    <div className="pfp">
                        <img src={savio} alt="ss" />
                    </div>

                    <div className="quote testi_name">Savio Pinto</div>
                    <p className="review">
                    Well organized and extremely professional service, ensures realtime updates on every step of the process, provides great service at the most economical rates. Thank you for your great service even at this time of great challenges.
                    </p>
                    <p> {star} {star}  {star}  {star}  {star} </p>
                </div>
                <div className="info">
                    <div className="information">
                      
                        
                    </div>
                </div>
            </div>

           
          
          


        </Slider>
    );

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "#74686800"}}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background:  "#74686800" }}
                onClick={onClick}
            />
        );
    }
}