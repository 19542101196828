import React from 'react'

import blog1 from '../../../images/blogs/1.jpg'

import banner from '../../../images/banners/login.jpg'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
export default function SaveShippingcost() {
  return (
    <div>
           <Helmet>
        <title>Time Express | Articles</title>
      </Helmet>
          <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
             <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                            <h2>HOW TO SAVE COSTS IN SHIPPING?</h2>
                         
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content blogmain">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
               <div className="row">
               <div className="container-fluid py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5" >
            <img src={blog1} style={{width:'100%'}} />
          </div>
          <div className="row ">
            <div className="col-md-12">
              <div className="bg-light rounded overflow-hidden">
              <h4>How to save costs in shipping?</h4>
              <p>By Time Express Parcels – Majed Zambaraji</p>
              <p>Importing a shipment from China, USA, UK, or any location involves significant cost for your business. It is estimated that the cost of freight for any distribution company is normally at 7% to 15% of invoice value – which is a lot.</p>
              <p>However, there are ways that you can minimize on your shipping costs, here’s a few pointers;</p>
              
              <h5>1. SHIPMENT WEIGHT</h5>
              <p>First check your shipment weight. The normal rule of thumb is if the shipment is less than 70Kg sending as express courier is cheaper and faster. If it is greater than 70 Kg then air freight is your best and most economical option. If time is not an issue and your shipment is heavy then go with sea freight.</p>

              <h5>2. ACTUAL VERSUS VOLUMETRIC WEIGHT</h5>
              <p>Actual weight is what you get on the weight scale. Volumetric weight is based on how much space does your item takes in terms of length, height, and width. A mattress that weights 12 kg and has large dimensions would be charged at 29 Kg. Ensure that you do not pay for shipping air and condense your package as much as possible for cheaper rates.</p>
              <h5>3. DIRECT VERSUS TRANSSHIPMENTS</h5>
              <p>Many companies do not take into consideration the element of risk of late delivery when your shipment is dispatched as transshipment. A transshipment usually stops at middle ports or airports which then offload and re-load your cargo on a new vessel or plane. A shipment from Shanghai to London via Rotterdam carries more risk than a direct flight Shanghai to London. Often, there is delays that happen in making these connections as well as sometimes capacity issues can cause the load to be scheduled on next connection trip.</p>
              <h5>4. FREQUENCY</h5>
              <p>Sending a heavier shipment is cheaper than sending a lighter shipment. A shipment from London to Madrid where you dispatch 400 Kg daily would cost you more than sending 800 Kg every 2 days. Look for ways where you can consolidate cargo to avoid high line haul rates.</p>
              <h5>5. PEAK SEASON</h5>
              <p>There are times of the year when capacity on planes and vessels becomes more difficult. These periods like December with Christmas command a higher premium for sending your goods cross border. Similarly India mango season is from April to July, South Africa Kiwi season is October through May, and Netherlands strawberry season is March to December. Look for months of the year when congestion on cargo are less and take advantage of lower rates.</p>
              <h5>6. CUSTOMS CLEARANCE</h5>
              <p>The customs clearance process in express courier can be cheaper and faster than in air freight. An express shipment is sent as consolidation of hundreds of HAWB parcels under one manifest and one MAWB whereas an airfreight shipment clears by itself under one MAWB. The airlines give priority clearance to express as it is classified as more urgent.</p>
              <h5>7. ROAD TRANSPORT</h5>
              <p>Road transport can be a great way to get your products deliver fast and less costly. Road comes in different products such as less than truck load (LTL) and full truck loads (FTL). A less than truck load would be a pallet where you can share space on a truck with other shippers, a full truck load is where you contract a full truck 3 ton, 5 ton, 7 ton, 10 ton, or 40 footer or less to send your products.</p>
              <h5>8. MULTIPLE QUOTATIONS</h5>
              <p>Freight rates quotations can vary and freight forwarders go to the asset holders being the shipping lines and airlines to get rates. The airlines cannot give cheap rates to all freight forwarders as it causes price erosion – but they do give better prices to the freight companies they trust and will give them the cargo. Also larger freight forwarders like Schenker, Kuehne Nagel, and DSV have higher volumes with airlines and shipping lines and can leverage their buy for better prices; they however tend to focus on the very large customers like Apple, Lenovo, Nokia, etc. It is wise to obtain at least 3 quotes for every shipment you send in order to ensure you are getting a competitive price.</p>
              
              </div>
            </div>
            
          </div>
        </div>
      </div>
               </div>
          
    </div>
    </div>
    </main>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
