import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom'
import { country_code_array } from './functions';
import "react-datepicker/dist/react-datepicker.css";
import '../../Home1.css'
import banner from '../../images/home/time-home-bg-1.jpg'
import axios from 'axios';
export default function Hometest() {

    const navigate = useNavigate()
    const [countries, setcountries] = useState(country_code_array);

    const ip_code = '5.195.227.207'; // find ip
    sessionStorage.setItem('ip_code', ip_code)

    const [showdiv, setshowdiv] = useState('exportdiv')
    const [typediv, settypediv] = useState('package')
    const [exportclass, setexportclass] = useState('active')
    const [importclass, setimportclass] = useState('')
    const [packclass, setpackclass] = useState('active')
    const [docclass, setdocclass] = useState('')

    const [title, settitle] = useState('The Cheapest Parcel Delivery In The UAE.')
    function changefun(get_id) {
        if (get_id == 'in-package-document-tab') {
            settitle("The Cheapest Parcel Delivery In The UAE.")
        } else if (get_id == 'in-freight-tab') {
            settitle("Priority Service For Heavier Shipments.")
        } else if (get_id == 'road-gcc-tab') {
            settitle("Economy Truck Service Express For Heavy Boxes (GCC Only).");
        } else {
            settitle("Send A Parcel Anywhere In The UAE.");
        }
    }

    const date = new Date();

    let result = date.setDate(date.getDate() + 2);

    const [startDate, setStartDate] = useState(new Date(result));


    const [getQuotedata, setgetQuotedata] = useState(
        {
            origin: 'AE',
            destination: 'GB',
            shipmenttype: "exportdiv",
            typediv: "package",
            // weight: ['0.5'],
            // length: [],
            // height: [],
            // width: [],
            shipping_date: startDate,
               dimensions:{
                weight:['0.5'],
                length:[],
                width:[],
                height:[]

               }


        }
    )

 console.log(getQuotedata)
function updateweight(val,i){
    const updatedCount = 1;
    const clonedListing = {...getQuotedata.dimensions};
    const updatedAccepted = [...clonedListing.weight]
    updatedAccepted[i] = val;
    setgetQuotedata({...getQuotedata,
        dimensions: {
          ...clonedListing,
          weight: updatedAccepted
        }
      });

}

function updatelength(val,i){
    
    const clonedListing = {...getQuotedata.dimensions};
    const updatedAccepted = [...clonedListing.length]
    updatedAccepted[i] = val;
    setgetQuotedata({...getQuotedata,
        dimensions: {
          ...clonedListing,
          length: updatedAccepted
        }
      });

}

function updatewidth(val,i){
   
    const clonedListing = {...getQuotedata.dimensions};
    const updatedAccepted = [...clonedListing.width]
    updatedAccepted[i] = val;
    setgetQuotedata({...getQuotedata,
        dimensions: {
          ...clonedListing,
          width: updatedAccepted
        }
      });

}

function updateheight(val,i){
   
    const clonedListing = {...getQuotedata.dimensions};
    const updatedAccepted = [...clonedListing.height]
    updatedAccepted[i] = val;
    setgetQuotedata({...getQuotedata,
        dimensions: {
          ...clonedListing,
          height: updatedAccepted
        }
      });

}




    function showthisdiv(val) {
        if (val == 'exportdiv') {

            setshowdiv('exportdiv')
            setexportclass('active')
            setimportclass('')
            setgetQuotedata({ ...getQuotedata, shipmenttype: val, origin: 'AE', destination: 'GB' })
        } else if (val == 'importdiv') {

            setshowdiv('importdiv')
            setexportclass('')
            setimportclass('active')
            setgetQuotedata({ ...getQuotedata, shipmenttype: val, origin: 'GB', destination: 'AE' })

        }


    }




    function showtypediv(val) {
        if (val == 'package') {
            settypediv('package')
            setpackclass('active')
            setdocclass('')
        } else if (val == 'document') {
            settypediv('document')
            setpackclass('')
            setdocclass('active')
        }
        setgetQuotedata({ ...getQuotedata, typediv: val })
    }




    const [countdiv, setcountdiv] = useState(1)
    function increment() {
        setcountdiv((prevCount) => prevCount + 1);
    }


    function removemore(val){
        console.log(val);
        setcountdiv((prevCount) => prevCount - 1);
        const clonedListing = {...getQuotedata.dimensions};
        const updatedAcceptedweight = [...clonedListing.weight]
        const updatedAcceptedlength = [...clonedListing.length]
        const updatedAcceptedwidth = [...clonedListing.width]
        const updatedAcceptedheight = [...clonedListing.height]
        updatedAcceptedweight.splice(val, 1);
        updatedAcceptedlength.splice(val, 1);
        updatedAcceptedwidth.splice(val, 1);
        updatedAcceptedheight.splice(val, 1);
        setgetQuotedata({...getQuotedata,
            dimensions: {
              ...clonedListing,
              height: updatedAcceptedheight,
              length:updatedAcceptedlength,
              width:updatedAcceptedwidth,
              weight:updatedAcceptedweight,
            }
          });
        }

    return (
        <div>
            <div>
                <div className="quick-quote-home-bg">
                    <div className="img-container">
                        <img src={banner} width={1349} height={847} />
                    </div>
                </div>
                <div className="quick-quote-home">
                    <div className="tab-tittle-page">
                        <div style={{ height: '40px' }} />
                        <h1 className="h1-title">{title}</h1>
                    </div>
                    <div className="tab-quick-quote">
                        <div className="container">
                            <div style={{ height: '40px' }} />
                            <ul className="nav nav-tabs" id="myTab" role="tablist" style={{ minHeight: "auto" }}>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => changefun('in-package-document-tab')} className="nav-link active" id="in-package-document-tab" data-bs-toggle="tab" data-bs-target="#send_international_package_document" type="button" role="tab" aria-controls="send_international_package_document" aria-selected="true">Send Parcel International</button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" onClick={() => changefun('uae-uae-tab')} id="uae-uae-tab" data-bs-toggle="tab" data-bs-target="#uae-uae" type="button" role="tab" aria-controls="uae-uae" aria-selected="false">Send UAE to UAE</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="send_international_package_document" role="tabpanel" aria-labelledby="in-package-document-tab">
                                    <div className="container">
                                        <div className="row">
                                            {/* Send International Package */}
                                            <form action method="post" id="send-in-package" style={{ padding: 0 }}>
                                                <div className="quick-holder">
                                                    <div className="col-md-5 col-sm-5">
                                                        <h3 className="section-head">Where are you sending your parcels from/to?</h3>
                                                        <div className="tab-in-export-import">
                                                            <a onClick={() => showthisdiv('exportdiv')} className={`export-link in-send-package ${exportclass}`} id="export-link-ip">Export</a>
                                                            <a onClick={() => showthisdiv('importdiv')} className={`import-link in-send-package  ${importclass}`} id="import-link-ip">Import</a>
                                                            <div className="tab-package-document">
                                                                <a onClick={() => showtypediv('package')} className={`package-link package-document ${packclass}`} id="package-link">Package</a>
                                                                <a onClick={() => showtypediv('document')} className={`document-link package-document ${docclass}`} id="document-link">Document</a>
                                                            </div>
                                                        </div>
                                                        {/* International Export Package */}
                                                        {showdiv && showdiv == 'exportdiv' ? (
                                                            <div className="tab-in-export-package">
                                                                <ul className="in-export-package from-to">
                                                                    <li>
                                                                        <label>From</label>
                                                                        <select className="form-select select-export-package" name="delivery_from" onChange={(e) => setgetQuotedata({ ...getQuotedata, origin: e.target.value })}>
                                                                            <option value="AE">Abu Dhabi</option>
                                                                            <option value="AE">Ajman</option>
                                                                            <option value="AE">Al Ain</option>
                                                                            <option value="AE" selected >Dubai</option>
                                                                            <option value="AE">Fujairah</option>
                                                                            <option value="AE">Ras Al Khaimah</option>
                                                                            <option value="AE">Sharjah</option>
                                                                            <option value="AE">Umm Al Quwain</option>
                                                                        </select>
                                                                    </li>
                                                                    <li>
                                                                        <label>To</label>
                                                                        <select className="form-select select-export-package" name="delivery_to" onChange={(e) => setgetQuotedata({ ...getQuotedata, destination: e.target.value })}>
                                                                            {

                                                                                Object.entries(countries).map(([k, v]) => (
                                                                                    <option key={k} value={k} selected={k === 'GB'}>{v}</option>
                                                                                ))
                                                                            }

                                                                        </select>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        ) : null
                                                        }
                                                        {/* End International Export Package */}
                                                        {/* International Import Package */}
                                                        {showdiv && showdiv == 'importdiv' ? (


                                                            <div className="tab-in-import-package" >
                                                                <ul className="in-import-package from-to">
                                                                    <li>
                                                                        <label>From</label>
                                                                        <select className="form-select select-export-package" name="delivery_to" onChange={(e) => setgetQuotedata({ ...getQuotedata, origin: e.target.value })}>
                                                                            {

                                                                                Object.entries(countries).map(([k, v]) => (
                                                                                    <option key={k} value={k} selected={k === 'GB'}>{v}</option>
                                                                                ))
                                                                            }

                                                                        </select>
                                                                    </li>
                                                                    <li>
                                                                        <label>To</label>
                                                                        <select className="form-select select-export-package" name="delivery_from" onChange={(e) => setgetQuotedata({ ...getQuotedata, destination: e.target.value })}>
                                                                            <option value="AE">Abu Dhabi</option>
                                                                            <option value="AE">Ajman</option>
                                                                            <option value="AE">Al Ain</option>
                                                                            <option value="AE" selected >Dubai</option>
                                                                            <option value="AE">Fujairah</option>
                                                                            <option value="AE">Ras Al Khaimah</option>
                                                                            <option value="AE">Sharjah</option>
                                                                            <option value="AE">Umm Al Quwain</option>
                                                                        </select>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        ) : null
                                                        }
                                                        {/* End International Import Package */}
                                                    </div>
                                                    <div className="col-md-7 col-sm-7">
                                                        <div id="add_more_weight_package_in" className="add_more_weight">
                                                            {[...Array(countdiv)].map((e, i) => <><div key={i}>
                                                                <h3 className="section-head">Enter the weight and dimensions of your parcels</h3>
                                                                <div className="weight-section">
                                                                    <ul className="in-export-package from-to">

                                                                        {typediv && typediv == 'package' ? (
                                                                            <li>
                                                                                <label>Weight1*(kg)</label>
                                                                                <select className="form-select" name="weight[]" onChange={(e)=>updateweight(e.target.value,i)}>
                                                   
                                                                                 {i>0?(
                                                                                     <option value="">select weight</option>
                                                                                 ):null
                                                                                 }
                                                                                    <option value="0.5">0.5</option>
                                                                                    <option value="1">1</option>
                                                                                    <option value="1.5">1.5</option>
                                                                                    <option value="2">2</option>
                                                                                    <option value="2.5">2.5</option>
                                                                                    <option value="3">3</option>
                                                                                </select>
                                                                            </li>
                                                                        ) : <li>
                                                                            <label>Weight2* (kg)</label>
                                                                            <select className="form-select" name="weight[]" onChange={(e)=>updateweight(e.target.value,i)}>
                                                                            {i>0?(
                                                                                     <option value="">select weight</option>
                                                                                 ):null
                                                                                 }
                                                                                <option value="0.5">0.5</option>
                                                                                <option value="1">1</option>
                                                                                <option value="1.5">1.5</option>
                                                                                <option value="2">2</option>
                                                                            </select>
                                                                        </li>
                                                                        }


                                                                        <li>
                                                                            <label>Length (cm)</label>
                                                                            <input className="form-control" name="length[]" type="text" onChange={(e)=>updatelength(e.target.value,i)} />
                                                                        </li>
                                                                        <li>
                                                                            <label>Width (cm)</label>
                                                                            <input className="form-control" name="width[]" type="text" onChange={(e)=>updatewidth(e.target.value,i)} />
                                                                        </li>
                                                                        <li>
                                                                            <label>Height (cm)</label>
                                                                            <input className="form-control" name="height[]" type="text" onChange={(e)=>updateheight(e.target.value,i)} />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <button type="button" onClick={()=>removemore(i)}>x</button>
                                                            </div>
                                                            </>
                                                            )
                                                            }
                                                        </div>
                                                        <button onClick={increment} className="btn btn-primary btn-parcels add-btn add_more_sizes" id="in_package" type="button" >Add another package 1</button>
                                                    </div>
                                                </div>
                                                <hr className="hr_border" />
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-8 col-sm-8">
                                                            <div className="row">
                                                                <div className="col-md-6 col-sm-6">
                                                                    <h3 className="section-head"><mark>When are you shipping?</mark></h3>
                                                                </div>
                                                                <div className="col-md-6 col-sm-6">
                                                                    <DatePicker className="form-control shipping_date" name="shipping_date" dateFormat='dd/MM/yyyy' selected={startDate} onChange={() => setStartDate(date)} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-4">
                                                            <div className="wrape_promo" style={{ textAlign: 'center' }}>
                                                                <span className="get_center_bnts">
                                                                    <button className="btn btn-primary" type="button" name="get_quotes" id="get_quote" flag="package" >Get Quote</button>
                                                                    <input type="hidden" name="get_quote" defaultValue="Get Quote" />
                                                                    <input type="hidden" name="parcel_type" id="in_parcel_type" defaultValue="export" />
                                                                    <input type="hidden" name="pac_type" id="pac-pac-link" defaultValue="package" />
                                                                    <input type="hidden" name="promo_discount" defaultValue id="promo_discount" />
                                                                </span>
                                                                {/* <div class="yodeldirect-hedding"><h3>Temporary Out of Service</h3></div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
