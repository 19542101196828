import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import banner from '../../images/banners/login.jpg'
import loading from '../../images/spin.gif'
export default function Reset_password() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const navigate=useNavigate()
    const [users, setusers] = useState()
   const [useremail, setuseremail] = useState()
  
    const [msg, setmsg] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const [loadingshow, setloadingshow] = useState(false)
   const [reset, setreset] = useState(searchParams.get("ref"))
    function resetpass(){
        const API_PATH=`${SERVER_URL}reset_password.php`;
        //const API_PATH='http://localhost:8000/reset_password.php';
        if(useremail!=""){
            setloadingshow(true)
           
            axios({
                method: 'post',
                url: `${API_PATH}`,
                data: useremail
            })
            .then((res)=>
            {
                setloadingshow(false)
               console.log(res.data)
                if(res.data.status===true){
                    
                    setmsg(res.data.message)
                 }else{
                    setmsg(res.data.message)
                 }
            }
            )
            .catch((err)=>console.log(err))

        }else{
            console.log("Enter username and password")
        }
   
      
      
    }

    const [collectionDetails, setcollectionDetails] = useState()
    const [errormsg, seterrormsg] = useState()
    const [errorcount, seterrorcount] = useState()
    function checkpassword(newpass){
        if(collectionDetails.user_pass!=newpass){
           
            seterrormsg('password mismatch')
            seterrorcount(1);
        }else{
            
            seterrormsg('')
            seterrorcount(0);
            setcollectionDetails({...collectionDetails,confirmpass:newpass})
        }

      }

      function resetpassword(){
        const Reset_API=`${SERVER_URL}reset_password.php?type=update`
        axios({
            method: 'post',
            url: `${Reset_API}`,
            data:{collectionDetails:collectionDetails,user:reset}
        })
        .then((res)=>
        {
           console.log(res.data)
            if(res.data.status===true){
                
                navigate('/login')
             }else{
                setmsg(res.data.message)
             }
        }
        )
        .catch((err)=>console.log(err))
      }

  return (
    <div>
        <Helmet>
        <title>Time Express | Reset Password</title>
      </Helmet>
    <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
         <div className="container"><div className="row">
             <div className="col-md-12 col-sm-12 your_business text-center">
                 <div className="business_content">
                 <h1 className="mainhead">RESET PASSWORD</h1>
                     
                 </div>
             </div>
         </div>
         </div>
     </section>
     <div className="pages_content">
     <div className="container">
     {msg?(
            <p className="reset_message"> <span>{msg}</span></p>
        ):null}
        {reset?(
         <div className="row">
         <div className="col-md-3"></div>
         <div className="col-md-6 loginbox" style={{border:"1px solid",marginTop:"6%",marginBottom:"6%"}}>
         <h5 className="resethead">Reset Password</h5>
         <div id="primary" className="content-area" style={{float: 'left', padding: '3%',width:"100%"}}>
     <main id="main" className="site-main" role="main">
     
     <form>
             <p>Please enter your new password. </p>    
            <input type="text" placeholder='Password' className="form-control mb-3" required onChange={(e) => setcollectionDetails({ ...collectionDetails, user_pass: e.target.value })}/>
            <input type="text" placeholder='Confirm Password' className="form-control mb-3" required onChange={(e) => checkpassword(e.target.value)} />
            <p style={{color:"red"}}>{errormsg}</p>
            <button type="button" className="btn  btn-sm" onClick={resetpassword} style={{backgroundColor:'#7b3995',color:"#fff",padding:"2%",width:"19%"}}>Reset Password</button>
      
        </form>
</main>
</div>
         </div>
   
</div>
        ):(
            <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 loginbox" style={{border:"1px solid",marginTop:"6%",marginBottom:"6%"}}>
            <h5 className="resethead">Reset Password</h5>
            <div id="primary" className="content-area" style={{float: 'left', padding: '3%',width:"100%"}}>
        <main id="main" className="site-main" role="main">
        
        <form>
                <p>Please enter your email address . You will receive a link to create a new password via email.</p>    
               <input type="text" placeholder='User Email' className="form-control mb-3" required onChange={(e)=>setuseremail(e.target.value)}/>
              
               <button type="button" className="btn  btn-sm" onClick={resetpass} style={{backgroundColor:'#7b3995',color:"#fff",padding:"2%",width:"19%"}}>Reset Password</button>
          <Link className="loginaction" to="/register">Are you a member?</Link>
          <Link className="loginaction" to="/reset-password">Forgot Password</Link>
       
      {loadingshow?(
      <p style={{textAlign:"center",marginTop:"2%"}}><img src={loading} style={{width:"5%"}}/></p> 
    ):null}
           </form>
   </main>
   </div>
            </div>
      
   </div>
        )}
       
     
   
</div>
</div>
</div>
  )
}
