import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import banner from '../../images/banners/collect.jpg'
import loc from '../../images/quote/navi-icon.png'
import bus from '../../images/quote/fastbus-icon.png'
import printer from '../../images/quote/printer-icon.png'
import dhl from '../../images/quote/dhl.jpg'
import aramex from '../../images/quote/aramex.jpg'
import '../../Quote.css'
import { useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
export default function Quote() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const input_qoute = sessionStorage.getItem('qoute_rate')
    const display_currency = sessionStorage.getItem('display_currency')
    const navigate = useNavigate()
    const get_gov_vat = sessionStorage.getItem('get_gov_vat')
    const location = useLocation();
    const [showdetails, setshowdetails] = useState(0)
    const [show, setShow] = useState(0)
    const [showindia, setShowindia] = useState(0)
    sessionStorage.removeItem('indiaquote');
    //sessionStorage.setItem('qouteinput',JSON.stringify(location.state))
    const inputs = JSON.parse(sessionStorage.getItem('qouteinput'))
    const downarrow = <FontAwesomeIcon icon={faAngleDown} />
    const show_details = {
        display: "block",
        backgroundColor: "#ffffff",
        marginBottom: "20px",
        marginLeft: "0",
        marginRight: 0,


    }
    
    const r_details = {
        marginBottom: "10px",
        backgroundColor: "#f9f9f9",
    }
    function setShowfunction(x) {
        let xx = show
        if (xx == 0) {
            setShow(1)
        } else {
            setShow(0)
        }
    }

    function setShowfunctionindia(x) {
        let yy = showindia
        if (yy == 0) {
            setShowindia(1)
        } else {
            setShowindia(0)
        }
    }
    const button_book = {
        background: "#7f3f98",
        color: "#fff",
        display: "inline-block",
        padding: "6px 25px",
        textTransform: " uppercase",
        borderColor: " #7f3f98",
        borderRadius: "6px",
        marginLeft: "50px",
        textDecoration: "none",
        textAlign: "center"
    }
    const button_book1 = {
        background: "#7f3f98",
        color: "#fff",
        display: "inline-block",
        padding: "6px 25px",
        textTransform: " uppercase",
        borderColor: " #7f3f98",
        borderRadius: "6px",
        marginLeft: "50px",
        textDecoration: "none",
        textAlign: "center",
        border:"none"
    }
   
    function indiaOrder() {
       sessionStorage.setItem('indiaquote',1)
        navigate('/order-detail')
       
    }

    const API_discount = `${SERVER_URL}is_user_discount.php`;
    const userid = sessionStorage.getItem('userid');
    const [indiarate, setindiarate] = useState()
    const [indiabase, setindia_base] = useState()
    const [indiadiscount, setindiadiscount] = useState()
    const [indiagovtlevy, setindiaindiagovtlevy] = useState()
    const [india_es, setindiaindia_es] = useState()
    useEffect(() => {
       
        if ((inputs['destination'] == 'IN' ||inputs['destination'] == 'PH' ||inputs['destination'] == 'PK') && location.state.weight <= 2) {
            axios({
                method: "post",
                url: `${API_discount}`,
                data: { userid: userid, typediv: inputs['typediv'], destination:inputs['destination'],weight:location.state.weight,dimensional_weight:location.state.chargable_weight}
            }).then((res) => {
                console.log(res.data)
                setindiadiscount(res.data.discount)
                setindiarate(res.data.rate)
                setindia_base(res.data.base)
                setindiaindiagovtlevy(res.data.govt_levy)
                setindiaindia_es(res.data.emergency)
            }).catch((err) => console.log(err))
        }
    }, [])



    return (
        <div className='quote'>
            <Helmet>
        <title>Time Express | Quotation</title>
      </Helmet>
            <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">QUOTATION</h1>

                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content">
                <div className="container">
                    <div className="row">
                        <div id="primary" className="content-area" style={{ float: 'left', width: '100%' }}>
                            <main id="main" className="site-main" role="main">
                                <div className="entry-content" style={{ background: "#fff", scrollPaddingBottom: "20px" }}>
                                    <div className="become_driver">
                                    
                                        <div className="col-md-12">
                                        {(inputs['destination'] == 'IN' || inputs['destination'] == 'PH'|| inputs['destination'] == 'PK') && location.state.weight <= 2 && location.state.chargable_weight<=2? (
                                        <h5>EXPRESS</h5>
                                        ):null}
                                            <div id="primary" className="content-area" style={{ float: 'left', padding: '3%', width: "100%" }}>

                                                <table className='table table-bordered  table-striped quotetable fordesk'>
                                                    <thead>
                                                        <tr>
                                                        {/* <th>Provider</th> */}
                                                            <th>Weight (Kg)</th>
                                                            <th>Volumetric Weight (Kg)</th>
                                                            <th>Destination</th>
                                                            <th>Transit Time (Days)</th>
                                                            <th>Total Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        {/* {inputs['origin']=='AUH' || inputs['origin']=='DXB'?(
                                                            <td style={{width:'10%'}}><img src={aramex} style={{width:'100%'}} /></td>
                                                        ):(
<td style={{width:'10%'}}><img src={dhl} style={{width:'100%'}} /></td>
                                                        )} */}
                                                            
                                                            <td>{location.state.weight}</td>
                                                            <td>{location.state.chargable_weight}</td>
                                                            <td> {inputs['destination']}</td>
                                                            <td>{location.state.get_transit_time}</td>
                                                            <td>{location.state.qoute_rate} AED   <span className="r_more_details" onClick={() => setShowfunction(1)}>More Details <i className="fa fa-angle-down" aria-hidden="true" />{downarrow}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>


                                                <div className="table-responsive other-option formobile">
                                                    <table className="table-bordered table-striped table-hover table" id="price-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Description</th>
                                                                <th>Total Shipping Price (AED)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {/* <td>Provider</td> */}
                                                                {/* {inputs['origin']=='AUH' || inputs['origin']=='DXB'?(
                                                            <td style={{width:'10%'}}><img src={aramex} style={{width:'100%'}} /></td>
                                                        ):(
<td style={{width:'10%'}}><img src={dhl} style={{width:'100%'}} /></td>
                                                        )} */}
                                                            </tr>
                                                            <tr>
                                                                <td>Weight (Kg)</td>
                                                                <td className="total-price" style={{ width: "65%" }}>{location.state.weight}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Volumetric Weight (Kg)</td>
                                                                <td className="total-price">{location.state.chargable_weight}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Destination</td>
                                                                <td className="total-price">{inputs['destination']} </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Transit Time (Days)</td>
                                                                <td className="total-price">{location.state.get_transit_time}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Price</td>
                                                                <td className="total-price"> {location.state.qoute_rate} AED <span className="r_more_details" onClick={() => setShowfunction(1)}>More Details <i className="fa fa-angle-down" aria-hidden="true" />{downarrow}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>


                                        {show && show > 0 ? (
                                            <div className="row show_details" style={show_details}>
                                                <div className="col-md-3">&nbsp;</div>
                                                <div className="col-md-6" style={{ padding: '12px 0px', margin: "0 auto" }}>
                                                    <div className="r_details" style={r_details}>
                                                        <ul><li>Transportation Charge</li>
                                                            <li className="r-right">{location.state.basic} <i className="fa fa-times-circle close_details" aria-hidden="true" />
                                                            </li>
                                                        </ul></div>
                                                    {
                                                        location.state.charge_rate > 0 ? (
                                                            <div className="r_details" style={r_details}>
                                                                <ul><li>  {location.state.get_gov_vat}</li>
                                                                    <li className="r-right">{location.state.charge_rate}</li>
                                                                </ul>
                                                            </div>
                                                        ) : null
                                                    }
                                                    {
                                                        location.state.es_surcharge > 0 ? (
                                                            <div className="r_details" style={r_details}>
                                                                <ul><li>Emergency Situation Surcharge</li>
                                                                    <li className="r-right">{location.state.es_surcharge}</li>
                                                                </ul>
                                                            </div>
                                                        ) : null
                                                    }

                                                    {location.state.oversize > 0 ? (
                                                        <div className="r_details" style={r_details}>
                                                            <ul><li>Oversize Charge</li>
                                                                <li className="r-right">{location.state.oversize}</li>
                                                            </ul>
                                                        </div>
                                                    ) : null
                                                    }
                                                    {location.state.er_charge > 0 ? (
                                                        <div className="r_details" style={r_details}>
                                                            <ul><li>Elevated Risk Charge</li>
                                                                <li className="r-right">{location.state.er_charge}</li>
                                                            </ul>
                                                        </div>
                                                    ) : null
                                                    }

                                                    {
                                                        location.state.rd_charge > 0 ? (
                                                            <div className="r_details" style={r_details}>
                                                                <ul><li>Restricted Destination Charge</li>
                                                                    <li className="r-right">{location.state.rd_charge}</li>
                                                                </ul>
                                                            </div>
                                                        ) : null
                                                    }

                                                    {
                                                        location.state.demand_surcharge > 0 ? (
                                                            <div className="r_details" style={r_details}>
                                                                <ul><li>Demand Surcharge</li>
                                                                    <li className="r-right">{location.state.demand_surcharge}</li>
                                                                </ul>
                                                            </div>
                                                        ) : null
                                                    }
                                                    {location.state.user_discount > 0 ? (
                                                        <div className="r_details" style={r_details}>
                                                            <ul>
                                                                {/* <li>User Discount D-Rate (20%)</li> */}
                                                                <li>{location.state.user_text}</li>
                                                                <li className="r-right">- {location.state.user_discount}</li>
                                                            </ul>
                                                        </div>
                                                    ) : null
                                                    }
                                                  
                                                    <div className="r_details" style={r_details}>
                                                        <ul><li style={{ fontWeight: 'bold' }}>Total</li>
                                                            <li className="r-right" style={{ fontWeight: 'bold' }}>{location.state.qoute_rate} AED</li>
                                                        </ul></div>
                                                </div>
                                                <div className="col-md-3">&nbsp;</div>
                                            </div>
                                        ) : null
                                        }

                                        
                                        <div className="col-md-12">
                                            <div className="submit" style={{ float: "right", marginRight: "35px" }}>
                                             
                                               <Link to="/order-detail" style={button_book}>Book Now</Link>
                                            </div>
                                        </div>

                                    </div>

                                    {(inputs['destination'] == 'IN'|| inputs['destination'] == 'PH' || inputs['destination'] == 'PK')&& location.state.weight <= 2 && location.state.chargable_weight<=2? (
                                                    <>
                                                     <div className="become_driver">
                                        <div className="col-md-12">
                                        <h5>ECONOMY</h5>
                                            <div id="primary" className="content-area" style={{ float: 'left', padding: '3%', width: "100%" }}>
                                               
                                                        <table className='table table-bordered  table-striped quotetable fordesk'>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>Provider</th> */}
                                                                    <th>Weight (Kg)</th>
                                                                    <th>Volumetric Weight (Kg)</th>
                                                                    <th>Destination</th>
                                                                    <th>Transit Time (Days)</th>
                                                                    <th>Total Price</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                {/* <td style={{width:'10%'}}><img src={dhl} style={{width:'100%'}} /></td> */}
                                                                    <td>{location.state.weight}</td>
                                                                    <td>{location.state.chargable_weight}</td>
                                                                    <td> {inputs['destination']}</td>
                                                                    <td>6</td>
                                                                    {inputs['typediv'] == "package" ? (
                                                                        <td>{indiarate} AED  <span className="r_more_details" onClick={() => setShowfunctionindia(1)}>More Details <i className="fa fa-angle-down" aria-hidden="true" />{downarrow}</span> </td>
                                                                    ) : (<td>{indiarate} AED   <span className="r_more_details" onClick={() => setShowfunctionindia(1)}>More Details <i className="fa fa-angle-down" aria-hidden="true" />{downarrow}</span></td>)
                                                                    }

                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                        <div className="table-responsive other-option formobile">
                                                    <table className="table-bordered table-striped table-hover table" id="price-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Description</th>
                                                                <th>Total Shipping Price (AED)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                                {/* <td>Provider</td> */}
                                                               
{/* <td style={{width:'10%'}}><img src={dhl} style={{width:'100%'}} /></td> */}
                                                     
                                                            </tr>
                                                            <tr>
                                                                <td>Weight (Kg)</td>
                                                                <td className="total-price" style={{ width: "65%" }}>{location.state.weight}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Volumetric Weight (Kg)</td>
                                                                <td className="total-price">{location.state.chargable_weight}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Destination</td>
                                                                <td className="total-price">{inputs['destination']} </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Transit Time (Days)</td>
                                                                <td className="total-price">6</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Price</td>
                                                                <td className="total-price">{indiarate} AED <span className="r_more_details" onClick={() => setShowfunctionindia(1)}>More Details <i className="fa fa-angle-down" aria-hidden="true" />{downarrow}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                        {showindia && showindia > 0 ? (
                                                            <div className="row show_details" style={show_details}>
                                                                <div className="col-md-3">&nbsp;</div>
                                                                <div className="col-md-6" style={{ padding: '12px 0px', margin: "0 auto" }}>
                                                                    <div className="r_details" style={r_details}>
                                                                        <ul><li>Transportation Charge</li>
                                                                            <li className="r-right">{indiabase}<i className="fa fa-times-circle close_details" aria-hidden="true" />
                                                                            </li>
                                                                        </ul></div>
                                                                    {indiadiscount > 0 ? (
                                                                        <div className="r_details" style={r_details}>
                                                                            <ul><li>User Discount D-Rate (20%)</li>
                                                                                <li className="r-right">- {indiadiscount}</li>
                                                                            </ul>
                                                                        </div>
                                                                    ) : null
                                                                    }
                                                                    {
                                                                        indiagovtlevy > 0 ? (
                                                                            <div className="r_details" style={r_details}>
                                                                                <ul><li> Mail Service Process (10%)</li>
                                                                                    <li className="r-right">{indiagovtlevy} </li>
                                                                                </ul>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                    {
                                                                        india_es > 0 ? (
                                                                            <div className="r_details" style={r_details}>
                                                                                <ul><li>Emergency Situation Surcharge</li>
                                                                                    <li className="r-right">{india_es} </li>
                                                                                </ul>
                                                                            </div>
                                                                        ) : null
                                                                    }



                                                                    <div className="r_details" style={r_details}>
                                                                        <ul><li style={{ fontWeight: 'bold' }}>Total</li>
                                                                            <li className="r-right" style={{ fontWeight: 'bold' }}>{indiarate}  AED</li>
                                                                        </ul></div>
                                                                </div>
                                                                <div className="col-md-3">&nbsp;</div>
                                                            </div>
                                                        ) : null
                                                        }

                                                        

                                                        <div className="col-md-12">
                                            <div className="submit" style={{ float: "right" }}>
                                                
                                                <button onClick={indiaOrder} style={button_book1}>Book Now</button>
                                            </div>
                                        </div>
                                                   

                                            </div>
                                        </div>
                                        </div>
                                        </>

                                        ) : null}



                                    <div className="col-md-12" style={{ display: "flex", width: "100%" }}>

                                        <div className="content-serv border_text" style={{ padding: "3%", border: "5px solid #f5f5f5", margin: "3%", width: "100%" }}>

                                            <div className="row mainbox">

                                                <div className="col-md-2 quotefirst quotebox">
                                                    <img src={loc} style={{ width: "30%" }} className=" " />
                                                    <p>Fully Tracked</p>
                                                </div>
                                                <div className="col-md-2 quotebox">
                                                    <img src={bus} style={{ width: "30%" }} />
                                                    <p>Collection Service</p>
                                                </div>
                                                <div className="col-md-2 quotebox">
                                                    <img src={printer} style={{ width: "30%" }} />
                                                    <p>Printer Required</p>
                                                </div>
                                            </div>



                                            <h3>TERMS AND CONDITIONS</h3>
                                            <p>All domestic shipments will be subjected to a 10% mail service process regardless of weight.</p>
                                            <p>Non Document shipments up to 30 kilograms and all Document shipments are subject to a 10% mail service process.</p>
                                            <p>Customs duties, taxes, clearance-related charges and other surcharges are not included in the tariffs.</p>
                                            <p>Fuel surcharge is included in the rates.</p>
                                            <p>The Rates as set out in this rate card are valid only until December 31 of the year the Rates are quoted.</p>
                                            <p>The Rates are subject to a General Price Increase (GPI) effective January 1st of each year.</p>
                                            <p>Time Express reserves the right to revise the Rates at any time upon shortfall on the projected volumes.</p>
                                            <p>Time Express Terms and Conditions of Carriage will apply for all Time Express services.</p>
                                            <p>Heavyweight rate per kilo may differ from weight break price.</p>
                                            <p>International shipments require invoice and packing list.</p>
                                            <p>Customer agrees to pay shipping charges cash upon collection.</p>
                                            <p>Remote locations, overweight/oversize packages, address correction, elevated risks, restricted destinations are subject to surcharge.</p>
                                            <p>Emergency situation surcharge applies from 1st April 2020.</p>
                                            <p>Cancellation charges 50dhs apply for refunds, credit note free of charge valid 6 months.</p>
                                            <p>Charges based on volumetric or Actual weight: Length x Width x Height (cm3) / 5000 = Volumetric Weight (kg).</p>
                                            {inputs['origin']=='DXB'||inputs['origin']=='AUH'?(<p>Additional AED 180 applicable for Embassy pickup.</p>):null}
                                        <p> The transit time mentioned is excluding weekends & holidays at the destination.</p>
                                        <p>Transit time is subject to custom clearance & inspection.</p>
                                        </div>
                                    </div>



                                </div>



                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
