import React from 'react'
import { Helmet } from 'react-helmet'
import '../../Custom.css'
import banner from '../../images/banners/testimonial.jpg'
export default function Testimonials() {
  return (
    <div className="single-testimonial">
      <Helmet>
        <title>Time Express | Testimonials</title>
      </Helmet>
      <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                 <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                       
                            <h1 className="mainhead">TESTIMONIALS</h1>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
<div className="become_driver ">
<h2>
<span>Passion for Customers</span>
</h2>
<h2>
<span>Dedication to Service Delivery</span>
</h2>
<h2>
<span>Energized People</span>
</h2>
<h2>
<span>Differentiation</span>
</h2>
<h1 className="testimonial-text">Here’s what our customers had to say about us</h1>
</div>
<div className="single-testimonial">
<div className="become_driver ">
<p>“The overall experience with Time Express has been a very pleasant one. Thank you for all of your hard work. &nbsp;The Time Express service is fast, reliable, and friendly. Time Express team is very pro-active in addressing issues and are available whenever we need to contact them.”</p>
<h3>Supply Chain Manager, <span>E-Commerce</span>
</h3>
</div>
</div>
<div className="single-testimonial">
<div className="become_driver ">
<p>“Time Express has filled our void of expedited parts delivery after hours and on the weekends. They provide us prompt and affordable service, saving time and money. The deciding advantage of Time Express Parcels over other courier services was their ability to customize their invoicing to fit our needs, allowing us to track parts and usage.”</p>
<h3>Business Manager, <span>Fashion e-Retail</span>
</h3>
</div>
</div>
<div className="single-testimonial">
<div className="become_driver ">
<p>“Our business in e-commerce is very competitive and requires same day deliveries to customers, Time Express Parcels is attentive to client needs and was the only courier who has beaten our expectations on reliability and on-time delivery”</p>
<h3>UAE Country Manager, <span>Electronics</span>
</h3>
</div>
</div>
<div className="single-testimonial">
<div className="become_driver ">
<p>“We enjoy the reliability of your service at Time Express, it is through the dedication and passion of your staff that we have built the backbone growth of our company. Thank you for being our partner.”</p>
<h3>Retail Division Manager, <span>Banking</span>
</h3>
</div>
</div>
<div className="single-testimonial">
<div className="become_driver ">
<p>“I wanted to take a minute to share my recommendation for Time Express Parcels for any future clients. They handle client orders urgently and efficiently, always have solutions to problems.”</p>
<h3>Logistics Director, <span>Omni-Channel Stores</span>
</h3>
</div>
</div>
<div className="single-testimonial">
<div className="become_driver ">
<p>“Time Express Parcels was one of the best business decisions we have made. Since joining we have grown our business by 30% and developed better relationships with our customers which have brought us new recurring revenue streams.”</p>
<h3>General Manager, <span>Aviation Unit</span>
</h3>
</div>
</div>
<div className="single-testimonial">
<div className="become_driver ">
<p>“I’ve worked closely with the Time Express Parcels team, and they are very flexible and have always met or exceeded my expectations for any requests, specifically short notice requests. Excellent international parcels rates!”</p>
<h3>Exporter, <span>Gift Items</span>
</h3>
</div>
</div>
<div className="single-testimonial">
<div className="become_driver ">
<p>“Very knowledgeable about customs and regulations. It’s honestly refreshing to work with people who practice what they preach”</p>
<h3>Importer and Local Manufacturer, <span>Spare Parts</span>
</h3>
</div>
</div>
<div className="single-testimonial">
<div className="become_driver ">
<p>“Because of Time Express Parcels, we have reduced our logistics express costs and improved our delivery time to our customers. I feel very comfortable using their services.”</p>
<h3>Logistics Manager,<span>Pharmaceutical Clinical Trials</span>
</h3>
</div>
</div>
</main>
           
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
