import React from 'react'
import { Helmet } from 'react-helmet'
import banner from '../../images/banners/packaging.jpg'
export default function Packaging_advice() {
  return (
    <div>
         <Helmet>
        <title>Time Express | Packaging Advice</title>
      </Helmet>
       <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">PACKAGING ADVICE</h1>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content">

<div className="become_driver packaging_shipment ">
<h3 style={{fontSize:"20px"}}>Cushioning your parcel</h3><h3>Sealing boxes carefully</h3>
<h3 style={{fontSize:"20px"}}>Packaging your shipment</h3><div className="border-packaging">
<h1 style={{fontSize:"16px"}}>From drop-off to delivery, we make sure your parcel is well looked after – but we need your help!</h1>
<p>Packaging can make all the difference especially when you are looking to give your brand an exclusive image and personality. On its journey, your parcel will be joined by thousands of others, passing across various machinery and may have other items stacked on top of it. To make sure your package arrives safe and sound, check out our handy hints and tips.</p>
</div>
<div className="border_text">
<h3>Do</h3>
<br/>• Remove old shipping labels
<br/>• Use H-Tape Method
<br/>• The Tape makes a difference
<br/>• Nest a Box within a Box for fragile items
<br/>• Use pre-made boxes and labels
<br/>• Chose a cardboard box to pack your item safely
<br/>• Choose a box that’s just the right size so there’s as little space as possible between the item and the side of the box
<br/>• Ensure items can’t shift inside box
<br/>• Use protective bubble wrap paper and ample cushioning to protect your item
<br/>• Provide a secondary inside label or business card
<br/>• Make sure your item doesn’t touch the side of the box
<br/>• Seal the box securely using reinforced paper tape
<br/>• Shake down the packing material to be sure the box is tightly packed
</div>
<div className="border_text">
<br/>
<h3>Don’t</h3>
<br/>•  Don’t assume, check shipping schedules
<br/>•  Use a box that’s old, worn out, or already damaged Use crumbled newspaper, beware of ink
<br/>•  Attempt to ship prohibited or restricted items
</div>
</div>
</div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
