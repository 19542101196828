import axios from 'axios'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import ReactDOM from "react-dom";
import { OldSocialLogin as SocialLogin } from "react-social-login";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom'
import ReactGoogleLogin from "react-google-login";
import banner from '../../images/banners/collect.jpg'
import goog from '../../images/g.png'
import fb from '../../images/fb.png'
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    IResolveParams
  } from 'reactjs-social-login'
  import {
    FacebookLoginButton,
    GoogleLoginButton
  } from 'react-social-login-buttons'

  
import '../../Track.css';
import { Helmet } from 'react-helmet';

export default function Register_test() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const navigate=useNavigate()
    const [users, setusers] = useState()
   const [register, setregister] = useState({
    username:'',
    email:'',
    first_name:'',
    last_name:'',
    phone_number:'',
    password:''
   })

   const onResponse = (resp) => {
    console.log(resp);
  };
   const [provider, setProvider] = useState('')
  const [profile, setProfile] = useState()
const [submitreg, setsubmitreg] = useState(false)

useEffect(()=>{
    if(provider!=""){
      console.log(provider)
      console.log(profile)
      if(provider=='google'){
        
       setregister({...register,username:profile.given_name,email:profile.email,first_name:profile.given_name,
        last_name:profile.family_name,password:profile.name})
        setsubmitreg(true)
      }
      if(provider=='facebook'){
       
        setregister({...register,username:profile.first_name,email:profile.email,first_name:profile.first_name,
          last_name:profile.last_name,password:profile.name})
          setsubmitreg(true)
      }
     
    }
    
  },[provider])

  const SocialApi=`${SERVER_URL}social_login.php`;
  //const SocialApi='http://localhost:8000/social_login.php';
  useEffect(()=>{
    if(register!="" && provider!=''){console.log(register)
     console.log(register.email)
      if(register.email!="" && register.email!== undefined ){
       console.log(register)
      
        axios({
          method: 'post',
          url: `${SocialApi}`,
          data: register
      })
      .then((res)=>
      {console.log(res.data)
         if(res.data.code==0){
         }else{
          console.log(res.data.userid)
          sessionStorage.setItem('userid',res.data.userid);
          navigate('/my-account/my-parcels')
         }
          
      }
      )
      .catch((err)=>console.log(err))
      }else{
        alert('unable to access your gmail details')
      }
    }
    },[submitreg])

   
const [passerror, setpasserror] = useState('')
const [passsuccess, setpasssuccess] = useState('')
    function checkpass(val){
      console.log(register.password)
        if(val!=register.password){
            console.log('error');
            setpasserror('password mismatch')

        }else{
            setpasserror('')
        }
    }

     

    function submitregister(){
      let emailerror=0;
        const API_PATH=`${SERVER_URL}register.php`;
        if(register.username !="" && register.password !="" && register.email !=""){

          let lastAtPos = register.email.lastIndexOf("@");
      let lastDotPos = register.email.lastIndexOf(".");

      if (!(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          register.email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          register.email.length - lastDotPos > 2
        ) ){
          emailerror=1
          alert(register.email)
        setpasserror('Please enter a valid email id')
         }else{
          setpasserror()
          emailerror=0
         }
          if(emailerror==0){
            axios({
                method: 'post',
                url: `${API_PATH}`,
                data: register
            })
            .then((res)=>
            {
               
               if(res.data.code==0){
               
                setpasserror(res.data.message)
               }else{
                sessionStorage.setItem('userid',res.data.userid);
                setpasssuccess('You successfully registered. Please check your email for the promo code.')
                setTimeout(
                  () => navigate('/my-account/my-parcels'), 
                  3000
                );
                
               }
                
            }
            )
            .catch((err)=>console.log(err))
          }

        }else{
            setpasserror("Enter Username, Password and Email")
        }
   
      
      
    }

    const handleSocialLogin = (user, err) => {
        console.log(user);
        console.log(err);
      };

  return (
    <div>
      <Helmet>
        <title>Time Express | Register</title>
      </Helmet>
    <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
         <div className="container"><div className="row">
             <div className="col-md-12 col-sm-12 your_business text-center">
                 <div className="business_content">
                     <h2>REGISTER</h2>
                     
                 </div>
             </div>
         </div>
         </div>
     </section>
     <div className="pages_content">
     <div className="container">

     <div className="row">
         <div className="col-md-3"></div>
         <div className="col-md-6 loginbox" style={{border:"1px solid",marginTop:"6%",marginBottom:"6%"}}>
         <div id="primary" className="content-area" style={{float: 'left', padding: '3%',width:"100%"}}>
     <main id="main" className="site-main" role="main">
        <div className="sociallogin">

       
     <LoginSocialFacebook
          appId={1236328493792135}
          
          onResolve={({ provider, data }: IResolveParams) => {
            setProvider(provider)
            setProfile(data)
          }}
          onReject={(err) => {
            console.log(err)
          }}
        >
          <FacebookLoginButton />
        </LoginSocialFacebook>

        <GoogleOAuthProvider clientId="955265914216-r9mapea98tpndc5gc3eanub4rs4a98rv.apps.googleusercontent.com" >
        <GoogleLogin 
  onSuccess={tokenResponse => {
    setProvider('google')
    console.log(jwt_decode(tokenResponse.credential))
    setProfile(jwt_decode(tokenResponse.credential))
  }}
  onError={() => {
    console.log('Login Failed')
  }}
  logo_alignment='left'
  text='signin_with'
  shape='circle'
 
/>

   
          </GoogleOAuthProvider>
         
        {/* <ReactGoogleLogin
      clientId="955265914216-8p8ht8g7djn2tvd8kt5i7l3kvkvps8gr.apps.googleusercontent.com" // react2 We created this earlier, remember?
      buttonText="Login with Google"
      onSuccess={onResponse}
      onFailure={onResponse}
      pluginName= 'hello'
    /> */}

       {/* <LoginSocialGoogle
        //  client_id="676916453834-9jap1gpbnlkto04f0s7fjjm0if7co8rt.apps.googleusercontent.com"
         client_id="955265914216-r9mapea98tpndc5gc3eanub4rs4a98rv.apps.googleusercontent.com"
         
          onResolve={({ provider, data }: IResolveParams) => {
            setProvider(provider)
            setProfile(data)
          }}
          onReject={(err) => {
            console.log(err)
          }}
        >
          <GoogleLoginButton />
        </LoginSocialGoogle>  */}
        </div>

     {/* <SocialLogin
      provider="google"
      appId="955265914216-r9mapea98tpndc5gc3eanub4rs4a98rv.apps.googleusercontent.com"
      callback={handleSocialLogin}
    >
      <button style={{background:"#4f71e8",border:"0px solid #4f71e8",marginBottom:"1%"}}> <img className="fa lazyloaded" style={{paddingTop: '0px !important', marginTop: 0,background:"#fff"}} src={goog} /> Login with Google</button>
    </SocialLogin> */}
 {/*
    <SocialLogin
      provider="facebook"
      appId="1236328493792135"
      callback={handleSocialLogin}
    >
      <button style={{background:"#1877f2",border:"0px solid #1877f2"}}><img className="fa lazyloaded" style={{paddingTop: '0px !important', marginTop: 0}} src={fb}  /> Login with facebook</button>
    </SocialLogin> */}
    <br/>

     <form>
                 
            <input type="text" placeholder='Username' className="form-control mb-3" required onChange={(e)=>setregister({...register,username:e.target.value})}/>
           
            <input type="email" placeholder='Email' className="form-control  mb-3"  required onChange={(e)=>setregister({...register,email:e.target.value})}/>
            <input type="text" placeholder='First Name' className="form-control  mb-3"  required onChange={(e)=>setregister({...register,first_name:e.target.value})}/>
            <input type="text" placeholder='Last Name' className="form-control  mb-3"  required onChange={(e)=>setregister({...register,last_name:e.target.value})}/>
            <input type="text" placeholder='Phone Number' className="form-control  mb-3"  required onChange={(e)=>setregister({...register,phone_number:e.target.value})}/>
            <input type="text" placeholder='Password' className="form-control  mb-3"  required onChange={(e)=>setregister({...register,password:e.target.value})}/>
            <input type="text" placeholder='Confirm Password' className="form-control  mb-3"  required onChange={(e)=>checkpass(e.target.value)}/>
           
           <p style={{color:'red'}}>{passerror}</p>
           <p style={{color:'purple'}}>{passsuccess}</p>
            <button type="button" className="btn  btn-sm" onClick={submitregister} style={{backgroundColor:'#7b3995',color:"#fff",padding:"2%",width:"19%"}}>Register</button>
        </form>
</main>
</div>
         </div>
   
</div>
</div>
</div>
</div>
  )
}
