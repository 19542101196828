import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'
import Modal from 'react-modal';
import walletimg from '../../../images/wallet-1.jpg'
import { Helmet } from 'react-helmet';
export default function My_wallet() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const navigate = useNavigate()
const [amount, setamount] = useState()
const [wallet, setwallet] = useState()
const [current, setcurrent] = useState(10)
const [page, setpage] = useState(0)
const userid=sessionStorage.getItem('userid')
function payamount(){

    const API_PATH=`${SERVER_URL}generate_wallet_payment.php`;
    axios({
        method: 'post',
        url: `${API_PATH}`,
        data: {userid:userid,amount:amount}
    })
    .then((res)=>{
        console.log(res.data)
       navigate('/checkout', { state: { orderno: 1, payment_page: res.data } })

    })
    .catch(err=>console.log(err))
}
const [showbooking, setshowbooking] = useState(true)
const [showtrans, setshowtrans] = useState(false)
const [transactions, settransactions] = useState()
const [counttransactions, setcounttransactions] = useState()
const [booking, setbooking] = useState()
const [countbooking, setcountbooking] = useState()
const [parcelcount2, setparcelcount2] = useState(0)
const API_PATH2=`${SERVER_URL}get_wallet.php`;
//const API_PATH2='http://localhost:8000/get_wallet.php';
useEffect(()=>{
    axios({
      method:"post",
      url:`${API_PATH2}`,
      data:{userid:userid,current:""}
    })
    .then((res)=>{
      console.log(res.data)
      setwallet(res.data.wallet)
      settransactions(res.data.transactions)
      setcounttransactions(res.data.transactions.length)
      setbooking(res.data.bookings)
      setcountbooking(res.data.bookings.length)
      setparcelcount2(res.data.totparcel.length)
    })
    .catch((err)=>console.log(err))
    },[])

    function bonus_payment(amt){
        const API_PATH=`${SERVER_URL}generate_wallet_payment.php`;
    axios({
        method: 'post',
        url: `${API_PATH}`,
        data: {userid:userid,amount:amt}
    })
    .then((res)=>{
        console.log(res.data)
       navigate('/checkout', { state: { orderno: 1, payment_page: res.data } })

    })
    .catch(err=>console.log(err))

    }

    function showfun(val){
        if(val=='booking'){
            setshowbooking(true)
            setshowtrans(false)
        }else{
            setshowbooking(false)
            setshowtrans(true)
        }

    }
    const [modalIsOpen,setModalIsOpen] = useState(false);
    const setModalIsOpenToTrue =()=>{
        setModalIsOpen(true)
    }

    const setModalIsOpenToFalse =()=>{
        setModalIsOpen(false)
    }

    function nextpage(val1){

        axios({
    
          method:"post",
          url:`${API_PATH2}`,
          data:{userid:userid,current:val1}
        })
        .then((res)=>{
          
          if(res.data.parcel!=""){
            if(val1>page){
              setcurrent(current+10)
            }
            if(val1<page){
              setcurrent(current-10)
            }
        setpage(val1)
        setbooking(res.data.bookings)
      setcountbooking(res.data.bookings.length)
           
             }
         
        })
        .catch((err)=>console.log(err))
     
      
    }

    return (


        <div className="wallet-plan">
            <Helmet>
        <title>Time Express | My Wallet</title>
      </Helmet>
            <Modal isOpen={modalIsOpen} ariaHideApp={false}><div >
           
    <button onClick={setModalIsOpenToFalse} style={{float:"right"}}>x</button>
    <h5 classname="modal-title title-mod" id="exampleModalLongTitle" style={{color: '#d85b12',textTransform:"uppercase",fontWeight:"bold",marginBottom:"5%"}}>To add money to you wallet</h5>
           
    </div>
    <>
    
           <div className="container">
           <div className="modal-body title-mod-body">
<div className="col-md-12"><img src={walletimg} height="auto" style={{width:"100%"}}/>
</div>
<div className="col-md-12">
<h4 style={{marginBottom: 0}}>Ship cheaper and earn more with Pay By Wallet</h4>
<p>1. Select exact amount you want to add to your wallet.</p>
<p>2. Choose one of Bulk Buy Bonus schemes and save more!</p>
<p>Note: Use your Pay By Wallet credits to send your parcels at checkout page.</p>
{/* <p>Note: After payment success your wallet money will add automatically to "My Balance".</p> */}
</div>
</div>
           </div>
         
            </>
</Modal>
            <div className="container myparctab" >

                <div className="container-fluid my_quote">
                    <div className="row">

                        <Sidebar />
                        <div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">

                            <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb" }}>
                                <div className="head" style={{ background: "#f5f5f5", padding: "1%" }}>
                                    <h3 className="comaprison_hedding">My Wallet </h3>
                                    <a onClick={setModalIsOpenToTrue} style={{color:"#d85b12",cursor:"pointer"}}>How to use</a>
                                </div>
                                <div className="row" style={{ textAlign: "center", padding: "15px", margin: "15px" }}>
                                    <div className="col-md-4" >
                                        <h5>MY WALLET</h5>
                                        <div className="row" style={{ textAlign: "center", border: "1px solid #d85b12" }}>
                                            <div className="col-md-12" style={{ background: "#d85b12", color: "#fff" }}>
                                                <h6 style={{ padding: "1%" }}>My Balance</h6>
                                            </div>
                                            <div className="col-md-12" style={{ padding: "8%" }}>
                                                {wallet} AED
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-7 offset-1 walletmoney">
                                        <h5>ADD MONEY TO YOUR WALLET</h5>
                                        <div className="row" style={{ textAlign: "center", border: "1px solid #d85b12", background: "#f5f5f5" }}>
                                            <div className="col-md-12" >
                                                <div className="row">
                                                    <div className="col-md-4" style={{ background: "#d85b12", color: "#fff" }}> <h6 style={{ padding: "1%" }}>Value:</h6></div>
                                                    <div className="col-md-8 " style={{ background: "#f5f5f5", color: "#fff", padding: "0" }}> <input type="number" onChange={(e)=>setamount(e.target.value)} style={{ background: "#f5f5f5", border: "none", border: "none", borderBottom: "1px solid", padding: "1%", width: "100%" }} /></div>
                                                </div>

                                            </div>
                                            <div className="col-md-12" style={{ padding: "8%" }}>
                                                <button type="submit" onClick={payamount} style={{ color: '#fff', textAlign: '', backgroundColor: '#d85b12bf', width: '35%', padding: "2%", border: "1px solid" }}>Pay Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb",marginTop:"2%", borderRadius: "6px" }}>

                                <div className="table-responsive " style={{ padding: '25px' }}>
                                    <h4 className="panel-title" style={{fontSize:'20px',marginBottom:"1px"}}>Bulk Buy Bonus</h4>
                                    <p style={{ fontSize: "14px" }}>Earn FREE BONUS CREDIT when you buy our below packages to top up your wallet.</p>

                                    <table className="table-bordered table-striped table-hover table wallet-plan" style={{marginTop:"2%"}}>
                                        <thead>
                                            <tr>
                                                <th>Add to Wallet</th>
                                                <th>Bonus Credit</th>
                                                <th>Total in Wallet</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>500 AED <button onClick={()=>bonus_payment(500)}>Pay Now</button></td>
                                                <td>+25 AED</td>
                                                <td>525 AED</td>
                                            </tr>
                                            <tr>
                                                <td>700 AED <button onClick={()=>bonus_payment(700)}>Pay Now</button></td>
                                                <td>+50 AED</td>
                                                <td>750 AED</td>
                                            </tr>
                                            <tr>
                                                <td>1000 AED <button className="adjustspace" onClick={()=>bonus_payment(1000)}>Pay Now</button></td>
                                                <td>+100 AED</td>
                                                <td>1100 AED</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* transaction history */}

                            <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb",marginTop:"2%", borderRadius: "6px" }}>

<div className="table-responsive " style={{ padding: '25px' }}>
    <h3 className="panel-title comaprison_hedding">My Transactions</h3>
    <div className="wallettrans">
    <a className="booking" onClick={()=>showfun('booking')}>BOOKINGS</a>
    <a className="onlinewallet" onClick={()=>showfun('online')}>ONLINE PAYMENT</a>
    </div>
    {showbooking?(
        <>
        <table className="table-bordered table-striped table-hover table wallet-plan">
        <thead>
            <tr>
                <th>DATE</th>
                <th>ORDER ID</th>
                <th>AMOUNT</th>
                <th>STATUS</th>
            </tr>
        </thead>
        <tbody>
        {
              [...Array(countbooking)].map((e, i) =><>
{booking && booking[i]!=''?(
            <tr key={i}>
                <td>{booking[i].updated_on}</td>
                <td>{booking[i].id}</td>
                <td>{booking[i].total_price} PAID</td>
                <td>BOOKING</td>
            </tr>
):null}
</>
)}
          
        </tbody>
    </table>
    <div className="pagination-sec fw bookingpage"> 
        <nav aria-label="Page navigation">
          <ul className="pagination">
          {current>10?(<>
      
            <li>
              <a onClick={()=>nextpage(page-1)} aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
         
              <li>
              <a className="page_list" onClick={()=>nextpage(page-1)}>{page}</a>
            </li>
            </>
            )
          :null}
            
            {/*?php
                            }?*/}
            <li>
              <a className="page_list active" onClick={()=>nextpage(page)} >{page+1}</a>
            </li>
           {current<parcelcount2?(
<>
<li>
              <a className="page_list" onClick={()=>nextpage(page+1)}>{page+2}</a>
            </li>
            <li>
              <a onClick={()=>nextpage(page+1)}  aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
</>
           ):null}
           
            {/*?php
                            }?*/}
          </ul>
        </nav>
      </div>

    </>
    
    ):(
        <table className="table-bordered table-striped table-hover table wallet-plan">
        <thead>
            <tr>
                <th>DATE</th>
                <th>ORDER ID</th>
                <th>AMOUNT</th>
                <th>STATUS</th>
            </tr>
        </thead>
        <tbody>
        {
              [...Array(counttransactions)].map((e, i) =><>
{transactions && transactions[i]!=''?(
            <tr key={i}>
                <td>{transactions[i].date_on}</td>
                <td>{transactions[i].id}</td>
                <td>{transactions[i].amount} PAID</td>
                <td>SUCCESS</td>
            </tr>
):null}
</>
)}
          
        </tbody>
    </table>
    )}
    
   

  
</div>
</div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
