import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from "axios";
import {  Link } from 'react-router-dom'
import banner from '../../../images/banners/login.jpg'
import loading from '../../../images/spin.gif'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export default function Wallet_orders() {

  const accountno = sessionStorage.getItem('accountno')
  const API_PATH = `${SERVER_URL}walletorders.php`; 
  //const API_PATH = "http://localhost:8000/walletusers.php"; 
  const [orders, setorders] = useState('')
  const [ordercount, setordercount] = useState(0)
  const [data, setdata2] = useState([])
  const [isload, setisload] = useState(true)
  useEffect(() => {

      axios({
        method: 'post',
        url: `${API_PATH}`
      })
        .then((res) => {
          setisload(false)
          setordercount(res.data.length)
          setdata2(res.data)
           setorders(JSON.parse(JSON.stringify(res.data)))
         
        }
        )
        .catch((err) => console.log(err))
  
    }, [])
  
  const columns = [
     
      {
        name: "Order Id",
        selector: "id",
        sortable: true
       
      },
      {
          name: "user_id",
          selector: "user_id",
          sortable: true,
          cell: (d) => (
            <a onClick={()=>getWallet(d.user_id)} target="_blank" className="dlink">
              {d.user_id}
            </a>
          )
        }
        ,
        {
          name: "AWB",
          selector: "awbno",
          sortable: true
        }
        ,
      {
          name: "Name",
          selector: "name",
          sortable: true
        }
        ,
      {
          name: "Email",
          selector: "email",
          sortable: true
        }
        ,
      {
        name: "From",
        selector: "delivery_from",
        sortable: true
      }
        ,
      {
        name: "To",
        selector: "delivery_to",
        sortable: true
      }
        ,
      {
        name: "Total Price",
        selector: "total_price",
        sortable: true
      }
        ,
      {
        name: "Updated Date",
        selector: "updated_on",
        sortable: true
      }
      
    ];
    const tableData = {
      columns,
      data
    };


   function getWallet(val){
    window.open('/wallet/'+val, '_blank', 'noopener,noreferrer');
  
  // navigate('/track/'+val)
  }
 
  return (
    <div>
       <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
              <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                            <h2>Wallet Orders</h2>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
           {isload?(
  <div style={{textAlign:"center"}}>
  <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>
  <img src={loading} alt="" style={{width:"4%"}}  />
  </div>
):(<DataTableExtensions {...tableData}>
  <DataTable
    columns={columns}
    data={data}
    noHeader
    defaultSortField="id"
    // sortIcon={<SortIcon />}
    defaultSortAsc={true}
    pagination
    highlightOnHover
    dense
  />
</DataTableExtensions>
)}
             </div>


            </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
