import React from 'react'
import { Helmet } from 'react-helmet'
import '../../Custom.css'
import banner from '../../images/banners/about.jpg'
export default function Sending_multiple() {
  return (
    <div className="improvelive">
      <Helmet>
        <title>Time Express | Sending Multiple Parcels Discount Tool</title>
      </Helmet>
       <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
              <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">SENDING MULTIPLE PARCELS DISCOUNT TOOL</h1>
                            <p>We're here to help</p>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="single_content">
<article id="post-288" className="post-288 send type-send status-publish has-post-thumbnail hentry send_categories-about-sending">
<div className="become_driver post_driver">
<div className="entry-content">
<h2>Discounted International Courier service in UAE</h2>
<div className="border_text">
<h3>Sure! How many would you like to send?</h3>
<p>Our customers love the flexibility we can offer them when it comes to arranging parcel collections and deliveries. We help individuals and businesses alike find a courier to handle their shipping, whether they want it to be shipped just in the UAE or around the world. We can book you in a collection for numerous parcels or provide you with shipping labels ready to print out for a drop off service.&nbsp;</p>
<p>The more you ship, the more you save. Our discount tool offers you additional discounts. Check out our offered discounts (link to Ship More, Save More).</p>
</div>
<hr />
<div className="border_text">
<h3>Are you looking for sending Multiple Parcels to Same Address?</h3>
<p>Multiple parcels going to the same address- the simplest option is for people who like to make ad hoc orders – which are the vast majority of people. Here all you need to do is use our upload and add the dimensions of each parcel and hit the add another parcel tab for each one going to the same address and then continue as you would normally do through the quote process. The prices shown will be based on the total amount of your basket covering however many parcels you have added in.</p>
</div>
<hr />
<div className="border_text">
<h3>Or you have Multiple parcels going to different addresses?</h3>
<p>If you are sending some packages to various different addresses, it’s not as much of a problem as you might expect. Add the parcel as normal with the first address and proceed to the payment screen, then if you want to add another parcel for another address, hit add another parcel and go through the process for your next parcel. Just repeat until you have all the parcels in your basket and complete the checkout.</p>
</div>
<hr />
<div className="border_text">
<h3>Sent to these addresses before?</h3>
<p>We save the addresses you’ve sent from or to in the past. So all you need to do is select the ones you’d like for each order. We feel that this is particularly useful for our customers that sell on eBay as it gives you great flexibility to arrange courier services with different speeds of delivery.</p>
</div>
<hr />
<div className="border_text">
<h3>I am a business/frequent sender, do you have Business Rates?</h3>
<p>Funny you should ask, we certainly do. If you send 5 or more parcels to UAE addresses all collected from the same collection address, our business rates might just be the thing for you. Likewise, if you have enough volume click on that link and see if our fulfillment opportunity is something of interest.</p>
</div>
<hr />
<div className="border_text">
<h3>Discounted international courier rates</h3>
<p> Want the lowest prices courier services in UAE? Save 40% on all your international parcels to any destination around the world at discounted international courier rates. Simply log on, select ‘Send’, and ship away!</p>
<p>To talk with a representative for a quote please contact us now!</p>
</div>
</div>
</div>
<footer className="entry-footer " />
</article>
<div className />
</div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
