import React, { useState } from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import '../../Partner.css';
import banner from '../../images/banners/customs.jpg'
import loading from '../../images/spin.gif'
export default function Customs_clearance() {

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [formdata, setformdata] = useState({
       name:'',
       email:'',
       phone:'',
       message:''
    })
   
    const [resultmsg, setresultmsg] = useState('')
    const [errorsts, seterrorsts] = useState(0)
    const [loadingshow, setloadingshow] = useState(false)
    const API_PATH=`${SERVER_URL}customs_enquiry.php`;
    //const API_PATH='https://timexpress.ae/modal/customs_enquiry.php';
    function handleFormSubmit(event){
        event.preventDefault();
        console.log(formdata);
        if(formdata.name!="" && formdata.email!="" ){
      
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formdata.email))
        {
            
          setloadingshow(true)
        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: formdata
          })
          .then((res)=>{
            setloadingshow(false)
              //setresultmsg(res.data.sent)
              console.log(res.data.message)
              setresultmsg(res.data.message);
              //console.log(resultmsg)
          })
            // .then(res => {
            //   this.setState({
            //     mailSent: res.data.sent
            //   })
            // })
            .catch(error => console.log(error.message));
        }else{
            setresultmsg('Please enter a valid email address');
        }
      }else{
        setresultmsg('Please enter all required fields');
      }
    }

  
    return (
        
        <div className="partner">
            <Helmet>
        <title>Time Express | Customs Clearance Services</title>
      </Helmet>
            <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
           <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                            <h1 className="mainhead">CUSTOMS CLEARANCE SERVICES</h1>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">

           <h4>CUSTOMS SERVICES </h4>
           <p>Time Express customs brokerage has extensive knowledge and experience of dealing with customs clearance. We provide a wide range of customs services for standard express and cargo clearance whether your cargo shipment is coming by sea freight, air freight, or express courier. By making sure that clearance is a smooth, fast, and efficient process, we ensure your shipments arrive as quickly as possible to your destination, and leave you free to focus on your core business activities. With our online tool we provide fast and efficient processing of your customs clearance agent declaration.</p>
<h4 className='custom_sub'>DOCUMENTS REQUIRED </h4>
<p>As your designated cargo clearance company we will need from you the following original documents: Invoice, Bill of Lading or Airway Bill, Packing List, and Certificate of Origin.  We will then act on your behalf with the concerned shipping line or airline to collect the delivery order and prepare the import documentation and submit your request to customs.  Once customs approves we will proceed to pay duties and taxes to clear the port dues.  We will then make arrangements for transport and pick up the cargo from the port and deliver to your door step.   </p>
<h4 className='custom_sub'>DISBURSEMENT CUSTOMS DUTIES & TAXES </h4>
<p>At your request,  we will use credit or cash to pay the customs clearance, duty and tax charges relating to your shipment, parcel, or cargo.  Alternatively, you can use your own bond or credit line with customs. Normally all documents should be originals, however, we can still process the declaration for you with certified copies.  Export procedures are generally easier to process than imports.  </p>
<h4 className='custom_sub'>ADVANCE CUSTOMS PAYMENT </h4>
  <p>By paying customs and duties on your behalf we offer you the utmost convenience to process your shipment without any hassle. Time Express Customs assumes the risk of non-repayment by the receiver of the cargo, for which a charge is applied based on the credit advanced “advanced fee”, subject to a standard minimum.</p>
    <h4 className='custom_sub'>BONDED STORAGE</h4>   
  <p>As your cargo clearance agent Time Express Customs can store your products when the shipment cannot be released by customs due to inaccurate or missing paperwork. These charges take effect three days after the shipment arrival date or the broker notification, whichever is later.</p>
   <h4 className='custom_sub'>BONDED TRANSIT </h4>
    <p>A bonded shipment occurs when Time Express customs is requested to move your cargo from foreign-origin to a location other than the regular Time Express customs gateway at destination within the free zones areas. As a bonded transit your shipment is moved from one free zone to another free zone under a bonded truck which is sealed by customs. We coordinate with local customs authorities for inspection, customs clearance, and document stamping and transfer under bond using our own guarantee.</p>
    <h4 className='custom_sub'>EXPORT DECLARATION</h4> 
   <p>Most countries require an export declaration for shipments containing controlled commodities or exceeding a certain value threshold or weight. If you shipment is lower than the diminmus value then it will be free of duties upon arrival.  Our team of customs specialists can support you with the export declaration process step by step.</p>
  
       
          </div>
            <h1 style={{display: 'none'}}>Become a driver</h1>
            <div id="contact_form_show" className="become_driver">
            <h4>Get Started (Submit Form)</h4><p />

            <form action="#">
      
        <div className="form-row row">
          <div className="form-group col-md-6">
            <label htmlFor="inputEmail4">Full Name<span style={{color:"red"}} className="mandatory">*</span></label>
            <input type="text" className="form-control"  onChange={(e)=>setformdata({...formdata,name:e.target.value})} placeholder="Full Name*" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputPassword4">Email<span style={{color:"red"}}>*</span></label>
            <input type="email" className="form-control" onChange={(e)=>setformdata({...formdata,email:e.target.value})} id="license_no" placeholder="Email*" />
          </div>
         
        </div>

        <div className="form-row row">
        <div className="form-group col-md-6">
            <label htmlFor="inputPassword4">Contact Number</label>
            <input type="text" className="form-control" onChange={(e)=>setformdata({...formdata,phone:e.target.value})} id="license_no" placeholder="Contact Number" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputEmail4">Message</label>
            <textarea  className="form-control"  onChange={(e)=>setformdata({...formdata,message:e.target.value})} placeholder="Message" />
          </div>
          
        </div>

        
        
        
        <button type="submit" style={{marginTop:"2%",background:"purple",color:"#fff",border:"none"}} className="btn btn-primary" onClick={e => handleFormSubmit(e)}>SEND</button>
      </form>
      {resultmsg.length > 0 &&
         <p style={{marginTop:"1%"}}>{resultmsg}</p>
      }
      {loadingshow?(
      <p style={{textAlign:"center",marginTop:"2%"}}><img src={loading} style={{width:"5%"}}/></p> 
    ):null}

            </div>

            
            
            </div>
           
            </main>
            </div>
            </div>
            </div>
            </div>
        </div>
    )
}
