import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'
import { country_code_array } from '../functions';
import { Helmet } from 'react-helmet';
export default function Add_address() {

    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const navigate = useNavigate()
    const userid = sessionStorage.getItem('userid')
    const [countries, setcountries] = useState(country_code_array);
    const [collectionDetails, setcollectionDetails] = useState({
        country:'AE',
        name:'xx'
    })
    const [collectionaddress, setcollectionaddress] = useState()
    const [editcollectionaddress, seteditcollectionaddress] = useState()
    const [collectioncount, setcollectioncount] = useState()
  const [editform, seteditform] = useState(false)
  const [addform, setaddform] = useState(false)
  const [addressdisplay, setaddressdisplay] = useState(true)
  const [editformdelivery, seteditformdelivery] = useState(false)
  const [addformdelivery, setaddformdelivery] = useState(false)
  const [addressdisplaydelivery, setaddressdisplaydelivery] = useState(true)

  function showaddressform(val){
    if(val=='collection'){
        setaddform(true)
        seteditform(false)
        setaddressdisplay(false)
        setaddressdisplaydelivery(false)
    }else{
        setaddformdelivery(true)
        seteditformdelivery(false)
        setaddressdisplaydelivery(false)
        setaddressdisplay(false)
    }
   
  }

    function savecollection(){
        const API_PATH=`${SERVER_URL}save_address.php`;
        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: {user:userid,collectionDetails:collectionDetails,type:'delivery'}
        })
        .then((res)=>{
            console.log(res.data)
            navigate('/my-account/address-book')
    
        })
        .catch(err=>console.log(err))
    }

    const addresspath=`${SERVER_URL}get_address.php`;
    useEffect(()=>{
        axios({
          method:"post",
          url:`${addresspath}`,
          data:userid
        })
        .then((res)=>{
          
            setcollectionaddress(res.data.collection)
            setcollectioncount(res.data.collection.length)
         
        })
        .catch((err)=>console.log(err))
        },[])

        function editAddress(val,type){
            setcollectionDetails(val)
            if(type=='collection'){
                
                seteditform(true)
                setaddform(false)
                setaddressdisplay(false)
                seteditformdelivery(false)
                setaddressdisplaydelivery(false)

            }else{
                setaddformdelivery(false)
                seteditformdelivery(true)
                setaddressdisplaydelivery(false)
                seteditform(false)
            }
        
        }

      

        function updateaddress(){
          
            const API_PATH=`${SERVER_URL}save_address.php?type=update`;
            axios({
                method: 'post',
                url: `${API_PATH}`,
                data: {user:userid,collectionDetails:collectionDetails,type:'delivery'}
            })
            .then((res)=>{
                console.log(res.data)
                navigate('/my-account/address-book')
               
        
            })
            .catch(err=>console.log(err))
        }

        function deleteAddress(val){
           
            window.confirm('Are you sure you wish to delete this item?') ? deletethisaddress(val) : deletethisaddress(0)

        }
       
        
        function deletethisaddress(val){
            if(val>0){
               
           const API_PATH=`${SERVER_URL}save_address.php?type=delete`;
            axios({
                method: 'post',
                url: `${API_PATH}`,
                data: {user:userid,collectionDetails:val,type:'delivery'}
            })
            .then((res)=>{
                console.log(res.data)
                navigate('/my-account/address-book')
        
            })
            .catch(err=>console.log(err))
           
        }
        }
    return (


        <div className="wallet-plan addressbook">
            <Helmet>
        <title>Time Express | Address Book</title>
      </Helmet>
            <div className="container myparctab" >

                <div className="container-fluid my_quote">
                    <div className="row">

                        <Sidebar />
                        <div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">

                            {/* Add Collection Address */}

                         

                            <div className="col-md-12 col-sm-12 col-xs-12" style={{border: "1px solid #c3bbbb", marginTop: "2%", borderRadius: "6px" }}>
                                <h3 className="panel-title comaprison_hedding" style={{ padding: "2%", background: "#f5f5f5" }}>Delivery Address</h3>


                                <form>
                                    <div className="row" style={{ marginLeft: "15px", marginRight: "15px" }}>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" name="collection_name" onChange={(e) => setcollectionDetails({ ...collectionDetails, name: e.target.value })} id="collection_name" maxLength={50} placeholder="Name" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Email<span className="required">*</span></label>
                                                <input type="text" className="form-control" name="collection_email" onChange={(e) => setcollectionDetails({ ...collectionDetails, email: e.target.value })} id="collection_email" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Phone<span className="required">*</span></label>
                                                <div className="input-group new-group">
                                                    <div className="input-group-addon" style={{ paddingTop: "6%" }}></div>
                                                    <input type="text" className="form-control" name="collection_phone" id="collection_phone" onChange={(e) => setcollectionDetails({ ...collectionDetails, phone: e.target.value })} placeholder="Phone" style={{ margin: 0 }} />
                                                    <input type="hidden" name="collection_phone_code" id="collection_phone_code" defaultValue="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" style={{ marginLeft: "15px", marginRight: "15px" }}>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Address</label>
                                                <input type="text" className="form-control" name="collection_address" onChange={(e) => setcollectionDetails({ ...collectionDetails, address: e.target.value })} id="collection_address" placeholder="Address" />
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">City/Town</label>
                                                        <input type="text" className="form-control" name="collection_city" onChange={(e) => setcollectionDetails({ ...collectionDetails, city: e.target.value })} id="collection_city" placeholder="City/Town" />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Country<span className="required">*</span></label>

                                                        <select className="form-select select-export-package" name="delivery_to" onChange={(e) => setcollectionDetails({ ...collectionDetails, country: e.target.value })}>
                                                            {

                                                                Object.entries(countries).map(([k, v]) => (
                                                                    <option key={k} value={k} >{v}</option>
                                                                ))
                                                            }

                                                        </select>

                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Zipcode</label>
                                                        <input type="text" className="form-control cd" name="collection_zipcode" onChange={(e) => setcollectionDetails({ ...collectionDetails, zipcode: e.target.value })} id="collection_zipcode" placeholder="Zipcode" />
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-12 text-right" > 
                                    <input type="button" defaultValue="Submit" name="save" onClick={savecollection} className="btn btn-success pull-right" id="save" style={{float:"right",margin:"3%"}} />
                                    </div>
                                    </div>
                                  
                                </form>
                                
                            </div>
                         



  {/* Edit Collection Address */}
                          

                        </div>



</div>










                    </div>


                </div>
            </div>

        

            


    )
}
