import React from 'react'
import { Helmet } from 'react-helmet'
import banner from '../../../images/banners/individual.jpg'
export default function Individuals() {
  return (
    <div>
      <Helmet>
        <title>Time Express | Individuals</title>
      </Helmet>
           <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
           <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">Individuals</h1>
                            <p>We're here to help</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
           <h4>SHOPPING MADE EASY</h4>
           <h4>GET THE BEST DEALS</h4>
           <h4>TAKE THE HASSTLE OUT OF SHIPPING</h4>
           <p>We make parcels shipping domestic and international cheap and easy by helping you directly connect with online merchants and retailers. Sign up and see how Time Express can help you get the best experience to the latest fashion and hi technology with 100s of new styles and models every day. Discover the latest in online shopping, shop from great selection from your favorite online store. Consumers want Convenience, Speed, and Empowered Choice. We Make It Happen.</p>
            
    </div>
    </div>
    </main>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
