import React from 'react'
import { Helmet } from 'react-helmet'
import '../../Custom.css'
import banner from '../../images/banners/collect.jpg'
export default function FindOutParcel() {
  return (
    <div className="improvelive">
        <Helmet>
        <title>Time Express | Find Out Your Parcel</title>
      </Helmet>
      <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
              
                            <h1 className="mainhead">FIND OUT WHERE YOUR PARCEL IS</h1>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content"><div className="become_driver im_lives">
            <div className="entry-content">
<p>Simply enter your parcel reference number above and hit track to find out where your parcel is.</p>
</div>
  </div>
  <h1 style={{display: 'none'}}>medical services</h1>
  
  </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
