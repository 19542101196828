import React from 'react'
import { Helmet } from 'react-helmet'
import '../../Custom.css'
import banner from '../../images/banners/improve.jpg'
export default function Improving_lives() {
  return (
    <div className="improvelive">
       <Helmet>
        <title>Time Express | Improving Lives</title>
      </Helmet>
        <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                  <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                          
                            <h1 className="mainhead">IMPROVING LIVES</h1>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content"><div className="become_driver im_lives"><div className="border_text"><h3>ONE FOR ONE</h3><p>Through your bookings, TIME EXPRESS helps provide people in need, disability, food, medical support, and schooling for people who cannot afford it.</p></div><div className="border_text"><h3>HOW IT HAPPENS</h3><p>Every month Time Express collects the donations made and connects with charities to help underprivileged people. Meet some of the members who help improve lives and fulfill the One for One promise around the world.</p></div><div className="border_text"><h3>BOOK</h3><p>Use the services of TIME EXPRESS, and help improve people in need. For every dirham you spend on international courier, we match the same from our side to improve the world we live in, improve the lives of people who need education, medication, and help a person in need.</p></div><div className="border_text"><h3>SCHEME</h3><p>The TIME EXPRESS donation team collaborates with various charities to plan how we can further expand our charity network, by using TIME EXPRESS services for helping provide further support to underprivileged populations.</p></div><div className="border_text"><h3>SUPPORT</h3><p>Join TIME EXPRESS to improve the standard of living of people around the world, through tailored express services, logistical support, local distribution and depots and more.</p></div><div className="border_text"><h3>GIVE</h3><p>Our program provides TIME EXPRESS services to different charities, supporting sustainable and responsible programs for communities in need.</p></div></div><h1 style={{display: 'none'}}>Improving lives</h1><p /></div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
