import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import DatePicker from "react-datepicker";
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { country_code_array2 } from './functions';
import { domestic_destination_array } from './functions';
import "react-datepicker/dist/react-datepicker.css";
import '../../Home1.css'
import banner from '../../images/banners/about.jpg'
import axios from 'axios';
import Parcels_delivered from './home_components/Parcels_delivered';
export default function Send() {

  

    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const navigate = useNavigate()
    const [countries, setcountries] = useState(country_code_array2);
    const [domesticloc, setdomesticloc] = useState(domestic_destination_array);

    const url = window.location.href
    const destcountry = url.split("/").pop();
    let xxx = 'GB'
    if (destcountry != 'send' && destcountry != "") {
        xxx = destcountry

    }

    const [default_dest, setdefault_dest] = useState(xxx)


    const ip_code = '5.195.227.207'; // find ip
    sessionStorage.setItem('ip_code', ip_code)

    const [showdiv, setshowdiv] = useState('exportdiv')
    const [typediv, settypediv] = useState('package')
    const [exportclass, setexportclass] = useState('active')
    const [importclass, setimportclass] = useState('')
    const [packclass, setpackclass] = useState('active')
    const [docclass, setdocclass] = useState('')
    const [qoutefor, setqoutefor] = useState('international')

    function changefun(get_id) {
        if (get_id == 'in-package-document-tab') {

            setqoutefor('international');
        } else if (get_id == 'in-freight-tab') {

        } else if (get_id == 'road-gcc-tab') {

        } else {

            setqoutefor('domestic');
        }
    }

    
   

    const date=new Date();
    const today= date.getDay()
 
     const [daycount, setdaycount] = useState(0)
     const [startDate, setStartDate] =useState(today==6?new Date(date.setDate(date.getDate()+2)):new Date(date.setDate(date.getDate()+1)))
     

    const user = sessionStorage.getItem('userid');
    const [getQuotedata, setgetQuotedata] = useState(
        {
            user: user,
            origin: 'AE',
            destination: default_dest,
            shipmenttype: "exportdiv",
            typediv: "package",
            shipping_date: startDate,
            dimensions: {
                weight: ['0.5'],
                length: [],
                width: [],
                height: []

            }


        }
    )

    const [getQuotedata_dom, setgetQuotedata_dom] = useState(
        {
            origin: 'DXB',
            destination: 'AUH',
            shipmenttype: "domestic",
            typediv: "package",
            shipping_date: startDate,
            dimensions: {
                weight: ['0.5'],
                length: [],
                width: [],
                height: []

            }


        }
    )



    function updateweight(val, i) {
        const updatedCount = 1;
        const clonedListing = { ...getQuotedata.dimensions };
        const updatedAccepted = [...clonedListing.weight]
        updatedAccepted[i] = val;
        setgetQuotedata({
            ...getQuotedata,
            dimensions: {
                ...clonedListing,
                weight: updatedAccepted
            }
        });

    }

    function updatelength(val, i) {
        const updatedCount = 1;
        const clonedListing = { ...getQuotedata.dimensions };
        const updatedAccepted = [...clonedListing.length]
        updatedAccepted[i] = val;
        setgetQuotedata({
            ...getQuotedata,
            dimensions: {
                ...clonedListing,
                length: updatedAccepted
            }
        });

    }

    function updatewidth(val, i) {
        const updatedCount = 1;
        const clonedListing = { ...getQuotedata.dimensions };
        const updatedAccepted = [...clonedListing.width]
        updatedAccepted[i] = val;
        setgetQuotedata({
            ...getQuotedata,
            dimensions: {
                ...clonedListing,
                width: updatedAccepted
            }
        });

    }

    function updateheight(val, i,) {
        const updatedCount = 1;
        const clonedListing = { ...getQuotedata.dimensions };
        const updatedAccepted = [...clonedListing.height]
        updatedAccepted[i] = val;
        setgetQuotedata({
            ...getQuotedata,
            dimensions: {
                ...clonedListing,
                height: updatedAccepted
            }
        });

    }


    function showthisdiv(val) {
        if (val == 'exportdiv') {

            setshowdiv('exportdiv')
            setexportclass('active')
            setimportclass('')
            setgetQuotedata({ ...getQuotedata, shipmenttype: val, origin: 'AE', destination: default_dest })
        } else if (val == 'importdiv') {

            setshowdiv('importdiv')
            setexportclass('')
            setimportclass('active')
            setgetQuotedata({ ...getQuotedata, shipmenttype: val, origin: default_dest, destination: 'AE' })

        }


    }




    function showtypediv(val) {
        if (val == 'package') {
            settypediv('package')
            setpackclass('active')
            setdocclass('')
        } else if (val == 'document') {
            settypediv('document')
            setpackclass('')
            setdocclass('active')
        }
        setgetQuotedata({ ...getQuotedata, typediv: val })
    }




    const [countdiv, setcountdiv] = useState(1)
    const [countdiv_domestic, setcountdiv_domestic] = useState(1)
    function increment(val) {
        if (val == 'inter') {
            setcountdiv((prevCount) => prevCount + 1);
        }
        else if (val == 'domestic') {
            setcountdiv_domestic((prevCount) => prevCount + 1);
        }

    }




    function updateweight_dom(val, i) {
        const updatedCount = 1;
        const clonedListing = { ...getQuotedata_dom.dimensions };
        const updatedAccepted = [...clonedListing.weight]
        updatedAccepted[i] = val;
        setgetQuotedata_dom({
            ...getQuotedata_dom,
            dimensions: {
                ...clonedListing,
                weight: updatedAccepted
            }
        });

    }

    function updatelength_dom(val, i) {
        const updatedCount = 1;
        const clonedListing = { ...getQuotedata_dom.dimensions };
        const updatedAccepted = [...clonedListing.length]
        updatedAccepted[i] = val;
        setgetQuotedata_dom({
            ...getQuotedata_dom,
            dimensions: {
                ...clonedListing,
                length: updatedAccepted
            }
        });

    }

    function updatewidth_dom(val, i) {
        const updatedCount = 1;
        const clonedListing = { ...getQuotedata_dom.dimensions };
        const updatedAccepted = [...clonedListing.width]
        updatedAccepted[i] = val;
        setgetQuotedata_dom({
            ...getQuotedata_dom,
            dimensions: {
                ...clonedListing,
                width: updatedAccepted
            }
        });

    }

    function updateheight_dom(val, i,) {
        const updatedCount = 1;
        const clonedListing = { ...getQuotedata_dom.dimensions };
        const updatedAccepted = [...clonedListing.height]
        updatedAccepted[i] = val;
        setgetQuotedata_dom({
            ...getQuotedata_dom,
            dimensions: {
                ...clonedListing,
                height: updatedAccepted
            }
        });

    }



    function removemore(val, shiptype) {
        if (shiptype == 'inter') {

            setcountdiv((prevCount) => prevCount - 1);
            const clonedListing = { ...getQuotedata.dimensions };
            const updatedAcceptedweight = [...clonedListing.weight]
            const updatedAcceptedlength = [...clonedListing.length]
            const updatedAcceptedwidth = [...clonedListing.width]
            const updatedAcceptedheight = [...clonedListing.height]
            updatedAcceptedweight.splice(val, 1);
            updatedAcceptedlength.splice(val, 1);
            updatedAcceptedwidth.splice(val, 1);
            updatedAcceptedheight.splice(val, 1);
            setgetQuotedata({
                ...getQuotedata,
                dimensions: {
                    ...clonedListing,
                    height: updatedAcceptedheight,
                    length: updatedAcceptedlength,
                    width: updatedAcceptedwidth,
                    weight: updatedAcceptedweight,
                }
            });
        }
        else if (shiptype == 'domestic') {
            setcountdiv_domestic((prevCount) => prevCount - 1);

            const clonedListing = { ...getQuotedata_dom.dimensions };
            const updatedAcceptedweight = [...clonedListing.weight]
            const updatedAcceptedlength = [...clonedListing.length]
            const updatedAcceptedwidth = [...clonedListing.width]
            const updatedAcceptedheight = [...clonedListing.height]
            updatedAcceptedweight.splice(val, 1);
            updatedAcceptedlength.splice(val, 1);
            updatedAcceptedwidth.splice(val, 1);
            updatedAcceptedheight.splice(val, 1);
            setgetQuotedata_dom({
                ...getQuotedata_dom,
                dimensions: {
                    ...clonedListing,
                    height: updatedAcceptedheight,
                    length: updatedAcceptedlength,
                    width: updatedAcceptedwidth,
                    weight: updatedAcceptedweight,
                }
            });
        }
    }

    const API_PATH = `${SERVER_URL}quote.php`;
    //const API_PATH='http://localhost:8000/quote.php';
    function getQuote() {
        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: getQuotedata
        })
            .then((res) => {

                sessionStorage.setItem('qouteinput', JSON.stringify(getQuotedata))
                navigate('/quotation', { state: { basic: res.data.basic, qoute_rate: res.data.rate, get_gov_vat: res.data.charge1, charge_rate: res.data.charge_rate, es_surcharge: res.data.es_surcharge, oversize: res.data.oversize, er_charge: res.data.RD_charge, user_discount: res.data.user_discount, weight: res.data.weight, chargable_weight: res.data.chargable_weight, get_transit_time: res.data.get_transit_time, demand_surcharge:res.data.demand_surcharge } })

            }).catch(error => console.log(error.message));

    }

    const API_Domestic = `${SERVER_URL}quote_domestic.php`;
    function getQuoteDomestic() {
        axios({
            method: 'post',
            url: `${API_Domestic}`,
            data: getQuotedata_dom
        })
            .then((res) => {
                console.log(res.data)
                sessionStorage.setItem('qouteinput', JSON.stringify(getQuotedata_dom))
                navigate('/quotation', { state: { basic: res.data.basic, qoute_rate: res.data.rate, get_gov_vat: res.data.charge1, charge_rate: res.data.charge_rate, es_surcharge: res.data.es_surcharge, oversize: res.data.oversize, er_charge: res.data.RD_charge, user_discount: res.data.user_discount, weight: res.data.weight, chargable_weight: res.data.chargable_weight, get_transit_time: res.data.get_transit_time } })

            }).catch(error => console.log(error.message));

    }


    function copySec(val) {
        const clonedListing = { ...getQuotedata.dimensions };
        const updatedAcceptedweight = [...clonedListing.weight]
        const updatedAcceptedlength = [...clonedListing.length]
        const updatedAcceptedwidth = [...clonedListing.width]
        const updatedAcceptedheight = [...clonedListing.height]
        updatedAcceptedweight[countdiv] = updatedAcceptedweight[val];
        updatedAcceptedlength[countdiv] = updatedAcceptedlength[val];
        updatedAcceptedwidth[countdiv] = updatedAcceptedwidth[val];
        updatedAcceptedheight[countdiv] = updatedAcceptedheight[val];
        setgetQuotedata({
            ...getQuotedata,
            dimensions: {
                ...clonedListing,
                weight: updatedAcceptedweight,
                length: updatedAcceptedlength,
                width: updatedAcceptedwidth,
                height: updatedAcceptedheight
            }
        });
        setcountdiv((prevCount) => prevCount + 1);
    }

    function copySecDOM(val) {
        const clonedListing = { ...getQuotedata_dom.dimensions };
        const updatedAcceptedweight = [...clonedListing.weight]
        const updatedAcceptedlength = [...clonedListing.length]
        const updatedAcceptedwidth = [...clonedListing.width]
        const updatedAcceptedheight = [...clonedListing.height]
        updatedAcceptedweight[countdiv_domestic] = updatedAcceptedweight[val];
        updatedAcceptedlength[countdiv_domestic] = updatedAcceptedlength[val];
        updatedAcceptedwidth[countdiv_domestic] = updatedAcceptedwidth[val];
        updatedAcceptedheight[countdiv_domestic] = updatedAcceptedheight[val];
        setgetQuotedata_dom({
            ...getQuotedata_dom,
            dimensions: {
                ...clonedListing,
                weight: updatedAcceptedweight,
                length: updatedAcceptedlength,
                width: updatedAcceptedwidth,
                height: updatedAcceptedheight
            }
        });
        setcountdiv_domestic((prevCount) => prevCount + 1);
    }


    return (
        <div>
            <Helmet>
        <title>Time Express | Send</title>
      </Helmet>
            <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className='h1-title'>SEND A PARCEL WITH<br />
                                TIME EXPRESS</h1>
                          
                            {/* <h5 style={{color:'#fff',fontWeight:"bold"}}>Get a quote easily with our online booking tool</h5> */}
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div>
                <div className="quick-quote-home-bg">
                    <div className="img-container">
                        <img width={1349} height={847} />
                    </div>
                </div>
                <div className="quick-quote-home">

                    <div className="tab-quick-quote">
                        <div className="container">
                            <div style={{ height: '40px' }} />
                            <ul className="nav nav-tabs" id="myTab" role="tablist" style={{ minHeight: "auto" }}>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => changefun('in-package-document-tab')} className="nav-link active" id="in-package-document-tab" data-bs-toggle="tab" data-bs-target="#send_international_package_document" type="button" role="tab" aria-controls="send_international_package_document" aria-selected="true">Send Parcel International</button>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <button onClick={() => changefun('in-freight-tab')} className="nav-link" id="in-freight-tab" data-bs-toggle="tab" data-bs-target="#in-freight" type="button" role="tab" aria-controls="in-freight" aria-selected="false">Send Freight International</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" onClick={() => changefun('road-gcc-tab')} id="road-gcc-tab" data-bs-toggle="tab" data-bs-target="#road-gcc" type="button" role="tab" aria-controls="road-gcc" aria-selected="false">Send Road to GCC</button>
                                </li> */}
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" onClick={() => changefun('uae-uae-tab')} id="uae-uae-tab" data-bs-toggle="tab" data-bs-target="#uae-uae" type="button" role="tab" aria-controls="uae-uae" aria-selected="false">Send UAE to UAE</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="send_international_package_document" role="tabpanel" aria-labelledby="in-package-document-tab">
                                    <div className="container">
                                        <div className="row">
                                            {/* Send International Package */}
                                            <form action method="post" id="send-in-package" style={{ padding: 0 }}>
                                                <div className="quick-holder">
                                                    <div className="col-md-5 col-sm-5">
                                                        <h3 className="section-head">Where are you sending your parcels from/to?</h3>
                                                        <div className="tab-in-export-import">
                                                            <a onClick={() => showthisdiv('exportdiv')} className={`export-link in-send-package ${exportclass}`} id="export-link-ip">Export</a>
                                                            <a onClick={() => showthisdiv('importdiv')} className={`import-link in-send-package  ${importclass}`} id="import-link-ip">Import</a>
                                                            <div className="tab-package-document">
                                                                <a onClick={() => showtypediv('package')} className={`package-link package-document ${packclass}`} id="package-link">Package</a>
                                                                <a onClick={() => showtypediv('document')} className={`document-link package-document ${docclass}`} id="document-link">Document</a>
                                                            </div>
                                                        </div>
                                                        {/* International Export Package */}
                                                        {showdiv && showdiv == 'exportdiv' ? (
                                                            <div className="tab-in-export-package">
                                                                <ul className="in-export-package from-to">
                                                                    <li>
                                                                        <label>From</label>
                                                                        <select className="form-select select-export-package" name="delivery_from" onChange={(e) => setgetQuotedata({ ...getQuotedata, origin: e.target.value })}>
                                                                            <option value="AE">Abu Dhabi</option>
                                                                            <option value="AE">Ajman</option>
                                                                            <option value="AE">Al Ain</option>
                                                                            <option value="AE" selected >Dubai</option>
                                                                            <option value="AE">Fujairah</option>
                                                                            <option value="AE">Ras Al Khaimah</option>
                                                                            <option value="AE">Sharjah</option>
                                                                            <option value="AE">Umm Al Quwain</option>
                                                                        </select>
                                                                    </li>
                                                                    <li>
                                                                        <label>To</label>
                                                                        <select className="form-select select-export-package" name="delivery_to" onChange={(e) => setgetQuotedata({ ...getQuotedata, destination: e.target.value })}>
                                                                            {

                                                                                Object.entries(countries).map(([k, v]) => (
                                                                                    <option key={k} value={k} selected={k === default_dest}>{v}</option>
                                                                                ))
                                                                            }

                                                                        </select>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        ) : null
                                                        }
                                                        {/* End International Export Package */}
                                                        {/* International Import Package */}
                                                        {showdiv && showdiv == 'importdiv' ? (


                                                            <div className="tab-in-import-package" >
                                                                <ul className="in-import-package from-to">
                                                                    <li>
                                                                        <label>From</label>
                                                                        <select className="form-select select-export-package" name="delivery_to" onChange={(e) => setgetQuotedata({ ...getQuotedata, origin: e.target.value })}>
                                                                            {

                                                                                Object.entries(countries).map(([k, v]) => (
                                                                                    <option key={k} value={k} selected={k === default_dest}>{v}</option>
                                                                                ))
                                                                            }

                                                                        </select>
                                                                    </li>
                                                                    <li>
                                                                        <label>To</label>
                                                                        <select className="form-select select-export-package" name="delivery_from" onChange={(e) => setgetQuotedata({ ...getQuotedata, destination: e.target.value })}>
                                                                            <option value="AE">Abu Dhabi</option>
                                                                            <option value="AE">Ajman</option>
                                                                            <option value="AE">Al Ain</option>
                                                                            <option value="AE" selected >Dubai</option>
                                                                            <option value="AE">Fujairah</option>
                                                                            <option value="AE">Ras Al Khaimah</option>
                                                                            <option value="AE">Sharjah</option>
                                                                            <option value="AE">Umm Al Quwain</option>
                                                                        </select>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        ) : null
                                                        }
                                                        {/* End International Import Package */}
                                                    </div>
                                                    <div className="col-md-7 col-sm-7">
                                                        <div id="add_more_weight_package_in" className="add_more_weight">
                                                            {[...Array(countdiv)].map((e, i) => <><div key={i}>
                                                                <h3 className="section-head">Enter the weight and dimensions of your parcels {i > 0 ? (
                                                                    <button type="button" className="removebtn" onClick={() => removemore(i, 'inter')}>x</button>
                                                                ) : null} </h3>
                                                                <div className="weight-section">
                                                                    <ul className="in-export-package from-to">

                                                                        {typediv && typediv == 'package' ? (
                                                                            <li>
                                                                                <label>Weight*(kg)</label>
                                                                                <select className="form-select" name="weight[]" onChange={(e) => updateweight(e.target.value, i)}>
                                                                                    {/* <select className="form-select" name="weight[]" onChange={(e)=>setgetQuotedata({...getQuotedata,weight:e.target.value})}> */}
                                                                                    {/* <select className="form-select" name="weight[]"  onChange={(e)=>updateweight(e.target.value,'')}> */}
                                                                                    {i > 0 ? (
                                                                                        <option value="">select weight</option>
                                                                                    ) : null
                                                                                    }
                                                                                    <option value="0.5" selected={getQuotedata.dimensions.weight[i] === '0.5'} >0.5</option>
                                                                                    <option value="1" selected={getQuotedata.dimensions.weight[i] === '1'}>1</option>
                                                                                    <option value="1.5" selected={getQuotedata.dimensions.weight[i] === '1.5'}>1.5</option>
                                                                                    <option value="2" selected={getQuotedata.dimensions.weight[i] === '2'}>2</option>
                                                                                    <option value="2.5" selected={getQuotedata.dimensions.weight[i] === '2.5'}>2.5</option>
                                                                                    <option value="3" selected={getQuotedata.dimensions.weight[i] === '3'}>3</option>
                                                                                    <option value="3.5" selected={getQuotedata.dimensions.weight[i] === '3.5'}>3.5</option>
                                                                                    <option value="4" selected={getQuotedata.dimensions.weight[i] === '4'}>4</option>
                                                                                    <option value="4.5" selected={getQuotedata.dimensions.weight[i] === '4.5'} >4.5</option>
                                                                                    <option value="5" selected={getQuotedata.dimensions.weight[i] === '5'}>5</option>
                                                                                    <option value="5.5" selected={getQuotedata.dimensions.weight[i] === '5.5'}>5.5</option>
                                                                                    <option value="6" selected={getQuotedata.dimensions.weight[i] === '6'}>6</option>
                                                                                    <option value="6.5" selected={getQuotedata.dimensions.weight[i] === '6.5'}>6.5</option>
                                                                                    <option value="7" selected={getQuotedata.dimensions.weight[i] === '7'}>7</option>
                                                                                    <option value="7.5" selected={getQuotedata.dimensions.weight[i] === '7.5'}>7.5</option>
                                                                                    <option value="8" selected={getQuotedata.dimensions.weight[i] === '8'}>8</option>
                                                                                    <option value="8.5" selected={getQuotedata.dimensions.weight[i] === '8.5'} >8.5</option>
                                                                                    <option value="9" selected={getQuotedata.dimensions.weight[i] === '9'}>9</option>
                                                                                    <option value="9.5" selected={getQuotedata.dimensions.weight[i] === '9.5'}>9.5</option>


                                                                                    {[...Array(71)].map((x, k) => (
                                                                                        k > 9 ? (
                                                                                            <option value={k} selected={getQuotedata.dimensions.weight[i] === k} key={k} >{k}</option>
                                                                                        ) : null
                                                                                    )
                                                                                    )}
                                                                                </select>
                                                                            </li>
                                                                        ) : <li>
                                                                            <label>Weight* (kg)</label>
                                                                            <select className="form-select" name="weight[]" onChange={(e) => updateweight(e.target.value, i)}>
                                                                                {i > 0 ? (
                                                                                    <option value="">select weight</option>
                                                                                ) : null
                                                                                }
                                                                                <option value="0.5" selected={getQuotedata.dimensions.weight[i] === '0.5'}>0.5</option>
                                                                                <option value="1" selected={getQuotedata.dimensions.weight[i] === '1'}>1</option>
                                                                                <option value="1.5" selected={getQuotedata.dimensions.weight[i] === '1.5'}>1.5</option>
                                                                                <option value="2" selected={getQuotedata.dimensions.weight[i] === '2'}>2</option>
                                                                            </select>
                                                                        </li>
                                                                        }


                                                                        <li>
                                                                            <label>Length (cm)</label>
                                                                            <input className="form-control" name="length[]" value={getQuotedata.dimensions.length[i]} type="text" onChange={(e) => updatelength(e.target.value, i)} />
                                                                        </li>
                                                                        <li>
                                                                            <label>Width (cm)</label>
                                                                            <input className="form-control" name="width[]" value={getQuotedata.dimensions.width[i]} type="text" onChange={(e) => updatewidth(e.target.value, i)} />
                                                                        </li>
                                                                        <li>
                                                                            <label>Height (cm)</label>
                                                                            <input className="form-control" name="height[]" value={getQuotedata.dimensions.height[i]} type="text" onChange={(e) => updateheight(e.target.value, i)} />
                                                                        </li>
                                                                        <a className="copybtn" onClick={() => copySec(i)}>copy</a>
                                                                    </ul>

                                                                </div>
                                                            </div>
                                                            </>
                                                            )
                                                            }
                                                        </div>
                                                        <button onClick={() => increment('inter')} className="btn btn-primary btn-parcels add-btn add_more_sizes" id="in_package" type="button" >Add another package</button>
                                                    </div>
                                                </div>
                                                <hr className="hr_border" />
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-8 col-sm-8">
                                                            <div className="row">
                                                                <div className="col-md-6 col-sm-6">
                                                                    <h3 className="section-head"><mark>When are you shipping?</mark></h3>
                                                                </div>
                                                                <div className="col-md-6 col-sm-6">
                                                                    <DatePicker className="form-control shipping_date" name="shipping_date" dateFormat='dd/MM/yyyy' selected={startDate} onChange={() => setStartDate(date)} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-4">
                                                            <div className="wrape_promo" style={{ textAlign: 'center' }}>
                                                                <span className="get_center_bnts">
                                                                    <button className="btn btn-primary" type="button" name="get_quotes" id="get_quote" flag="package" onClick={getQuote}>Get Quote</button>
                                                                    <input type="hidden" name="get_quote" defaultValue="Get Quote" />
                                                                    <input type="hidden" name="parcel_type" id="in_parcel_type" defaultValue="export" />
                                                                    <input type="hidden" name="pac_type" id="pac-pac-link" defaultValue="package" />
                                                                    <input type="hidden" name="promo_discount" defaultValue id="promo_discount" />
                                                                </span>
                                                                {/* <div class="yodeldirect-hedding"><h3>Temporary Out of Service</h3></div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            {/* End Send International Package */}
                                            {/* Send International Document */}
                                            {/* document div */}
                                            {/* End Send International Document */}
                                        </div>
                                    </div>
                                </div>


                                <div className="tab-pane fade" id="uae-uae" role="tabpanel" aria-labelledby="uae-uae-tab">
                                    <div className="container">
                                        <div className="row">
                                            {/* Send UAE to UAE */}
                                            <form action method="post" id="send-uae-to-uae" style={{ padding: 0 }}>
                                                <div className="quick-holder">
                                                    <div className="col-md-5 col-sm-5">
                                                        <h3 className="section-head">Where are you sending your parcels from/to?</h3>
                                                        <div className="tab-in-export-import">
                                                            <a href="" className="export-link uae-to-uae active" id="export-link-freight">Next Day</a>
                                                        </div>
                                                        <div className="tab-uae-to-uae">
                                                            <ul className="in-export-freight from-to">
                                                                <li>
                                                                    <label>From</label>
                                                                    <select className="form-select select-uae-to-uae" name="delivery_from" onChange={(e) => setgetQuotedata_dom({ ...getQuotedata_dom, origin: e.target.value })}>
                                                                        <option value="DXB">Dubai</option>
                                                                        <option value="AUH">Abudhabi</option>

                                                                    </select>
                                                                </li>
                                                                <li>
                                                                    <label>To</label>
                                                                    <select className="form-select select-uae-to-uae" name="delivery_to" onChange={(e) => setgetQuotedata_dom({ ...getQuotedata_dom, destination: e.target.value })}>
                                                                        {

                                                                            Object.entries(domesticloc).map(([k, v]) => (
                                                                                <option key={k} value={k} selected={k === 'AUH'}>{v}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-7 col-sm-7"  >
                                                        <div id="add_more_weight_uae_to_uae" className="add_more_weight">
                                                            {[...Array(countdiv_domestic)].map((e, i) => <><div key={i}>
                                                                <h3 className="section-head">Enter the weight and dimensions of your parcels {i > 0 ? (
                                                                    <button type="button" className="removebtn" onClick={() => removemore(i, 'domestic')}>x</button>
                                                                ) : null} </h3>
                                                                <div className="weight-section">
                                                                    <ul className="uae-to-uae from-to">

                                                                        <li>
                                                                            <label>Weight* (kg)</label>
                                                                            <select className="form-select" name="weight[]" onChange={(e) => updateweight_dom(e.target.value, i)}>
                                                                                <option value="0.5" selected={getQuotedata_dom.dimensions.weight[i] === '0.5'} >0.5</option>
                                                                                <option value="1" selected={getQuotedata_dom.dimensions.weight[i] === '1'}>1</option>
                                                                                <option value="1.5" selected={getQuotedata_dom.dimensions.weight[i] === '1.5'}>1.5</option>
                                                                                <option value="2" selected={getQuotedata_dom.dimensions.weight[i] === '2'}>2</option>
                                                                                <option value="2.5" selected={getQuotedata_dom.dimensions.weight[i] === '2.5'}>2.5</option>
                                                                                <option value="3" selected={getQuotedata_dom.dimensions.weight[i] === '3'}>3</option>
                                                                                <option value="3.5" selected={getQuotedata_dom.dimensions.weight[i] === '3.5'}>3.5</option>
                                                                                <option value="4" selected={getQuotedata_dom.dimensions.weight[i] === '4'}>4</option>
                                                                                <option value="4.5" selected={getQuotedata_dom.dimensions.weight[i] === '4.5'} >4.5</option>
                                                                                <option value="5" selected={getQuotedata_dom.dimensions.weight[i] === '5'}>5</option>
                                                                                <option value="5.5" selected={getQuotedata_dom.dimensions.weight[i] === '5.5'}>5.5</option>
                                                                                <option value="6" selected={getQuotedata_dom.dimensions.weight[i] === '6'}>6</option>
                                                                                <option value="6.5" selected={getQuotedata_dom.dimensions.weight[i] === '6.5'}>6.5</option>
                                                                                <option value="7" selected={getQuotedata_dom.dimensions.weight[i] === '7'}>7</option>
                                                                                <option value="7.5" selected={getQuotedata_dom.dimensions.weight[i] === '7.5'}>7.5</option>
                                                                                <option value="8" selected={getQuotedata_dom.dimensions.weight[i] === '8'}>8</option>
                                                                                <option value="8.5" selected={getQuotedata_dom.dimensions.weight[i] === '8.5'} >8.5</option>
                                                                                <option value="9" selected={getQuotedata_dom.dimensions.weight[i] === '9'}>9</option>
                                                                                <option value="9.5" selected={getQuotedata_dom.dimensions.weight[i] === '9.5'}>9.5</option>


                                                                                {[...Array(71)].map((x, z) => (
                                                                                    z > 9 ? (
                                                                                        <option value={z} key={z} selected={getQuotedata_dom.dimensions.weight[i] === z}>{z}</option>
                                                                                    ) : null

                                                                                )
                                                                                )}
                                                                            </select>
                                                                        </li>
                                                                        <li>
                                                                            <label>Length (cm)</label>
                                                                            <input className="form-control" name="length[]" value={getQuotedata_dom.dimensions.length[i]} placeholder type="text" onChange={(e) => updatelength_dom(e.target.value, i)} />
                                                                        </li>
                                                                        <li>
                                                                            <label>Width (cm)</label>
                                                                            <input className="form-control" name="width[]" value={getQuotedata_dom.dimensions.width[i]} placeholder type="text" onChange={(e) => updatewidth_dom(e.target.value, i)} />
                                                                        </li>
                                                                        <li>
                                                                            <label>Height (cm)</label>
                                                                            <input className="form-control" name="height[]" value={getQuotedata_dom.dimensions.height[i]} placeholder type="text" onChange={(e) => updateheight_dom(e.target.value, i)} />
                                                                        </li>
                                                                        <a className="copybtn" onClick={() => copySecDOM(i)}>copy</a>
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                            </>
                                                            )}

                                                            <button onClick={() => increment('domestic')} className="btn btn-primary btn-parcels add-btn add_more_sizes" id="uae_to_uae" type="button" data-toggle="collapse" data-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample1">Add another package</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <hr className="hr_border" />
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-8 col-sm-8">
                                                            <div className="row">
                                                                <div className="col-md-6 col-sm-6">
                                                                    <h3 className="section-head"><mark>When are you shipping?</mark></h3>
                                                                </div>
                                                                <div className="col-md-6 col-sm-6">
                                                                    <DatePicker className="form-control shipping_date" name="shipping_date" dateFormat='dd/MM/yyyy' selected={startDate} onChange={() => setStartDate(date)} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-4">
                                                            <div className="wrape_promo" style={{ textAlign: 'center' }}>
                                                                <span className="get_center_bnts">
                                                                    <button className="btn btn-primary" type="button" name="get_quotes" id="get_quote" flag="freight" onClick={getQuoteDomestic}>Get Quote</button>
                                                                    <input type="hidden" name="get_quote" defaultValue="Get Quote" />
                                                                    <input type="hidden" name="parcel_type" id="uae_parcel_type" defaultValue="nextday" />
                                                                    <input type="hidden" name="promo_discount" defaultValue id="promo_discount" />
                                                                </span>
                                                                {/* <div class="yodeldirect-hedding"><h3>Temporary Out of Service</h3></div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            {/* End Send UAE to UAE */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

            <section className="business_needs_sec text-center fw">
                <div className="container">

                    <div className="business_needs_sec row">
                        <h2>ABOUT SENDING</h2>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Our Bulk Upload Tools</h3>
                                        <p></p>
                                        <p style={{ minHeight: "120px" }}>SPREADSHEET UPLOAD SHIPPING TOOLS Bulk uploading of product information is the modern way of listing items on any e-commerce website as quickly as possible, saving time and increasing profits by getting your products listed online without having to use cumbersome manual methods of transferring data.</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/send/our-bulk-upload-tools">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img " >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Sending Multiple Parcels Discount Tool</h3>
                                        <p></p>
                                        <p style={{ minHeight: "140px" }}>Sure! How many would you like to send? Our customers love the flexibility we can offer them when it comes to arranging parcel collections and deliveries.</p>
                                        <p></p>

                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/send/sending-multiple-parcels-discount-tool">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img " >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Shop USA, Ship UAE</h3>
                                        <p></p>
                                        <p style={{ minHeight: "140px" }}>Shop and send what you love from the USA to your door step.</p>
                                        <p></p>

                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/shop-usa-ship-uae/">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img " >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Local Courier, Same Day Courier</h3>
                                        <p></p>
                                        <p style={{ minHeight: "120px" }}>Our Services – On-Demand 24/7 We’re focused on growing your business while staying lean. Time Express helps you handle your shipping volume as you grow. No matter what you want to send, we can help you.</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/send/local-courier-same-day-courier">Find out more</Link></div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>
            <div className="pages_content">
                <div className="container">
                    <div className="row">
                        <div id="primary" className="content-area" style={{ float: 'left', width: '100%' }}>
                            <main id="main" className="site-main" role="main">
                                <div className="entry-content">
                                    <div className="become_driver">
                                        <h4>What Is Time Direct?</h4>
                                        <p>One of the best services we offer is Time Direct which offers same day collection and delivery 365 days of the year and up to 9pm on week-days with a Friday delivery option.</p>
                                        <p>Time Direct is particularly useful for e-retailers and e-commerce companies offering full tracking and customers can select a time when they want to receive the items, plus you can watch the courier via the tracking portal until the item is delivered to the recipient. You can also choose a vehicle size which is perfect for your item.</p>
                                        <p>If you are an online business and should have sent your parcels yesterday, or you’ve had a call from a customer and they need some goods or paperwork sent urgently then we’re here with a same day collection service.

                                        </p>
                                        <p>All you need to do is place your order before 11:30 am and meet our minimum quantity requirements and we’ll be able to get it collected same day via our fleet and delivered the same day by 9pm in the evening.</p>

                                        <h4>I Want To Send A Parcel How do I Do This?</h4>
                                        <p>We offer multiple ways you can place your domestic and international packages orders from via web to using our upload tools to speed your AWB entry process. For high volume customers where time criticality is of the essence, we provide API integration for fast and efficient placement and tracking of your parcels.
                                        </p><p>If you are a business, you can visit the Your Business page, where you can find out information on Time Express services. If you only want to send a same day delivery of your parcels then Time Direct could be the right choice for you.
                                        </p><p>Have you purchased the wrong item and want to return it? Why not send your international package and save over 40% on your parcel costs? Try our international express services from Dubai and benefit from excellent transit time and significantly lower charges!
                                        </p><p>Our Worldwide reliable deliveries courier service caters for both businesses and residential consumers with daily collections and delivery of international packages anywhere in the world!
                                        </p>


                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
