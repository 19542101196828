import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import DatePicker from "react-datepicker";
import { Link, useNavigate } from 'react-router-dom'
import { country_code_array2 } from './functions';
import { domestic_destination_array } from './functions';
import "react-datepicker/dist/react-datepicker.css";
import '../../Home1.css'
import banner from '../../images/banners/smartsend1.jfif'
import shopify from '../../images/shopify.png'
import woo from '../../images/wooco.png'
import magento from '../../images/magento.png'
import loading from '../../images/spin.gif'
import axios from 'axios';

import { Helmet } from 'react-helmet';
export default function SmartSend() {


    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [passerror, setpasserror] = useState('')
const [passsuccess, setpasssuccess] = useState('')
const [loadingshow, setloadingshow] = useState(false)
const [enquiry, setenquiry] = useState({
 name:'',
 phone:'',
 platform:''
 })
 const [sector, setsector] = useState([])

 const [stsmsg, setstsmsg] = useState()
 //const API_PATH='http://localhost:8000/smart_send_enquiry.php';
 const API_PATH=`${SERVER_URL}smart_send_enquiry.php`;
function sendEnquiry(){
 if(enquiry.name!="" && enquiry.phone!="" && enquiry.company_name!="" && enquiry.platform!=""&& enquiry.address!=""&& enquiry.city!="" && sector!=""){
     setstsmsg('')
     setloadingshow(true)
 axios({
     method:"post",
     url:`${API_PATH}`,
     data:{enquiry:enquiry,sector:sector}
 })
 .then((res)=>{
 console.log(res.data)
 setloadingshow(false)
setstsmsg(res.data.message)
 })
 .catch(err=>console.log(err))
}else{
 setstsmsg('Please enter the required fields')
}
}


    return (
        <div>
            <Helmet>
        <title>Time Express | Smart Send</title>
      </Helmet>
            <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">Smarter E-Commerce Shipping for Your Business</h1>
                            <h3 style={{color:'#fff',fontWeight:'500',fontSize:"18px"}}>Smart Connect is the 100% Free shipping management platform by Timex.  Designed with facilitating and integrating all your e-commerce bookings and logistics under one roof, it enables you to manage all your deliveries tracking from one easy-to-use dashboard.</h3>
                            {/* <h5 style={{color:'#fff',fontWeight:"bold"}}>Get a quote easily with our online booking tool</h5> */}
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-2" ></div>
           <div className="col-md-4" style={{textAlign:'center'}}>
           <img src={shopify} style={{width:"65%"}}/>
           <div className="row">
            <div className="col-md-6"><a href="https://apps.shopify.com/time-express" target="_blank"> <button className='smartbtn'>Download</button></a></div>
            <div className="col-md-6"> <Link to='/smart-send/shopify'><button className='smartbtn2'>Learn More</button></Link></div>
           </div>
          
           </div>
           <div className="col-md-4" style={{textAlign:'center'}}>
           <img src={woo} style={{width:"60%"}}/>
           <div className="row">
            <div className="col-md-6"> <a href="https://wordpress.org/plugins/time-express/" target="_blank"><button className='smartbtn'>Download</button></a></div>
            <div className="col-md-6"> <Link to='/smart-send/woocommerce'><button className='smartbtn2'>Learn More</button></Link></div>
           </div>
           </div>
           {/* <div className="col-md-4" style={{textAlign:'center'}}>
           <img src={magento} style={{width:"60%"}}/>
           <div className="row">
            <div className="col-md-6"> <a href="https://marketplace.magento.com/time-express-parcels-time-express-parcels.html" target="_blank"> <button className='smartbtn'>Download</button></a></div>
            <div className="col-md-6"> <Link to='/smart-send/magento'><button className='smartbtn2'>Learn More</button></Link></div>
           </div>
           </div> */}
        </div>
                </div>
                </div>
            </section>
            <div>

            </div>

         

            <section className="business_needs_sec text-center fw">
                <div className="container">

                    <div className="business_needs_sec row" style={{paddingTop:'1%'}}>
                        <h2 style={{marginBlock:'0px'}}>ABOUT SENDING</h2>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Our Bulk Upload Tools</h3>
                                        <p></p>
                                        <p style={{ minHeight: "120px" }}>SPREADSHEET UPLOAD SHIPPING TOOLS Bulk uploading of product information is the modern way of listing items on any e-commerce website as quickly as possible, saving time and increasing profits by getting your products listed online without having to use cumbersome manual methods of transferring data.</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/send/our-bulk-upload-tools">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img " >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Sending Multiple Parcels Discount Tool</h3>
                                        <p></p>
                                        <p style={{ minHeight: "140px" }}>Sure! How many would you like to send? Our customers love the flexibility we can offer them when it comes to arranging parcel collections and deliveries.</p>
                                        <p></p>

                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/send/sending-multiple-parcels-discount-tool">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img " >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Shop USA, Ship UAE</h3>
                                        <p></p>
                                        <p style={{ minHeight: "140px" }}>Shop and send what you love from the USA to your door step.</p>
                                        <p></p>

                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/shop-usa-ship-uae/">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        {/*                         
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img " >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Local Courier, Same Day Courier</h3>
                                        <p></p>
                                        <p style={{ minHeight: "120px" }}>Our Services – On-Demand 24/7 We’re focused on growing your business while staying lean. Time Express helps you handle your shipping volume as you grow. No matter what you want to send, we can help you.</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/send/local-courier-same-day-courier">Find out more</Link></div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>

            <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
           <h4>BUILD ON YOUR SUCCESS</h4>
           <h4>INSTANT INTEGRATION INTO SHOPIFY & WOOCOMMERCE</h4>
           <h4>TAKE THE HASSTLE OUT OF SHIPPING</h4>
           <p>Have you build your merchant store using Shopify and Woo Commerce? Download the Time Express App from the Shopify and Woo Commerce Store to integrate right away and have Time Express as your logistics partner at checkout page.</p>
           <p>By using Shopify and WooCommerce with Time Express integration use one platform to sell products to anyone, anywhere at lower courier rates —in person with Point of Sale and online through your website, social media, and online marketplaces.</p>
           <p>Looking to build your website? Shopify powers over 1,000,000 businesses worldwide, it is an all-in-one commerce platform to start, run, and grow a business. WooCommerce is an open-source e-commerce plugin for WordPress. It is designed for small to large-sized online merchants using WordPress</p>
      
        <h4>TAP INTO NEW MARKETS</h4>
        <h4>OPEN YOURSELF UP TO TENS OF MILLIONS OF ADDITIONAL POTENTIAL CUSTOMERS</h4>
      <p>The days of only being able to sell products to customers in one town, region, or country are long gone as businesses of all sizes can easily export their products across the World. We have designed a range of delivery and distribution services to help you provide the tools and services that make it as easy for small businesses to sell abroad than in your home country as well as export at the lowest possible international parcels rates. We partner with you to expand your horizons by offering your products on regional and Global basis. We Make Shipping Simple & Easy. Online Shopping is Limited to Nothing, Let the World be Your Market Place.</p>
      <form> 
               <div className="row">
               <h4>Start an Account with Us</h4>
                  
          
<div className="col-md-6">
<div className="form-group"> <label>Full Name*</label>
<br /> <span className="wpcf7-form-control-wrap FirstName">
<input type="text" name="FirstName" onChange={(e)=>setenquiry({...enquiry,name:e.target.value})}  size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="fullname" aria-required="true" aria-invalid="false" placeholder="Please Enter Your First Name" />
</span>
</div>
<div className="form-group"> <label>Company Name*</label>
<br /> <span className="wpcf7-form-control-wrap CompanyName">
<input type="text" name="CompanyName" onChange={(e)=>setenquiry({...enquiry,company_name:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="company" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Company Name" />
</span>
</div>
<div className="form-group"> <label>Email Address*</label>
<br /> <span className="wpcf7-form-control-wrap email">
<input type="email" name="email" onChange={(e)=>setenquiry({...enquiry,email:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Email Address" />
</span>
</div>
<div className="form-group"> <label>Telephone*</label>
<br /> <span className="wpcf7-form-control-wrap telephone">
<input type="tel" name="telephone" onChange={(e)=>setenquiry({...enquiry,phone:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel form-control" id="tel" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Telephone" />
</span>
</div>
<div className="form-group"> <label>Address*</label>
<br /> <span className="wpcf7-form-control-wrap Address">
<input type="text" name="Address" onChange={(e)=>setenquiry({...enquiry,address:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="address" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Address" />
</span>
</div>
<div className="form-group"> <label>Town/City*</label>
<br /> <span className="wpcf7-form-control-wrap TownCity">
<input type="text" name="TownCity" onChange={(e)=>setenquiry({...enquiry,city:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="town" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Town or City" />
</span>
</div>
</div>
                   <div className="col-md-6" style={{paddingLeft:"6%"}}>
                   

                
<div className="form-group"> <label>Ecommerce Platform*</label>
<br /> <span className="wpcf7-form-control-wrap TownCity">
<select name="Courier" onChange={(e)=>setenquiry({...enquiry,platform:e.target.value})} className="wpcf7-form-control wpcf7-select form-control" id="courier" aria-invalid="false">
<option value="Please Select">Please Select</option>
<option value="Shopify">Shopify</option>
<option value="WooCommerce">WooCommerce</option>
<option value="Magento">Magento</option>
</select>
</span>
</div>

<div className="form-group"> <label>Vendor Type</label>
<br /> <span className="wpcf7-form-control-wrap TownCity">
<select name="Courier" onChange={(e)=>setenquiry({...enquiry,vendor:e.target.value})} className="wpcf7-form-control wpcf7-select form-control" id="courier" aria-invalid="false">
<option value="Please Select">Please Select</option>
<option value="1">Single Vendor</option>
<option value="2">Multi Vendor</option>
</select>
</span>
</div>

<div className="form-group"> <label>Shipping To*</label>
<br /> <span className="wpcf7-form-control-wrap TownCity">
<span className="wpcf7-list-item first">
<input type="checkbox" name="Sectors" onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}}  defaultValue="Domestic" />
<span className="wpcf7-list-item-label">Domestic</span>
</span>
<span className="wpcf7-list-item">
<input type="checkbox" name="Sectors" onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="International" />
<span className="wpcf7-list-item-label">International</span>
</span>
</span>
</div>
<div className="col-md-12">
<div className="form-group textarea_reilan"> <label className="textarea_label "> Any further information?</label>
<br /> <span className="wpcf7-form-control-wrap your-message">
<textarea name="your-message" onChange={(e)=>setenquiry({...enquiry,message:e.target.value})} cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Write additional information in this box..." defaultValue={""} />
</span>
</div>
</div>
<button type="button" className="btn  btn-sm" onClick={sendEnquiry}  style={{backgroundColor:'#7b3995',color:"#fff",padding:"2%",width:"19%",float:"right"}}>Submit</button>
 <p>{stsmsg}</p>
 {loadingshow?(
      <p style={{textAlign:"center",marginTop:"2%"}}><img src={loading} style={{width:"5%"}}/></p> 
    ):null}
</div>
         
               </div>
               </form>
    </div>
    </div>
    </main>
    
    </div>
    </div>
    </div>
    </div>
          
        </div>
    )
}
