import React, { useEffect, useState } from 'react'
import '../../Help.css';
import { useNavigate, Link, useParams, Route, Routes } from 'react-router-dom'
import axios from 'axios';
import banner from '../../images/banners/career.jpg'
import { Helmet } from 'react-helmet';

export default function Careers() {
  const [tabpath, settabpath] = useState('fuels')
  const [count, setCount] = useState(0)
  //  let role=sessionStorage.getItem('userid')
  const [help, sethelp] = useState()

  return (

    <>
      <Helmet>
        <title>Time Express | Careers</title>
      </Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;0,600;0,700;1,500&display=swap" rel="stylesheet" />
      <section id="cd-intro">


        <section id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 your_business text-center">
                <div className="business_content">
                  <h1 className='h1-title'>Careers</h1>
                  
                  {/* <p>Search for answers or explore regular help topics below</p> */}
                </div>
              </div>
              {/* <div className="form-help">
                                <form role="search" method="get" id="searchform" className="searchform" action="https://www.timexpress.ae/">
                                    <div><label className="screen-reader-text" htmlFor="s">Search for:</label>
                                        <input type="text" defaultValue='' name="s" id="s" placeholder="Ask a question or search a topic" />
                                        <input type="submit" id="searchsubmit" defaultValue="Search" /></div>
                                </form>
                            </div> */}
            </div>
          </div>
        </section>

        <div className="container help mt-5">
       <div className="row" >
        <div className="col-md-4 career_box">
            <h5 className='careersub'>SUNDAY ONLY DRIVERS</h5>
            <p className='careersub'>Experience:</p>
        <p className="card-text">Want to earn some extra cash at weekends? Then join our team of Sunday drivers and help us to deliver thousands of parcels. We’ll even provide you with a van to use.

Collecting parcels from one of our depots early on Sunday mornings, you’ll deliver up to 50 or 60 parcels during the day, on time and in great condition. Following the most efficient route (it’s all planned out for you), you’ll meet and greet people as you deliver parcels of all shapes and sizes straight to their doors.

As well as a full UAE driving license, and the ability to lift up to 32kg in weight, you must also be aged over 21 for insurance purposes.

If you’ve got some experience of a similar role – so much the better. But we’re more interested in your passion for delivering a great customer experience and taking every delivery personally.

Join our team and you’ll be employed on a Sunday only basis, but there will be scope to take on some extra work at peak times – like Eid, Ramadan, Christmas, Easter, Mother’s Day and Valentine’s Day. If you love it as much as we do, you may even want to join us as an owner driver in the future and work with us during the week too</p>
         
        </div>
        <div className="col-md-4 career_box">
            <h5 className='careersub'>OPERATIONS MANAGEMENT</h5>
            <p className='careersub'>Experience:</p>
        <p className="card-text">In our service centers are where you find our delivery drivers, and our sort centers are where hundreds of parcels are scanned, sorted and distributed, then operations management is what ties it all up together.

Join us here and you’ll look after your own service or sort center team – taking responsibility for everything from making sure we hit our financial targets and objectives, to implementing change and managing the end-to-end customer experience.

You’ll need energy by the bucket load, experience of managing a large team, commercial acumen, the skills to multi-task, plan, prioritize and be aware of what everyone is working on at any one time. It’s a big job. But in return, you’ll get to manage your own team – working closely with senior management to make things happen, including your own career progression.</p>  
        </div>
        <div className="col-md-4 career_box">
            <h5 className='careersub'>OWNER DRIVERS</h5>
            <p className='careersub'>Experience : 5-6 Years</p>
        <p className="card-text">Become a self-employed owner driver, and you’ll deliver parcels of all shapes and sizes to homes and businesses on behalf of Time. It’ll be your job to get to customers on time, every time, and make sure the parcels are in perfect condition too. But we won’t expect you to jump straight in. As a full-time owner driver, we’ll equip you with all the training you need to do a great job, and give you the support to build your own delivery business from the ground up too. We’ll also offer you paid inductions, discounted van insurance and earning top-ups to make the first month’s training well worth your while. So what do you need to bring? That’s easy. You just need access to your own 3.5 tonne van, your own insurance (more on these below), a full UK/EU driving licence, and a sense of pride in delivering a great service for your local community. After all, that’s who you’ll be delivering to, six days a week. Experience of doing this sort of work before is an advantage, but it isn’t essential. What we’re looking for is people who have a real desire to own and develop their own business, deliver great customer service, and be part of the Yodel team. If you’ve shifted supplies or delivered goods before, you’ll know it’s more than just getting something from A to B. It’s about being self-motivated, flexible, passionate, and able to take a top-notch service to people’s doors, come rain or shine. That’s exactly what you’ll do here too – as you load your van, follow the planned route, and deliver anything up to 150 parcels a day.
            
            </p> </div>
       </div>
       <div className="row" style={{marginTop:'3%'}}>
       <div className="col-md-4 career_box">
            <h5 className='careersub'>COURIERS DRIVERS</h5>
            <p className='careersub'>Experience : 2-3 Years</p>
        <p className="card-text">As more and more people are buying their favorite things online, we need more and more drivers to collect and deliver their parcels. That’s where our diverse team of couriers comes in.

Join us and you’ll use our van to deliver parcels to customers in your local area. Your positive attitude and get-up-and-go will enable you to meet and greet lots of different people during the day, as you deliver small parcels and packages on time and in great condition straight to their doors.

Working flexibly at a time that suits you over a six-day week, you’ll fill up our van with parcels (that have already been sorted and bagged for you), plan your route, and then deliver them in your local area. Whether you want to deliver 30 or 70 parcels a day – we’re totally fine with that!

We’re looking for people with huge amounts of energy and great route planning skills. You’ll also love driving and know your local area like the back of your hand. So, join us as a courier and enjoy a bit more freedom and a lot more control over when and where you work.</p> </div>
        <div className="col-md-4 career_box">
            <h5 className='careersub'>MARKETING SPECIALIST</h5>
            <p className='careersub'>Experience : 4-5 Years</p>
        <p className="card-text">Under general supervision, plans and executes product development, pricing, product support and/or promotional initiatives that support the organization’s corporate marketing strategies.</p> 
        <p className="careersub">Job Specific Information:</p>
        <p className="card-text">Sr Marketing Specialist / Mobile Application Designer (Digital User Experience)

As part of the Global Portfolio Marketing organization, TIME EXPRESS PARCELS’s Digital Access Marketing group creates the digital experiences hat facilitate shipping-related activities for our customers. From web, desktop, mobile and retail solutions to integrated technology solutions, TIME EXPRESS PARCELS Digital Access Marketing strives for a superior and seamless experience across all customer touch points. This position is for a mobile application designer on the Digital User Experience team.   </p>
        
<p className="careersub">Job Responsibilities:</p>
<p className="card-text">Conceive and create intuitive and engaging mobile application experiences based on a deep understanding of user needs and business objectives Actively participate on an agile product development team Work closely with user experience researchers in support of user research and usability testing Define the user interface and user experience for iOS and Android platforms Facilitate creative collaboration internally, bridging between design, user research, key marketing partners and product development teams  Provide ongoing knowledge of latest technology and design trends and how they might be applied to the TIME EXPRESS PARCELS brand

Knowledge / Skills / Abilities Considered a Plus Solid foundation in user-centered design, UX and agile methodologies Human Factors or other user experience certification(s) Technology-grounded experience that spans platforms, including mobile, web and native Experience working across digital and/or retail products to deliver one integrated brand experience Professional UX Design or Interaction Design experience Proficiency in user flows, wire-framing and interactive prototyping for mobile (iOS, Android, Windows Phone) Solid understanding of iOS and Android mobile design standards and patterns Exceptional written and verbal communication skills and presentation skills Demonstrated ability to build and to maintain strong relationships with cross-functional stakeholders, including Executive Management Excellent collaboration and problem-solving abilities in a team-oriented environment</p>

<p className="careersub">Relocation Information:</p>
<p className="card-text">Relocation assistance is available for this position.</p>

<p className="careersub">Desired Skills and Experience</p>
<p className="card-text">Bachelor’s Degree/equivalent in marketing, communications or related business discipline. Five (5) years work experience in marketing, sales, sales administration or related discipline. Demonstrated knowledge of marketing disciplines and information analysis. Experience in database marketing, telemarketing, promotions, pricing, and financial analysis. Strong human relations, communication, organizational and analytical skills. A related advanced degree may offset the related experience requirements. A related Master’s Degree equals two years experience.</p>
        </div>
        
        
        <div className="col-md-4 career_box">
            <h5 className='careersub'>LOGISTICS ACCOUNT COORDINATOR</h5>
            <p className='careersub'>Experience : 1-3 Years</p>
        <p className="card-text">The Logistics Account Coordinator is responsible for handling a book of business, house accounts, within TIME EXPRESS PARCELS Truckload Brokerage. Works collectively with the customer, the carrier, and other team members to increase sales opportunities and to provide an outstanding customer experience.

You will uphold the Company Values by making every customer experience outstanding.</p>
        <p className="careersub">Position Information:</p>
        <p className="card-text">Selling – Customers and Carrier Group – Handles business for the national and house accounts within TIME EXPRESS PARCELS Truckload Brokerage. Communicates and develops relationships with our customer and carrier relationships to improve our sales opportunities and level of customer service.

· Pricing and quote determination – Maximizes profits through customer and carrier relationships. Establishes quotes based on customer and carrier needs and TIME EXPRESS PARCELS Truckload Brokerage’s goals.

· Analysis – Identifies process improvement or business enhancements to better service the customer while driving down costs.

· Contract completion and monitoring – Facilitates the administrative processes related to contract approval and completion. Dispatches and monitors the shipment in transit and updates the system and customer as needed. Reviews carrier performance and insures overall compliance with standard operating procedures and applicable federal and state requirements. Works to resolve issues as they arise.

· Sales and payment follow up – Contacts customer to obtain additional information to resolve issues related to invoice discrepancies.

· Teamwork – Partner with team members to insure customer needs are met and carrier relationships are maintained.

· Additional job duties as assigned</p>
        <p className="careersub">Qualifications:</p>
        <p className="careersub">Required:</p>
        <p className="card-text">High school diploma or equivalent · One year of experience in sales support or transportation · Strong selling and negotiation skills · Excellent written and verbal communication skills · Working knowledge of computer software applications (MS Office) · Entrepreneurial spirit; strong desire to grow and build business</p>
        <p className="careersub">Preferred:</p>
        <p className="card-text"> Bachelor’s degree · Two years of experience in sales support or transportation This position may require a non-traditional business hours and some weekends.</p>
        
        
         </div>
       </div>
       <div className="row" style={{marginTop:'3%',paddingBottom:'3%'}}>
       <div className="col-md-4 career_box">
            <h5 className='careersub'>FIELD ACCOUNT SALES EXECUTIVE</h5>
            <p className='careersub'>Experience : 0-1 Years</p>
        <p className="card-text">The Field Sale Executive is responsible for prospecting, developing, and growing new accounts and growing TIME EXPRESS PARCELS Office’s existing local market customer base.

Responsibilities (This is a representative list of the general duties the position may be asked to perform, and is not intended to be all-inclusive) Meet or exceed sales quotas in existing accounts and territory Maintain regular contact with designated accounts, per established sales process requirements Schedule and conduct face-to-face sales calls to include telesales and cold calling activity Provide international parcels pricing information to named account base, per established pricing guidelines Create and present value-added business solutions to specific named accounts, within prescribed guidelines Build rapport with customers using a consultative sales approach Serve as the primary relationship owner for accounts in territory to ensure proper service and retention Grow relationships and sales in assigned accounts Build and maintain a pipeline of business at established standards Interact with Sales leadership to develop and review sales strategies for sales accounts and prospects Drive target products and promotions, as directed Work with other sales resources in a team selling capacity to include involvement with other TIME EXPRESS PARCELS operating companies and secondary coverage to national accounts

Desired Skills and Experience Bachelor’s degree or equivalent experience required 2+ years of business to business direct selling experience required Experience within the courier industry, preferred but not required Strong understanding of sales financial targets and quotas with proven successful results Demonstrated success of the consultative selling process Proven organization, planning, administrative, and planning skills Proven skills and aptitude to excel in a customer-focused and results-driven environment Demonstrated business math and intermediate computer skills Experience utilizing Customer Relationship Management (CRM) software programs preferred Presents a personal professional image For new hires, must meet all TIME EXPRESS PARCELS Office employment qualifications in force at time of hiring</p> 
        
        </div>
       </div>
         
        </div>
      </section>

      <section id="tabs">

      </section>






    </>
  )

  function setopen(tab) {

    if (tabpath === tab) { // already displayed

      if (count == 0) {
        setCount(1);
      } else {
        setCount(0)
      }

    } else {
      setCount(0);
      settabpath(tab)
    }
    console.log(tab)
    if (count == 0) {
      axios.get(`http://127.0.0.1:3000/${tab}`)
        .then((res) => sethelp(res.data))
        .catch(err => console.log(err))
      console.log(help)
      return (
        <div className="details">
          <h2>Fuel {tabpath}</h2>
          <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"  >
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              11111111 ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
            </div>

          </div>
        </div>

      )

    }

  }




}





