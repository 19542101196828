import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'


export default function After_payment_subscription() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [searchParams, setSearchParams] = useSearchParams();
    const orderno=searchParams.get("ref")
    //const orderno='f445ff40-a49a-490c-9df4-d2d75471431c'
    const navigate=useNavigate()
   
  useEffect(() => {
   if(!sessionStorage.getItem('userid')){
navigate('/login');
   }else{
   
   }
  }, [])

  const [msg, setmsg] = useState('Please wait. Checking the payment status.')
  const SubscribedAPI = `${SERVER_URL}check_subscription_payement.php`;
useEffect(() => {
  if (orderno) {
    axios({
      method: "post",
      url: `${SubscribedAPI}`,
      data: orderno
    })
      .then((res) => {
       if(res.data==1){
        setmsg('Transaction Successful')
        setTimeout(()=> {
            navigate('/my-account/my-subscriptions');    
        }, 2000); 
       }
       if(res.data==0){
        setmsg('Transaction Failed')
       }
      })
      .catch((err) => console.log(err))
  }
}, [])

return (
    <div>
      <p style={{textAlign:"center"}}>{msg}</p>
     
    </div>
  )
  
}
