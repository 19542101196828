import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import '../../Custom.css'
import banner from '../../images/banners/collect.jpg'
export default function Shop_usa() {
  return (
    <div className="shopusa">
        <Helmet>
        <title>Time Express | Shop USA, Ship UAE</title>
      </Helmet>
       <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">SHOP USA, SHIP UAE</h1>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content">
<div className="become_driver  ship-more">
<div className="border_text">
<h1 style={{display: 'none'}}>Shop USA, Ship UAE</h1>
<h3>Ready to ship? Sign up and start saving now.<Link style={{marginLeft: '20px',textDecoration:"none",borderRadius:"6px"}} to="/send">Get a Quote</Link>
</h3>
<h4>SHOP USA, SHIP UAE</h4>
<p>Shop &amp; Ship What You Love from the USA to your Doorstep</p>
<h4 style={{fontSize: '18px'}}>Shipping Simplified</h4>

<p>• Sign up for a free US address</p>
<p>• Use your US address to shop at your favorite US retailer</p>
<p>• We receive your package at your Timex locker</p>
<p>• Consolidate and ship to your home address</p>
<p>• Receive your shipment within days</p>

<h4 style={{fontSize: '18px'}}>International delivery without the worry</h4>

<p>• Express shipping to over 200 countries</p>
<p>• Packages arrive within as little as three business days</p>
<p>• 30 years of experience in international shipping</p>

<h4 style={{fontSize: '18px'}}>Savings on Purchases and Shipping</h4>

<p>• Shop &amp; Ship without paying US sales tax</p>
<p>• Save up to 80% on shipping consolidated packages</p>
<p>• Discounted shipping with major carriers including DHL Express, FedEx, and US Postal Service</p>

<h4 style={{fontSize: '18px'}}>No Surprise Fees for Shop &amp; Ship</h4>

<p>• No membership or joining fees</p>
<p>• No fees to receive packages</p>
<p>• No storage fee for the first 45 days</p>

<h4>Simplified pricing that takes the worry out of excess fees</h4>
<p>Shop &amp; Ship in the USA and Ship Internationally without the worry</p>
<p>Online shopping for Shop &amp; Ship in the USA should be easy</p>

<p>• Find what you want</p>
<p>• Order</p>
<p>• See your package on your doorstep</p>

<h3 style={{marginTop: '15px', marginBottom: '10px'}}>But then uncertain details make you anxious:</h3>

<p>• “What if they ship the wrong thing?”</p>
<p>• “Will they accept my credit card?”</p>
<p>• “How much is shipping going to cost?”</p>
<p>• Time Express offers a variety of international shipping services with simplified pricing so you can see your package on your doorstep without worrying about excess fees.</p>

<h4>Consolidation: Ship just one package and save</h4>
<p>Combine multiple packages into one box. Consolidating packages saves you up to 80% on international shipping compared to shipping packages individually from retailers.</p>
<h4>Repacking: Don’t pay for wasted space</h4>
<p>Domestic shipping in the USA does not use dimensional weight and there is often wasted space. Save on delivery costs when you repackage your packages into a smaller box.</p>
<h4>Personal Shopping Services: Buy from the Best Stores</h4>
<p>Some retailers will not accept foreign credit cards, or ship to packages internationally—Time Express offers a personal shopping service that allows you to shop with hundreds of your favorite USA retailers.</p>
<h4>Rates</h4>
</div>
<div className="table-responsive">
<table className="table-bordered table-striped table-hover table" style={{tableLayout: 'inherit'}}>
<thead style={{backgroundColor: 'purple', color: '#fff'}}>
<tr>
<th style={{textAlign: 'left'}}>Time Express (Shop USA, Ship UAE)</th>
<th style={{textAlign: 'left'}}>Fees</th>
<th>&nbsp;</th>
</tr>
</thead>
<tbody>
<tr>
<td style={{fontWeight: 'bold'}}>(AED)</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Membership Fee</td>
<td>None</td>
<td>&nbsp;</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Monthly Fee</td>
<td>None</td>
<td>&nbsp;</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Consolidation</td>
<td>10</td>
<td>Per package</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Repacking Fee</td>
<td>25</td>
<td>Per package</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Package Receipt Fee</td>
<td>None</td>
<td>&nbsp;</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Personal Shopping Fee</td>
<td>45</td>
<td>Per online retailer</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>&nbsp;</td>
<td>&nbsp;</td>
<td>10% of the purchase value</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Storage Fee</td>
<td>Free for 45 days</td>
<td>50 fils per day per pound past 45 days</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Hazmat Processing Fee</td>
<td>60</td>
<td>Per hazmat type</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Special Request Fee</td>
<td>10</td>
<td>15 AED for 1 photo, 25 AED for 3 photos</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Shipment Value Protection</td>
<td>20</td>
<td>Min charge</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>&nbsp;</td>
<td>&nbsp;</td>
<td>2% of declared value</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Editing Commercial Invoice Fee</td>
<td>115</td>
<td>Per commercial value</td>
</tr>
<tr>
<td style={{fontWeight: 'bold'}}>Package Return</td>
<td>35</td>
<td>Per pre-paid label</td>
</tr>
</tbody>
</table>
</div>
<p>
<Link to="/contact-time-express" style={{textDecoration:"none",borderRadius:"6px"}}>Contact</Link>
</p>
</div>
</div>
           </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
