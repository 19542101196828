import React from 'react'
import { Helmet } from 'react-helmet'
import banner from '../../images/banners/terms.jpg'
export default function Terms_and_Conditions() {
  return (
    <div>
        <Helmet>
        <title>Time Express | Terms & Conditions</title>
      </Helmet>
    <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
         <div className="container"><div className="row">
             <div className="col-md-12 col-sm-12 your_business text-center">
                 <div className="business_content">
                 <h1 className="mainhead">TERMS & CONDITIONS</h1>
                   
                 </div>
             </div>
         </div>
         </div>
     </section>
     <div className="pages_content">
     <div className="container">
     <div className="row">
     <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
     <main id="main" className="site-main" role="main">
    <div className="entry-content">
    <div className="become_driver">
    <h4>TIME EXPRESS PARCELS DELIVERY LLC</h4>
    <h4>TERMS & CONDITIONS OF CARRIAGE AND OTHER SERVICES</h4>
    <h5>1. DEFINITIONS</h5>
    <p>Time Express Parcels Delivery LLC may perform any of the following activities on Shipper’s behalf in order to provide its services to Shipper, including complete any documents, amend product or service codes, an pay any duties and taxes under laws, act as the Shipper’s forwarding agent for customs and export controls, and redirect the shipment to receivers import broker. ‘We’ ‘Us’ and ‘TIME EXPRESS PARCELS DELIVERY’ means TIME EXPRESS PARCELS DELIVERY LLC and its employees, agents and independent contractors; ‘You’ and ‘Your’ ‘Carriage’ means the sender or consignor; ‘Carriage’ means and includes the whole of the operations and services undertaken by us in connection with the transportation of the shipment;</p>
    <p>‘Other Services’ means all services not being services for the carriage of shipments that are performed by us, including, but not limited to, storage, sorting, kitting, merging, packing, installation, value added- and transportation management services;
‘Shipment’ means goods or documents of whatever nature (whether in bulk or in one or more packages) which we have accepted for carriage from one address to another or regarding which we have accepted to perform other services, whether under our consignment note or not; ‘Prohibited Items’ means any goods or materials the carriage of which any law, rule or regulation of any country in prohibits or over which the shipment travels.</p>
 
    <h5>2. OUR CONTRACTING AGREEEMENT</h5>
    <p>Shipper agrees that its shipment is acceptable for transportation and is deemed unacceptable if it is a prohibited or restricted item or packaging is defective or inadequate. Your contract of carriage and of other services is with the TIME EXPRESS PARCELS DELIVERY LLC company or the subsidiary or affiliate of TIME EXPRESS PARCELS DELIVERY LLC that accepts from you the shipment for carriage or, as the case may be, the performance of other services. You agree that we may subcontract the whole or any part of the contract of carriage or of other services on any terms and conditions we decide.</p>
    <h5>3. YOUR ACCEPTANCE OF OUR TERMS AND CONDITIONS</h5>
    <p>Time Express Parcels Delivery LLC has the right to open and inspect a shipment without notice. By giving us your shipment you accept our terms and conditions set out in the consignment note and/or the contract of carriage and/or the contract for the performance of other services on behalf of yourself and/or anyone else who has an interest in the shipment or the performance of other services irrespective of whether you have signed the front of our consignment note or not. Our terms and conditions also cover and can be invoked by anyone we use or sub-contract to collect, transport, deliver your shipment or perform other services as well as our employees, directors and agents. Only our authorized directors may agree to a variation of these terms and conditions in writing. When you give us the shipment with oral or written instructions that conflict with our terms and conditions we shall not be bound by such instructions.</p>
    <h5>4. SCOPE OF THE CONDITIONS</h5>
    <p>4.1. Even if the carriage of the shipment forms part of another type of contract between you and us, these terms and conditions apply to the contract agreed between you and us in respect of any carriage of goods pursuant to the contract.</p>
    <p>4.2. By concluding any type of contract with us that involves the carriage of goods you agree that the contract is a contract of carriage of goods by air or road as deemed necessary by Time Express Parcels Delivery LLC.</p>
    <h5>5. MATERIALS DANGEROUS AND RESTRICTED FOR TRANSPORT</h5>
    <p>5.1. Except in the circumstances shown in Clauses 5.2 & 5.3, we do not carry, nor perform other services regarding, goods which are, or are in our sole opinion, dangerous goods including, but not limited to, those specified in the International Civil Aviation Organization (ICAO) Technical Instructions, the International Air Transport Association (IATA) Dangerous Goods Regulations, the International Maritime Dangerous Goods (IMDG) code, the European Agreement Concerning the International Carriage of Dangerous Goods by Road (ADR) regulations or any other national or international rules applicable to the transport of, or the performance of other services regarding, dangerous goods.
<br/>5.2. We may at our discretion accept some dangerous goods for carriage, or for the performance of other services, in some countries if you have been accorded the status of an approved customer and this must be given by us in writing before your shipment can be accepted. Your dangerous goods will only be accepted if they comply with the applicable regulations (as referred to in Clause 5.1) and our requirements. Details of our requirements together with the procedure for applying for approved customer status are available from our nearest office and a dangerous goods surcharge will be invoiced to you upon acceptance of your shipment.
<br/>5.3. Certain dangerous goods are exempt from the above approved customer requirements. Details can be obtained from our nearest TIME EXPRESS PARCELS DELIVERY LLC office.
<br/>5.4. You must ensure and you certify by completing our consignment note or tendering a shipment to us that your shipment does not contain a prohibited article as specified in ICAO Annex 17 or other national or international regulations that govern aviation security. You must give us a full description of the contents of the shipment on the consignment note, or other accompanying document, and your responsibilities and liabilities are not extinguished by providing this information.
<br/>5.5. Shipments carried, or handled, by us may be subject to security screening which could include the use of x-ray, explosive trace detection and other security screening methods and you accept that your shipment may be opened and the contents of your shipment may be examined in transit.
<br/>5.6. You declare that you have prepared the shipment for carriage, or for the performance by us of other services, in secure premises using reliable staff employed by you and that the shipment has been safeguarded against unauthorized interference during preparation, storage and transportation immediately prior to acceptance for carriage of the shipment by us or for the performance by us of other services.
<br/>5.7. We do not accept shipments that contain prohibited items.
5.8. We may be required to share information, including your personal data for your shipment with the shipment destination country authorities or transit country authorities for customs and / or security reasons.</p>
    <h5>6. EXPORT CONTROLS AND CONSEQUENTIAL DAMAGES</h5>
    <p>Time Express Parcels Delivery LLC will make every reasonable effort to deliver the Shipment according to regular delivery schedule.
    <br/>6.1. You assume responsibility for and guarantee compliance with all applicable export controls laws, including but not limited to regulations and rules that prohibit unauthorized trade in military and other strategic goods and services with, as well as financial or commercial dealings with named individuals and entities in countries to, from, through or over which your shipment may be carried, or regulations and rules that impose conditions under which certain technologies, information, and commodities can be transported to, from, through or over any country which your shipment may be carried.
    <br/>6.2. You also guarantee that you will not tender any shipment to us if you or any of the parties involved in the shipment are listed on any of the United Nations sanctions programs, regional and national programs implementing and/or supplementing those, as well as parties listed on autonomous measures regulations.
    <br/>6.3. You agree to identify shipments subject to pre-export regulatory controls, and provide us with information and all necessary documentation to comply with applicable regulations.
    <br/>6.4. You are responsible at your expense for determining export and import licensing or permitting requirements for a shipment, obtaining any required licenses and permits, and ensuring that the consignee is authorized by the laws of the origin, destination countries and any country(s) asserting jurisdiction over the goods.
    <br/>6.5. We assume no liability to you or to any other person for your acts of non-compliance with export control laws, sanctions, restrictive measures and embargoes.</p>
    <h5>7. INSPECTION</h5>
    <p>7.1. You agree that we or any governmental authority including customs and security may open and inspect your shipment at any time.</p>
    <h5>8. ROUTING</h5>
    <p>Weekend days, public holidays, bank holidays, delays caused by customs, delays attributable to compliance with mandatory local security requirements or other events beyond our control are not included when we quote door to door delivery times in our published literature. The route and the method by which we transport your shipment shall be at our sole discretion.</p>
    <h5>9. CUSTOMS, EXPORTS & IMPORTS</h5>
    <p>9.1. You appoint us as your agent solely for the purpose of clearing and entering the shipment through customs. If we subcontract this work, you certify that we are the consignee for the purpose of designating a customs broker to perform customs clearances and entries. If any customs authority requires additional documentation for the purpose of confirming the import/export declaration or our customs clearance status it is your responsibility to provide the required documentation at your expense.
    <br/>9.2. You certify that all statements and information you provide relating to the exportation and importation of the shipment will be true and correct. You acknowledge that in the event that you make untrue or fraudulent statements about the shipment or any of its contents you risk a civil claim and/or criminal prosecution the penalties for which include forfeiture and sale of your shipment. To the extent that we may voluntarily assist you in completing the required customs and other formalities such assistance will be rendered at your sole risk. You agree to indemnify us and hold us harmless from any claims that may be brought against us arising from the information you provide to us and any costs we will incur regarding this, and pay any administration fee we may charge you for providing the services described in this condition.
    <br/>9.3. Any customs duties, taxes (including but not limited to VAT if applicable), penalties, storage charges or other expenses we incur as a result of the actions of customs or other governmental authorities or your failure and/or the receiver’s failure to provide proper documentation and/or to obtain the required license or permit will be charged to you or the receiver of the shipment. In the event that we decide to charge the receiver and the receiver refuses to pay the incurred charges you agree to pay them to us together with our fee for the administration involved as well as any extra costs we will incur. Upon our first request you will provide a proper guarantee for any of the duties, taxes, penalties, storage charges or any other expenses set out in this condition.
    <br/>9.4. We will endeavor to expedite all customs clearance formalities for your shipment but are not liable for any delays, losses or damage caused by interference from customs officers or other governmental authorities.</p>
    <h5>10. UNACCEPTABLE SHIPMENTS</h5>
    <p>10.1. If we are unable to deliver a shipment because of an incorrect address we will make all reasonable e orts to find the correct address. We will notify you of the correction and deliver or attempt to deliver the shipment to the correct address although additional charges may apply.
    <br/>10.2. Deliveries to post office box numbers are not accepted except in a limited number of countries (the list of which is available from the subsidiary or a affiliate or branch of TIME EXPRESS PARCELS DELIVERY LLC that accepts your shipment for carriage) and further provided that the telephone number of the consignee is provided. In the event we are unable to deliver the shipment at the first attempt then you agree that we may post the shipment to the consignee and proof of posting is sufficient proof of delivery.</p>
    <h5>11. DELIVERIES AND UNDELIVERABLES</h5>
    <p>11.1. Where we are unable to complete the delivery of a shipment for whatever reason we will try to leave a notice at the receiver’s address stating that delivery has been attempted and the whereabouts of the shipment. If we have not made delivery after a second attempt, or the receiver refuses to accept delivery, we will try to contact you and agree the appropriate next action. You agree to pay us any costs we incur in forwarding, disposing of or returning the shipment and our charges (if any) for making a third or more delivery attempt and for the agreed appropriate next action. If we do not receive your or receiver’s instructions within a reasonable period after our second attempt to deliver the shipment, then you agree that we may destroy or sell the content of the shipment without any further liability to you. Special Delivery Instructions
    <br/>11.2. You or the receiver of a shipment may give special instructions to us (by means of a TIME EXPRESS PARCELS DELIVERY LLC website or any other medium) to deliver the shipment to another location/person (being for example a neighbor and/or neighboring address) or the receiver may indicate its wish to collect the shipment from a location approved by us. Where you request and we agree to enable this special delivery instructions service, the following provisions shall apply:
    <br/>11.2.1. Our furnishing of any delivery receipt upon which is listed the alternative person and/or delivery location shall constitute proof of delivery of the shipment;
    <br/>11.2.2. We shall not be liable for any loss or damage in any way as a result of our performance of your special delivery instructions;
    <br/>11.2.3. You shall indemnify us and hold us harmless from and against all claims, costs, liability and expenses (including reasonable lawyers fees and expenses) arising by reason of loss or damage to any shipments as a result of providing this special delivery instructions service. We also reserve the right to charge you an administration fee for providing this special delivery instructions service.</p>
    <h5>12. YOUR OBLIGATIONS</h5>
    <p>You warrant, represent and guarantee to us that:
    <br/>12.1. The contents of the shipment (including but not limited to weight and number of items) have been properly described on our consignment note, have been correctly labeled and the label or labels have been securely fixed by you in a prominent position on the outer surface of the shipment that can be clearly seen by us;
    <br/>12.2. The consignee’s contact details have been fully, accurately and legibly entered on our consignment note and on an address label securely fixed by you to a prominent position on the outer surface of the shipment that can be clearly seen by us;
    <br/>12.3. The contents of the shipment have been prepared and packed safely and carefully by you to protect against the ordinary risks of transport, or the performance by us of other services, including any associated sortation and/or handling process;
    <br/>12.4. You have declared the correct weight of the shipment and you will provide any special equipment we may need to load or unload the shipment on or o our vehicles;
    <br/>12.5. You have securely fixed a heavy weight label in a prominent position on the outer surface of the shipment that can clearly be seen by us for any item weighing 30 kilos or more;
    <br/>12.6. The contents of the shipment are not restricted by IATA, ICAO, IMDG or ADR and are not prohibited items, and neither you nor the consignee is a person or organization with whom we or you may not legally trade under any applicable laws or regulations;
    <br/>12.7. When you have asked us to charge the receiver or a third party and the receiver or third party does not pay us you will promptly settle our invoice together with an administration fee in full within 7 days of us sending you the invoice;
    <br/>12.8. All applicable laws and regulations have been complied with;
    <br/>12.9. You have included in the shipment the correct commercial invoice related to the shipment (mentioning correct “bill to” address with applicable VAT number, correct and clear description of the commodity, and the first 6 digits of the Harmonized System (“HS”) code;
    <br/>12.10. That you have taken all reasonable precautions to comply with all conventions, directives and legislation relating to the protection of personal data including if practicable encryption of the personal data to ensure the safety of the personal data in the event of loss or miss-delivery of a shipment;
    <br/>12.11. The value of any shipment does not exceed 2,500 Dhs. You agree to indemnify us and hold us harmless from any liabilities we may suffer or any costs, damages or expenses, including legal costs, we incur either to you or to anyone else arising out of you being in breach of any of these warranties, representations and guarantees, even if we inadvertently accept a shipment that contravenes any of your obligations.</p>
    <h5>13. OUR LIABILITY EXTENT</h5>
    <p>Claims are limited to one claim per shipment settlement, liability for Transportation Services are;
    <br/>13.1. Subject to Clause 14 below, we limit our liability for any loss, damage or delay of your shipment or any part of it arising from carriage as follows:
    <br/>13.1.1. If the carriage of your shipment is solely or partly by air and involves an ultimate destination or a stop in a country other than the country of departure the Warsaw Convention (1929), or the Warsaw Convention as amended by the Hague Protocol (1955) and/or Montreal Protocol No. 4 (1975), or the Montreal Convention (1999), whichever is compulsorily applicable, will apply. These international treaties govern and limit our liability for loss, damage or delay to your shipment to 19 special drawing rights per kilo.
    <br/>13.1.2.1 If we carry your shipment by road within, to or from a country that is a party to the convention on the contract for the international carriage of goods by road 1956 (CMR) our liability for loss or damage to your shipment shall be governed by the CMR and thus limited to 8.33 special drawing rights per kilo . In the case of delay where you can show to us you have suffered loss our liability is limited to refunding to you the charge you paid us for carriage in respect of that shipment or the part which was delayed.
    <br/>13.1.2.2. If we carry your shipment by road within a country that is not party to the CMR or between two countries neither of which is a party to the CMR, our liability for loss, damage or delay to your shipment shall be deemed to be governed by the CMR and thus limited to 8.33 special drawing rights per kilo subject to a maximum limit of 100 Dirhams per shipment. In the case of delay where you can show to us you have suffered loss our liability is limited to refunding to you the charge you paid us for carriage in respect of that shipment or the part which was delayed.
    <br/>13.1.2.3. If none of Clauses 13.1.1 – 13.1.3 above apply and we have a liability to you for whatever reason for transportation services performed by us, including without limitation breach of contract, negligence, willful act or default, our liability to you for loss, damage, miss-delivery or non-delivery of your shipment or the part affected is at all times limited to the lower of the market value of the shipment at the time of carriage or the cost of repairing the shipment or the part affected with in each case an upper limit that does not exceed 17 Dirhams per kilo limited to a maximum of 100 Dirhams per shipment. In the case of delay where you can show to us you have suffered loss our liability is limited to refunding to you the charge you paid us for carriage in respect of that shipment or the part which was delayed. Liability for Other Services
    <br/>13.2. Subject to Clause 14 below, if we have a liability relating to other services for whatever reason, including without limitation breach of contract, negligence, willful act or default, our liability to you is at all times limited to 100 Dirhams per event or series of events with one and the same cause of damage or, in case of the loss of or damage to a shipment, to the lower of the market value of the shipment or the cost of repairing the shipment or the part affected with in every case an upper limit that does not exceed 3.40 Dirhams per kilo with a maximum of 100 Dirhams per event or series of connected events.</p>
    <h5>14. EXCLUSIONS OF LIABILITY</h5>
    <p>14.1. We will not be liable for any loss of income, loss of profits, loss of markets, loss of reputation, loss of customers, loss of use, loss of an opportunity even if we had knowledge that such damages or loss might arise or for any indirect, incidental, special or consequential damages or loss howsoever arising including without limitation breach of contract, negligence, willful act or default.

    <br/>14.2. We are not liable if we do not fulfill any obligations towards you at all as a result of:

14.2.1. Circumstances beyond our control such as (but not limited to):<br/>• acts of god including earthquakes, cyclones, storms, flooding, fire, disease, fog, snow or frost;

<br/>• Force majeure including (but not limited to) war, accidents, acts of public enemies, strikes, embargoes, perils of the air, local disputes or civil commotions;

<br/>• National or local disruptions in air or ground transportation networks and mechanical problems to modes of transport or machinery;

<br/>• Latent defects or inherent vice in the contents of the shipment;

<br/>• Criminal acts of third parties such as theft and arson.

14.2.2. Your acts or omissions or those of third parties such as:

<br/>• you being in breach of (or any other party claiming an interest in the shipment causing you to breach) your obligations under these terms and conditions and in particular those warranties set out in Clause 12;

<br/>• An act or omission of any customs, security, airline, airport or government official.

14.2.3. The contents of the shipment consisting of any article that is a prohibited item even though we may have accepted the shipment by mistake.

14.2.4. Our refusal to make any illegal payments on your behalf.

<br/>14.3. We are not a common carrier and do not accept any liabilities of a common carrier.</p>
    <h5>15. TIME GUARANTEED PRODUCTS</h5>
    <p>If we fail to deliver time guaranteed products (that we may offer and that you order) within the time specified and if our failure was not caused by any events set out in Clause 14.2 and if you notify us of your claim in compliance with Clause 19, we will charge you for the actual delivery service provided (e.g. before noon) rather than charging the price we quoted for the service you asked for (e.g. before 9 am), within the same product category as the service you ordered.</p>
    <h5>16. VALUABLE GOODS</h5>
    <p>Valuable goods such as precious stones, precious metals, jewelry, money, negotiable instruments, unprotected furniture, glass or china, objects of art, antiques and important documents that include passports, tenders, share and option certificates should not be sent through our network delivery system because it involves the use of mechanical handling and automated sortation equipment together with multiple transshipments on and of vehicles which could result in loss and/or damage. If you nevertheless send, or cause us to send, such goods through our network delivery system it shall be at your own risk.</p>
    <h5>17. ENHANCED LIABILITY</h5>
    <p>17.1. We will charge you a fee as indicated in our tariff to extend the limits laid down in Clause 13, unless specifically opted out by you. Compensation for proved loss or damage to your shipment may be claimed up to the total amount of the above declared value or 250 Dirhams whichever is lower. Enhanced Liability is not available for precious stones, precious metals, laptop computers, plasma and LCD screens, jewelry, money, glass, china, objects of art, antiques, documents (other than the reconstitution cost as provided for in Clause 17.2 below) or any films, tapes, discs, memory cards or any such other data or image carrying goods. If you do send such goods we recommend that you arrange insurance yourself.

    <br/>17.2. You may opt for Enhanced Liability for the reconstruction, reproducing, reissuing or re-printing cost (including the costs of the materials (e.g. paper) plus reasonable labor costs) of your document shipment by completing the relevant box on the consignment note and paying the indicated charge. Compensation for proved loss or damage to your document shipment might be claimed up to a maximum 500 Dirhams per shipment. This Enhanced Liability option is only available for documents which are listed on the website of the subsidiary or affiliate or branch of TIME EXPRESS PARCELS DELIVERY LLC that accepts your shipment for carriage.

    <br/>17.3. The Enhanced Liability options provided by this Clause 17
(i) do not provide compensation for losses of a consequential nature (see Clause

13.1 above) or delays in carriage or where the loss has arisen as a result of your breach of your obligations under these terms and conditions,
(ii) are not available for non-carriage services and
(iii) are not available for a limited number of countries. For a list of these countries, and/or to obtain further details on the conditions of Enhanced Liability, please contact our customer s
ervice center or visit the website of the subsidiary or affiliate or branch of TIME EXPRESS PARCELS DELIVERY LLC that accepts your shipment for carriage.</p>
    <h5>18. CLAIMS BROUGHT BY THIRD PARTIES</h5>
    <p>You undertake to us that you shall not permit any other person who has an interest in the shipment to bring a claim or action against us even though we may have been negligent or in default and if a claim or action is made you will indemnify us against the consequences of the claim or action and the costs and expenses we incur in defending it.</p>
    <h5>19. TIME LIMIT FOR CLAIMS</h5>
    <p>If you wish to claim for a lost, damaged or delayed shipment, or for any other damages, you must comply with any applicable convention and with the following procedure otherwise we reserve the right to reject your claim:

    <br/>19.1. You must notify us in writing about the loss, damage or delay within 7 days (i) after delivery of the shipment, (ii) from the date the shipment should have been delivered or (iii) from the date you reasonably should have become aware of the loss, damage or delay in the event the claim relates to other services.

    <br/>19.2. you must document your claim by sending us all relevant information about the shipment and/or the loss, damage or delay suffered within 21 days of notifying us of your claim.

    <br/>19.3. We are not obliged to act on any claim until our charges have been paid nor are you entitled to deduct the amount of your claim from our charges;

    <br/>19.4. We will assume the shipment was delivered in good condition unless the receiver has noted any damage on our delivery record when he or she accepted the shipment. In order for us to consider a claim for damage, the contents of your shipment and the original packaging must be made available to us for inspection;

    <br/>19.5. save as otherwise provided by any applicable convention and or law, your right to claim damages against us shall be extinguished unless an action is brought in a court of law within 1 year from the date of delivery of the shipment or from the date on which the shipment should have been delivered or from the date on which the carriage ended or if the claim relates to other services within 1 year from the date you ought reasonably to have become aware of the loss, damage or delay;

    <br/>19.6. in case of acceptance by us of part or all of your claim, you warrant to us that your insurers or any other third party having an interest in the shipment shall have waived any rights, remedies or relief to which they might become entitled by subrogation or otherwise;

    <br/>19.7. the shipment shall not be deemed to be lost until at least 30 days have elapsed since the date you notified us of the non delivery. We may agree with you in writing to shorten this period.
    <br/>19.8. In case of refunds, it will be made onto the original mode of payment and will be processed within 10 to 45 days 
depends on the issuing bank of the credit card.</p>
    <h5>20. SHIPMENT CHARGES</h5>
    <p>Time Express Parcels Delivery LLC charges are calculated according to higher of actual or volumetric weight and any shipment may be re-weighted and re-measured by us to confirm this calculation.

    <br/>20.1. You agree to pay our charges (including applicable surcharges) as per our calculations for the carriage of the shipment between the locations specified on the consignment note/contract of carriage, or for the performance by us of other services, and any value added taxes within 7 days from the date of our invoice without withholding, deduction, counterclaim or set off.

    <br/>20.2. You waive all your rights to challenge our calculations and invoices, if you do not contest our invoice in writing within 7 days from the date of the invoice./

<br/>20.3. Our charges are calculated in accordance with the rates applicable to your shipment as set out in our current rate card or in the relevant contract. Our current rate card is available on request from any of our offices in the country from which the shipment is invoiced.

<br/>20.4. We charge for either the actual weight of the shipment or the volumetric weight of the shipment whichever is the higher and the volumetric weight is calculated in accordance with the volumetric conversion equation set out in our rate card. We may check the weight and/or volume of and/or the number of items within your shipment and if we find that there is a discrepancy between your declared weight and/or volume and/or number of items you agree that the weight and/or volume and/or the number of items that we determine may be used for the purpose of our calculation.

<br/>20.5. As a matter of course all import duties, value added taxes on goods and all other charges levied on the shipment in the destination country shall be payable to us by the receiver upon delivery of the shipment and if the receiver refuses to pay you agree to pay us these amounts in full within 7 days of us notifying you that the receiver has not paid.

<br/>20.6. You agree that we may charge penalty on all invoices not paid within 7 days from the invoice date at the rate of 6% on all amounts due towards the relevant invoice. The exercise of such right by TIME EXPRESS PARCELS DELIVERY LLC shall however be without prejudice to its other rights or remedies under applicable law.

<br/>20.7. The door to door delivery rates shown on our current rate card include provision for simple customs clearance formalities and we reserve the right to charge an extra administration fee where time-consuming excessive customs clearance work is needed to enable us to deliver your shipment to the receiver. Additional charges may therefore be applied in some countries for complex customs clearance activities and these include but are not limited to shipments that require:

<br/>20.7.1. Formal customs entries involving more than three different commodities;

<br/>20.7.2. Customs bonds or the need to deliver goods under a customs bond;

<br/>20.7.3. Temporary import facilities;

<br/>20.7.4. Clearances involving a government department other than the customs authority. We may in some countries make advance payments of import duty, taxes, penalties or have to post bond on behalf of the importer and where this additional service is provided a local administration fee will be charged to the receiver and you will be liable for this charge if the receiver does not pay us.

<br/>20.8. You may give us special invoicing instructions or agree with the receiver of the shipment or another third party that he or she will pay our charges and/or any duties, taxes, penalties, bonds, assessments, expenses, surcharges and fines levied or incurred by us in connection with the shipment. If the receiver or other third party refuses to pay our charges for the carriage or reimburse us for any of the above costs you agree to pay these amounts within 7 days of us notifying you of the refusal to pay.

<br/>20.9. Our invoice does not include a copy of the Proof of Delivery (POD), which you agree may be validly obtained or provided in a digital or electronic format, or any other additional documents.

<br/>20.10. Where permitted by law, our standard invoice presentation method is electronic invoicing. Where you request, or we are required, to use paper invoicing we reserve the right to charge you an administration fee for providing this service.

<br/>20.11. Our invoices must be paid in the currency stated in the invoice or otherwise in a local currency against exchange rates provided by us.

<br/>20.12. We have a general lien on all your shipments in our possession at any one time that gives us the right to sell the contents and retain the proceeds of sale in settlement of any amounts that you may owe us.

<br/>20.13. You shall be liable for the payment of all duties, taxes and charges including stamp duties as applicable on the carriage and other services as well as on all documents including the consignment note.</p>
    <h5>21. INDEMNITY</h5>
    <p>Time Express Parcels Delivery LLC is not liable for any loss or damage arising out of circumstances beyond its control.

    <br/>21.1. You agree to indemnify us against and keep us harmless from all costs, claims, liabilities and demands of any nature arising directly or indirectly from the redundancy selective re-employment or transfer of any of:

21.1.1. Our employees assigned or solely dedicated to the services we provide to you; or 21.1.2. Employee or former employee of yours; or 21.1.3. Any supplier or former supplier to you; or 21.1.4. Any third partywhich may in any way arise from the commercial relationship between us and you including but not limited to any liability arising under the European Community Acquired Rights Directive (77/187/EEC, as amended by Directive 2001/23/EC) or national implementing legislation thereof or under any other applicable employment legislation.</p>
    <h5>22. GOVERNING LAW</h5>
    <p>The invalidity or enforceability of any provision shall not affect any or other part of these terms and conditions.
    <br/>22.1. In the event that any term or condition is declared invalid or unenforceable such a determination shall not affect the other provisions of this contract all of which remain in force.
    <br/>22.2. Save as provided by any applicable convention, disputes arising from or related to this contract shall be subject to the laws and the courts of the United Arab Emirates.</p>
    
</div>
</div>
</main>
</div>
</div>
</div>
</div>
</div>
  )
}
