import axios from 'axios';
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function Subscription() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const location = useLocation();
    const url=window.location.href
    const plan = url.split("/").pop();
    const userid = sessionStorage.getItem('userid');
     React.useEffect(() => {
        const API_Plan=`${SERVER_URL}subscribe_plan.php`;
        axios({
         method: 'post',
         url: `${API_Plan}`,
         data: {userid:userid,plan:plan}
       })
         .then((res) => {
console.log(res.data)
    window.location.replace(res.data)
         }).catch((err)=>console.log(err))
    //     window.location.replace(`${location.state.payment_page}`)
       }, [])


    //    useEffect(() => {
    //     subscribeplan();
    // }, []);

  
    
   
    // const subscribeplan = async () => {
    //     const API_PATH = `${SERVER_URL}subscribe_plan.php`;
    //     const passval = {
    //         userid : userid,
    //         plan:plan
    //     };

    //     await axios
    //         .post(API_PATH, passval)
    //         .then(res => {
    //            console.log('here')
    //              console.log(res.data)
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }

  return (
    <div>
      <p style={{textAlign:"center"}}>You will be redirected to payment screen.</p>
     
    </div>
  )
}
