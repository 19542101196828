import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'
import { country_code_array } from '../functions';
import { Helmet } from 'react-helmet';
export default function Settings() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const navigate = useNavigate()
    const userid = sessionStorage.getItem('userid')
    const [countries, setcountries] = useState(country_code_array);
    const [collectionDetails, setcollectionDetails] = useState({
        country:'AE',
        user_email:'',
        display_name:'',
        phone:''
     
    })
    const [personaldetails, setpersonaldetails] = useState({
        user_email:'',
        display_name:'',
        phone:''
    })
    const [active, setactive] = useState('personal')
    const [errormsg, seterrormsg] = useState('')
    const [errorcount, seterrorcount] = useState(0)
    const [updatemsg, setupdatemsg] = useState()
  function setactivefun(val){
    if(active==val){
        setactive('')
    }else{
        setactive(val)
    }
    
  }

  const addresspath=`${SERVER_URL}get_personaldetails.php?type=getdata`;
    useEffect(()=>{
        axios({
          method:"post",
          url:`${addresspath}`,
          data:userid
        })
        .then((res)=>{
            console.log(res.data)
            setcollectionDetails({...collectionDetails,display_name:res.data.details.display_name,user_email:res.data.details.user_email,phone:res.data.details.phone,user_pass:res.data.details.user_pass})
         
        })
        .catch((err)=>console.log(err))
        },[])

      function checkpassword(newpass){
        if(collectionDetails.user_pass!=newpass){
           
            seterrormsg('password mismatch')
            seterrorcount(1);
        }else{
            
            seterrormsg('')
            seterrorcount(0);
            setcollectionDetails({...collectionDetails,confirmpass:newpass})
        }

      }

      function submitDetails(){
        if(errorcount==1){
           //console.log(errormsg)
            console.log('unable to process')
        }else{
            const Apipath=`${SERVER_URL}get_personaldetails.php?type=update`;
       
            axios({
                method:"post",
                url:`${Apipath}`,
                data:{userid:userid,collectionDetails:collectionDetails}
              })
              .then((res)=>{
                if(res.data=='updated'){
                    setupdatemsg('Details updated successfully!')
                }
              
                //navigate('/my-account/settings')
              })
              .catch((err)=>console.log(err))
            
        }
        
      }
    return (


        <div className="wallet-plan addressbook">
            <Helmet>
        <title>Time Express | Settings</title>
      </Helmet>
            <div className="container myparctab" >

                <div className="container-fluid my_quote">
                    <div className="row">

                        <Sidebar />
                        <div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">

                            {/* Add Collection Address */}

                         
                            <h3>MY DETAILS</h3>
                            <div  className="col-md-12 col-sm-12 col-xs-12" style={{border: "1px solid #c3bbbb", marginTop: "2%", borderRadius: "6px" }}>
                            <h3  onClick={()=>setactivefun('personal')} className="panel-title comaprison_hedding" style={{ padding: "2%", background: "#f5f5f5" }}>Personal Details</h3>

                               {active=='personal'?(
                                <>
                               
                              
                                    <div className="row" style={{ padding:"3%" }}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Full Name<span className="required">*</span></label>
                                                <input type="text" className="form-control" value={collectionDetails.display_name} name="collection_name" onChange={(e) => setcollectionDetails({ ...collectionDetails, display_name: e.target.value })} id="collection_name" maxLength={50}/>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Phone<span className="required">*</span></label>
                                                <div className="input-group new-group">
                                                    <div className="input-group-addon" style={{ paddingTop: "6%" }}></div>
                                                    <input type="text" value={collectionDetails.phone} className="form-control" name="collection_phone" id="collection_phone" onChange={(e) => setcollectionDetails({ ...collectionDetails, phone: e.target.value })} placeholder="Phone" style={{ margin: 0 }} />
                                                    <input type="hidden" name="collection_phone_code" id="collection_phone_code" defaultValue="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Email<span className="required">*</span></label>
                                                <input type="text" className="form-control" value={collectionDetails.user_email} name="collection_email" onChange={(e) => setcollectionDetails({ ...collectionDetails, user_email: e.target.value })} id="collection_email" placeholder="Email" />
                                            </div>
                                        </div>
                                       
                                    </div>

                                    
                                  
                              
                                </>
                               ):null}
                                
                            </div>
                         
                           
                         
                            <div  className="col-md-12 col-sm-12 col-xs-12" style={{border: "1px solid #c3bbbb", marginTop: "2%", borderRadius: "6px" }}>
                            <h3 onClick={()=>setactivefun('password')} className="panel-title comaprison_hedding" style={{ padding: "2%", background: "#f5f5f5" }}>Change Password</h3>

                            {active=='password'?(
                                <>
                               

                                <form>
                                <div className="row" style={{ padding:"3%" }}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">New Password</label>
                                                <input type="text" className="form-control" name="collection_address" onChange={(e) => setcollectionDetails({ ...collectionDetails, user_pass: e.target.value })} id="collection_address" placeholder="Password" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Confirm New Password</label>
                                                <input type="text" className="form-control" name="collection_address" onChange={(e) => checkpassword(e.target.value)} id="collection_address" placeholder="Confirm New Password" />
                                            </div>
                                            <p style={{color:"red"}}>{errormsg}</p>
                                        </div>
                                      

        
                                    </div>

                                    
                                  
                                </form>
                                </>
                            ):null}
                            </div>

                            <div className="col-md-12 col-sm-12 text-right" > 
                                    <input type="button" defaultValue="Submit" onClick={submitDetails} name="save" className="btn btn-success pull-right" id="save" style={{float:"right",margin:"3%"}} />
                                    </div>
                                    <p style={{color:"green",fontWeight:"500"}}>{updatemsg}</p>

                            <div onClick={()=>setactivefun('address')} className="col-md-12 col-sm-12 col-xs-12" style={{display:"none",border: "1px solid #c3bbbb", marginTop: "2%", borderRadius: "6px" }}>
                            <h3 className="panel-title comaprison_hedding" style={{ padding: "2%", background: "#f5f5f5" }}>Address Information</h3>

                            {active=='address'?(
                                <>
                               

                                <form>
                                <div className="row" style={{ marginLeft: "15px", marginRight: "15px" }}>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Address</label>
                                                <input type="text" className="form-control" name="collection_address" onChange={(e) => setcollectionDetails({ ...collectionDetails, address: e.target.value })} id="collection_address" placeholder="Address" />
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">City/Town</label>
                                                        <input type="text" className="form-control" name="collection_city" onChange={(e) => setcollectionDetails({ ...collectionDetails, city: e.target.value })} id="collection_city" placeholder="City/Town" />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Country<span className="required">*</span></label>

                                                        <select className="form-select select-export-package" name="delivery_to" onChange={(e) => setcollectionDetails({ ...collectionDetails, country: e.target.value })}>
                                                            {

                                                                Object.entries(countries).map(([k, v]) => (
                                                                    <option key={k} value={k} >{v}</option>
                                                                ))
                                                            }

                                                        </select>

                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Zipcode</label>
                                                        <input type="text" className="form-control cd" name="collection_zipcode" onChange={(e) => setcollectionDetails({ ...collectionDetails, zipcode: e.target.value })} id="collection_zipcode" placeholder="Zipcode" />
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-12 text-right" > 
                                    <input type="button" defaultValue="Submit" name="save" className="btn btn-success pull-right" id="save" style={{float:"right",margin:"3%"}} />
                                    </div>
                                    </div>

                                    
                                  
                                </form>
                                </>
                            ):null}
                            </div>

                          

                        </div>



</div>










                    </div>


                </div>
            </div>

        

            


    )
}
