import React from 'react'
import Homebanner from './Home/Homebanner'
const Mystyle={
    backgroundImage:  `url('${process.env.PUBLIC_URL}/banner.png')`,
    // minHeight:"1000px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginTop:"9%"
}
export default function HeaderBanner() {
  return (
    <section className="headernew" style={Mystyle}>
    <h1>Company Name</h1>
    <p>The Cheapest Parcel Delivery In The UAE.</p>
    {/* <section className="home_send_section"> */}
    <Homebanner/>
       
        
</section>
  )
}
