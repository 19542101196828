import React from 'react'
import shopify from '../../../images/shopify.png'
import woo from '../../../images/wooco.png'
import banner from '../../../images/banners/sme.jpg'
import { Helmet } from 'react-helmet'
export default function SMEs() {
  return (
    <div>
      <Helmet>
        <title>Time Express | SME'S</title>
      </Helmet>
            <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
              <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">SME'S</h1>
                            <p>We're here to help</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
           <h4>BUILD ON YOUR SUCCESS</h4>
           <h4>INSTANT INTEGRATION INTO SHOPIFY & WOOCOMMERCE</h4>
           <h4>TAKE THE HASSTLE OUT OF SHIPPING</h4>
           <p>Have you build your merchant store using Shopify and Woo Commerce? Download the Time Express App from the Shopify and Woo Commerce Store to integrate right away and have Time Express as your logistics partner at checkout page.</p>
           <p>By using Shopify and WooCommerce with Time Express integration use one platform to sell products to anyone, anywhere at lower courier rates —in person with Point of Sale and online through your website, social media, and online marketplaces.</p>
           <p>Looking to build your website? Shopify powers over 1,000,000 businesses worldwide, it is an all-in-one commerce platform to start, run, and grow a business. WooCommerce is an open-source e-commerce plugin for WordPress. It is designed for small to large-sized online merchants using WordPress</p>
        <div className="row">
           <div className="col-md-3">
           <img src={shopify} style={{width:"80%"}}/>
           </div>
           <div className="col-md-3">
           <img src={woo} style={{width:"80%"}}/>
           </div>
        </div>
        <h4>TAP INTO NEW MARKETS</h4>
        <h4>OPEN YOURSELF UP TO TENS OF MILLIONS OF ADDITIONAL POTENTIAL CUSTOMERS</h4>
      <p>The days of only being able to sell products to customers in one town, region, or country are long gone as businesses of all sizes can easily export their products across the World. We have designed a range of delivery and distribution services to help you provide the tools and services that make it as easy for small businesses to sell abroad than in your home country as well as export at the lowest possible international parcels rates. We partner with you to expand your horizons by offering your products on regional and Global basis. We Make Shipping Simple & Easy. Online Shopping is Limited to Nothing, Let the World be Your Market Place.</p>
    
    </div>
    </div>
    </main>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
