import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import banner from '../../images/banners/bulk2.jpg'
import { useLocation } from "react-router-dom"
import logo from '../../images/logo-purple.png'
import { country_code_array } from './functions';
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
export default function Bulk_awblabel() {
    sessionStorage.removeItem('batch_id');
    const location = useLocation();
    const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [created, setcreated] = useState('');
    let newDate = new Date()
    let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
    const today=`${year}/${month}/${date}`
    const [countries, setcountries] = useState(country_code_array);
    const ref = useRef();
    const [parceldetailscount, setparceldetailscount] = useState(0)
    const [parceldetailscontent, setparceldetailscontent] = useState('')
    useEffect(()=>{
        const API_PATH2 = `${SERVER_URL}get_batch_order_details.php`;
        const data= new FormData();
        data.append('batch_id',location.state.batch_id);
        data.append("userid", location.state.userid);
        data.append("current", "");
      
        axios.post(API_PATH2,data,{})
          .then((res) => {
            console.log(res.data.response_arr)
            setparceldetailscontent(res.data.response_arr)
  setparceldetailscount(res.data.response_arr.length)


  res.data.response_arr.forEach(orders => {
    send_label(orders.orderid)
   
  });

          })
          .catch((err) => console.log(err))
    },[])
   
    function send_label(orderids) {
      console.log('email funct ', orderids)
      const API_email = `${INTEGRA_URL}label_email.php`;
      axios({
        method: 'post',
        url: `${API_email}`,
        data: orderids
      })
        .then((res) => {
          console.log(res.data)
          //navigate(res.data)
  
  
        })
        .catch((err) => console.log(err));
    }
    return(
        <div className="awblabel ">
            <Helmet>
                <title>Time Express | Bulk Order Confirmation</title>
            </Helmet>

            <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 your_business text-center">
                            <div className="business_content">                    
                                <h1 className='h1-title'>BULK ORDER DETAIL</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <style type="text/css" media="print" dangerouslySetInnerHTML={{__html: "\n  @page { size: landscape; }\n   @page { margin: 0; size: A4;}\n   @media print {\n  footer {\n    position: fixed;\n    bottom: 0;\n  }\n\n  .content-block, p {\n    page-break-inside: avoid;\n  }\n\n  html, body {\n    width: 210mm;\n    height: 297mm;\n  }\n}\n" }} />
   
            <div className="servies-content become_driver last_order thank-you-page parsel_container pages_content" >
<p className="ss" style={{textAlign: 'left'}}>
Please print the label and attach to your package, our driver will come shortly to collect your parcel. A PDF copy of your label is also available to download from your confirmation email.</p>

         

            <ReactToPrint content={() => ref.current}>
        <PrintContextConsumer>
          {({ handlePrint }) => (
            <button onClick={handlePrint} className="btn btn-small" style={{marginBottom:"2%",marginTop:"2%",background:"#7f3f98",color:"#fff",minWidth:"100px"}}>Print</button>
          )}
        </PrintContextConsumer>
      </ReactToPrint>
      <div className="table-responsive " ref={ref}>
            {
                             [...Array(parceldetailscount)].map((e,i)=><>{
                                parceldetailscount && parceldetailscount[i]!=''?(
                                 [...Array(parceldetailscontent[i]['parcelcount'])].map((e,k)=><>
                                 
                                 {
                             
<table id="" key={i} className="table-bordered table-striped table-hover table product_account awbprint" cellPadding={5} cellSpacing={0} border={1} width="100%" style={{fontFamily: 'arial', margin: 'auto'}}>
<thead>
          <tr>
            <th style={{textAlign:"center",verticalAlign:"middle"}}><img src={logo} alt="logo"/></th>
            <th>PRODUCT<br /><span>{parceldetailscontent[i]['parcel_type']=='package'?'XPS':'DOX'}</span></th>
            {parceldetailscontent[i]['delivery_from'] == 'AUH' || parceldetailscontent[i]['delivery_from'] == 'DXB' ?(
              <>
<th>ORIGIN  <br /><span>{parceldetailscontent[i]['delivery_from']} </span></th>
            <th>DESTINATION  <br /><span>{parceldetailscontent[i]['delivery_to']}</span></th>
</>
            ):
            (
<>
<th>ORIGIN  <br /><span>{countries[`${parceldetailscontent[i]['delivery_from']}`]}</span></th>
            <th>DESTINATION  <br /><span>{countries[`${parceldetailscontent[i]['delivery_to']}`]}</span>
            </th>
</>
            )}
            
            <th>WEIGHT <br /><span> {parceldetailscontent[i]['final_weight']}</span></th>
            <th>NO OF PCS <br /><span>{k+1}/{parceldetailscontent[i]['parcelcount']}</span></th>
          
          </tr>
        </thead> 
        <tbody>
           <tr>
            <td colSpan={1}>
            <tr>
                <td>Account: 11000 </td>
            </tr>
            </td>
            <td colSpan={2}>
            <tr>
                <td >Date:{today}</td>
            </tr>
            </td>
            <td colSpan={2} >
            <tr>
             
                <td colSpan={3}>AMT: {parceldetailscontent[i]['total_price']} AED</td>
               
            </tr>
            </td>
            <td colSpan={2} >
            <tr>
                <td colSpan={3}> <td>SERVICE: NOR</td></td>
               
            </tr>
            </td>
           
           </tr>

           <tr>
        <td colSpan={3}>
              <h3>FROM</h3>
              <address>
                <p>{parceldetailscontent[i]['cname']}</p>
                <p>{parceldetailscontent[i]['caddress']}</p>
                <p>{parceldetailscontent[i]['ctown']},{parceldetailscontent[i]['czipcode']}</p>
                <p>Phone: {parceldetailscontent[i]['cphone']}</p>
              </address>
            </td>
            <td colSpan={3} align="center">
              {/* <figure><img src={codebar} style={{marginRight: '-17%'}} /></figure> */}
              <p>TYPE OF ORDER: DELIVERY</p>
              <p>PAYMENT TYPE: {parceldetailscontent[i]['pay_method']}</p>
<img src={`data:image/png;base64,${parceldetailscontent[i]['barcode']}`}/>
            </td>
          </tr>

          <tr>
            <td colSpan={3} rowSpan={3}>
              <h3>To</h3>
              <address>
              <p>{parceldetailscontent[i]['dname']}</p>
                <p>{parceldetailscontent[i]['daddress']}</p>
                <p>{parceldetailscontent[i]['dtown']},{parceldetailscontent[i]['dzipcode']}</p>
                <p>Phone: {parceldetailscontent[i]['dphone']}</p>
              </address>
            </td>
            {/* <td colSpan={3}>DO REF# /ORDER REF# <br />32323332</td> */}
           
<td colSpan={3}>DO REF# /ORDER REF# <br />/ {parceldetailscontent[i]['awbno']}</td>
           
          </tr>
          <tr>
            <td colSpan={3}>DESCRIPTION: {parceldetailscontent[i]['parcel_content']}</td>
          </tr>

          <tr>
            <td colSpan={3} width="50%">VALUE FOR CUSTOMER:  {parceldetailscontent[i]['value']}</td>
          </tr>

            <tr>
            <td colSpan={6} style={{textAlign: 'center'}}>
              <p className="received_text" style={{display: 'contents'}}>I confirm I have read Time Express prohibited and restricted items as well as hereby consent to the
Time Express terms and conditions.</p>
            </td>
          </tr> 
           </tbody>
    
     </table>   
    }  </> )         
                              ):null

                            }</>)
                          }
                 </div>
            
     
        </div>
        </div>
    )
}