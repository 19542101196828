import React, { useCallback, useEffect, useRef, useState } from 'react'
// import './app.css'
// import { User } from './User' // component display user (see detail on /example directory)
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  IResolveParams
} from 'reactjs-social-login'

// CUSTOMIZE ANY UI BUTTON
import {
  FacebookLoginButton,
  GoogleLoginButton,
  GithubLoginButton,
  AmazonLoginButton,
  InstagramLoginButton,
  LinkedInLoginButton,
  MicrosoftLoginButton,
  TwitterLoginButton
} from 'react-social-login-buttons'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// import { ReactComponent as PinterestLogo } from './assets/pinterest.svg'

// REDIRECT URL must be same with URL where the (reactjs-social-login) components is locate
// MAKE SURE the (reactjs-social-login) components aren't unmounted or destroyed before the ask permission dialog closes
const REDIRECT_URI = "window.location.href";


 

export default function Sample() {

  const [register, setregister] = useState({
    username:'',
    email:'',
    first_name:'',
    last_name:'',
    phone_number:'',
    password:''
   })

  const [provider, setProvider] = useState('')
  const [profile, setProfile] = useState()
const [submitreg, setsubmitreg] = useState(false)
  const onLoginStart = useCallback(() => {
   
  }, [])


  useEffect(()=>{
    if(provider!=""){
      console.log(provider)
      console.log(profile)
      if(provider=='google'){
        
       setregister({...register,username:profile.given_name,email:profile.email,first_name:profile.given_name,
        last_name:profile.family_name,password:profile.name})
        setsubmitreg(true)
      }
      if(provider=='facebook'){
       
        setregister({...register,username:profile.first_name,email:profile.email,first_name:profile.first_name,
          last_name:profile.last_name,password:profile.name})
          setsubmitreg(true)
      }
     
    }
    
  },[provider])

  const navigate=useNavigate()
  const SocialApi='http://localhost:8000/social_login.php';
  useEffect(()=>{
    if(register!=""){
      if(register.email!=""){
       console.log(register)
        axios({
          method: 'post',
          url: `${SocialApi}`,
          data: register
      })
      .then((res)=>
      {
        //  console.log(res.data)
        //  console.log(res.data.message)
         if(res.data.code==0){
         
          //setpasserror(res.data.message)
         }else{
          // console.log('in')
          console.log(res.data.userid)
          sessionStorage.setItem('userid',res.data.userid);
          navigate('/my-account/my-parcels')
         }
          
      }
      )
      .catch((err)=>console.log(err))
      }
    }
    },[submitreg])






 

  const onLogoutSuccess = useCallback(() => {
    setProfile(null)
    setProvider('')
    alert('logout success')
  }, [])

  return (
    <>
    {provider && profile ? (
      <p onLogout={onLogoutSuccess}>logout</p>
    
    ) : (
      <div className={`App ${provider && profile ? 'hide' : ''}`}>
        <h1 className='title'>ReactJS Social Login</h1>
        <LoginSocialFacebook
          appId={1236328493792135}
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }: IResolveParams) => {
            setProvider(provider)
            setProfile(data)
          }}
          onReject={(err) => {
            console.log(err)
          }}
        >
          <FacebookLoginButton />
        </LoginSocialFacebook>

        <LoginSocialGoogle
          client_id="955265914216-r9mapea98tpndc5gc3eanub4rs4a98rv.apps.googleusercontent.com"
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }: IResolveParams) => {
            setProvider(provider)
            setProfile(data)
          }}
          onReject={(err) => {
            console.log(err)
          }}
        >
          <GoogleLoginButton />
        </LoginSocialGoogle>

        <LoginSocialLinkedin
            client_id="78d6ab7p15fmcw"
            client_secret="Iw8gjJfIZsDWKIdh"
            redirect_uri={REDIRECT_URI}
            onLoginStart={onLoginStart}
            onResolve={({ provider, data }: IResolveParams) => {
              setProvider(provider)
              setProfile(data)
            }}
            onReject={(err: any) => {
              console.log(err)
            }}
          >
            <LinkedInLoginButton />
          </LoginSocialLinkedin>

       
      </div>
    )}
  </>
  )
        }
