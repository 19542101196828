import React from 'react'
import { Helmet } from 'react-helmet'
import '../../Custom.css'
import banner from '../../images/banners/collect.jpg'
export default function Online_booking() {
  return (
    <div className="improvelive">
          <Helmet>
        <title>Time Express | Online Booking</title>
      </Helmet>
        <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
              <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                            <h1 className="mainhead">Online Booking</h1>
                            <p>We're here to help</p>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="single_content">
 <article id="post-272" className="post-272 track type-track status-publish has-post-thumbnail hentry"><div className="become_driver post_driver"><div className="entry-content">
 <h1 style={{display: 'none'}}>Sunday Delivery</h1>
 <h4>Time Express: Your Ultimate Courier Portal for Seamless Shipping</h4>
 <p></p>
 <h4>Time Express is a revolutionary courier portal designed to streamline your shipping process and provide a hassle-free experience. Here's how it works:</h4>
 <ul style={{listStyle: 'decimal', padding: 'revert'}}>
	<li style={{paddingLeft: '10px'}}><p>Registration: Start by registering your account on the Time Express website. Provide your business information, contact details, and any necessary documentation for verification. Once registered, you'll have access to all the features and benefits of the courier portal.</p></li>
	<li style={{paddingLeft: '10px'}}><p>Shipping Options: Choose from a wide range of shipping options tailored to your needs. Whether you require domestic or international shipping, express or standard delivery, Time Express has you covered. Select the service that best suits your requirements and budget.</p></li>
	<li style={{paddingLeft: '10px'}}><p>Online Booking: Book your shipment directly through the Time Express portal. Enter the necessary details, such as the pickup and delivery addresses, package dimensions and weight, and any additional requirements or special instructions. The user-friendly interface makes it easy to input your shipment information accurately.</p></li>
	<li style={{paddingLeft: '10px'}}><p>Cost Calculation: Time Express instantly calculates the shipping cost based on the provided details. You'll receive a transparent breakdown of charges, including shipping fees, customs duties (if applicable), and any additional services requested. This allows you to compare prices and make an informed decision.</p></li>
	<li style={{paddingLeft: '10px'}}><p>Courier Selection: Time Express partners with reputable courier companies worldwide such as DHL, Fedex, and Aramex to ensure reliable and efficient service. Once you've entered your shipment details, the portal will display a list of available couriers along with their delivery times, track records, and customer ratings.</p></li>
	<li style={{paddingLeft: '10px'}}><p>Tracking and Updates: Sit back and relax while Time Express provides you with real-time tracking and regular updates on the status of your shipment. You can access this information directly through the portal or receive notifications via email or SMS. Stay informed every step of the way!</p></li>
	<li style={{paddingLeft: '10px'}}><p>Documentation and Labels: Time Express generates all the necessary shipping documents and labels seamlessly. You can download and print them directly from the portal, ensuring compliance with customs regulations and easy identification of your packages during transit.</p></li>
	<li style={{paddingLeft: '10px'}}><p>Customer Support: Time Express understands the importance of excellent customer service. In case you have any questions, concerns, or need assistance, our dedicated support team is available via phone, email, or live chat. We are here to help you throughout the shipping process.</p></li>
 </ul>
 <p>Whether you're a small business owner or an individual shipper, Time Express provides you with a user-friendly courier portal that simplifies the shipping process and saves you time and effort. </p>
 <p>Experience the convenience and efficiency of Time Express today and enjoy seamless shipping at your fingertips! </p>


 </div>
 </div>
 <footer className="entry-footer " /></article>
 <div className /></div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
