import React, { useEffect, useState } from 'react'

// for mobile app
export default function Orderapp() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [postdata, setpostdata] = useState([])
  const [postdata2, setpostdata2] = useState()
  const API_PATH=`${SERVER_URL}mobileapp.php`;
  useEffect(()=>{
    fetch(`${API_PATH}`) // url here
    .then((response) => {console.log(response)
      alert(response)
      setpostdata(response)
      setpostdata2(response)
      //return response.json(); // replace .json() to .text() for plain text
    })
    .then((daat) => {
      console.log(daat);
    });
},[])
  return (
    <>
    <div style={{textAlign:'center'}}></div>
    {/* {postdata?(
      <>
      {postdata}
      </>
    ):null} */}

{/* {postdata2?(
      <>
      {postdata2}
      </>
    ):null} */}
    </>
    
  )
}
