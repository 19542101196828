import React from 'react'

import blog1 from '../../../images/blogs/3.png'

import banner from '../../../images/banners/login.jpg'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
export default function CompetetivePrices() {
  return (
    <div>
           <Helmet>
        <title>Time Express | Articles</title>
      </Helmet>
          <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
             <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                            <h2>COMPETITIVE PRICES</h2>
                         
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content blogmain">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
               <div className="row">
               <div className="container-fluid py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5" >
            <img src={blog1} style={{width:'100%'}} />
          </div>
          <div className="row ">
            <div className="col-md-12">
              <div className="bg-light rounded overflow-hidden">
              <h4>Do You Need to Register a Company to Ship to the UAE? What is an Importer of Record (IOR)?</h4>
              <ul className='bloglist'>

              <li>Acting as the importer (IOR) / exporter (EOR) on behalf of customers.</li>
              <li>Obtaining permission from authorities for importation/exportation of goods on behalf of customers.</li>
              <li>Obtaining permits/licenses name or assisting customers with permit applications, depending on the regulations of the country in question.</li>
              <li>Assisting customers to prepare correct documentation and put in place the required procedures to effectively and efficiently cargo import and export to UAE in each country in question.</li>
                 </ul>
                 <h5>8 Step Implementation Process:</h5>
                 <p>1. Customer Due Diligence</p>
                 <p>2. Pricing</p>
                 <p>3. Contract Negotiations</p>
                 <p>4. Parts Classification</p>
                 <p>5. Authority Approval</p>
                 <p>6. Permit Application</p>
                 <p>7. Document Preparation</p>
                 <p>8. Testing & Go-Live</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
               </div>
          
    </div>
    </div>
    </main>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
