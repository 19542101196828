import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faClock, faBell, faEnvelope,faSquarePhone} from '@fortawesome/free-solid-svg-icons'
import {faInstagramSquare,faFacebookSquare,faLinkedinIn,faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'
import axios from "axios";
import { Link } from 'react-router-dom'
import foot from '../Footer.css'
import { useNavigate } from "react-router-dom";
import apple from '../images/apple.png'
import android from '../images/android.png'
import downloadapp from '../images/Time_Express.apk'
import bag from '../images/bag.png'

const facircle = <FontAwesomeIcon icon={faCircleCheck} />
const faEnvelope1 = <FontAwesomeIcon icon={faEnvelope} />
const faPhone1 = <FontAwesomeIcon icon={faSquarePhone} />
const faclock = <FontAwesomeIcon icon={faClock} />
const fabell = <FontAwesomeIcon icon={faBell} />
const fainsta = <FontAwesomeIcon icon={faInstagramSquare} />
const fafacebook = <FontAwesomeIcon icon={faFacebookSquare} />
const falinkedin = <FontAwesomeIcon icon={faLinkedinIn} />
const fayoutube = <FontAwesomeIcon icon={faYoutubeSquare} />
const LoginForm = ({ onSubmit }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
 
    return (
        <div className=" shipment_cont" style={{}}> 
        <img style={{position:"absolute",right:"0",width:"10%"}} className=" lazyloaded" src={bag}  alt="parcel" />
        <h4 >register now to receive your</h4>
        <h2 style={{color: "#7f3f98",textAlign:"center"}}>10% Off</h2>
        <h4>ON YOUR FIRST SHIPMENT OVER AED 50</h4>
        <ul className="get_itms">
        <h3 className="hr-lines">
        <span> And get exclusive advantages </span>
        </h3>
        
            <li style={{paddingLeft:"2%"}}>{facircle}
            <p style={{fontSize:"14px"}}>Book online &amp; save<br /> Send parcels to any<br /> destination</p>
            </li>
            <li>{faclock}
            <p style={{fontSize:"14px"}}>Get exclusive access<br /> to early bird promotions<br /> and discount</p>
            </li>
            <li>{fabell}
            <p style={{fontSize:"14px"}}>Tailored notifications<br /> on your shipment<br /> status</p>
            </li>
        
       
        
        </ul>

        <div id="msg-form-wysija-html5cef852d21cda-2" className="wysija-msg ajax" />

        
        </div>
           
     );
  };

export default function Footer() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const navigate=useNavigate()
    const [loggeduser, setloggeduser] = useState()
   const [msg, setmsg] = useState()
    function subscribe(){
        const API_PATH=`${SERVER_URL}subscribe.php`;
        if(loggeduser.username !="" && loggeduser.password !=""){
           
            axios({
                method: 'post',
                url: `${API_PATH}`,
                data: loggeduser
            })
            .then((res)=>
            {
               console.log(res.data)
               setmsg(res.data.message)
               setTimeout(() => navigate('/register'), 1000); 
          
            }
            )
            .catch((err)=>console.log(err))

        }else{
            console.log("Enter username and password")
        }
   
      
      
    }

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  useEffect(() => {
    let time =  sessionStorage.getItem('time')
//   let time = localStorage.getItem('time');

   
    console.log(time)
    if (time == null) {
       // localStorage.setItem('time', new Date());
       sessionStorage.setItem('time', new Date());
       setTimeout(() => setShow(true), 11000); 
       
    }else{
        setShow(false)
    }
    
  },[]);
    return (
        <div>


<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ background:"#a060b9",color:"#fff"}}>
          <Modal.Title style={{textAlign:"center",width:"100%"}}>Time Express
          <p style={{fontSize:"20px"}}>
Send international parcels for less</p></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <>
          <LoginForm onSubmit={onLoginFormSubmit} />
          
        <form className="subscribeform">
                 <label>Email*</label>
                 <input type="text" placeholder='Email' className="form-control mb-3" required onChange={(e)=>setloggeduser({...loggeduser,email:e.target.value})}/>
                 <label>Phone*</label>
                 <input type="text" placeholder='Phone Number' className="form-control  mb-3"  required onChange={(e)=>setloggeduser({...loggeduser,phone:e.target.value})}/>
                 <button type="button" className="btn  btn-sm" onClick={subscribe} style={{backgroundColor:'#7b3995',color:"#fff",padding:"2%"}}>SUBSCRIBE</button>
             </form>
             <p style={{textAlign:"center",color:"green"}}>
                {msg}
             </p>
             </>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close Modal
          </Button>
        </Modal.Footer> */}
      </Modal>
            <footer className="footer">
                <div className="container ">
                    <div className="row footersec" style={{ padding: "3%" }}></div>
                    <div className="row">
                        <div className="col-md-3 col-lg-3 col-xl-3">
                            <div className="block mb-4 mb-lg-0">
                                <h5 className="footer-nav-title text-light">About Time Express Parcels</h5>
                                <div className="office1">
                                    <div className="block mb-4 mb-lg-0">

                                        <p><Link to="/about-us" style={{ color: '#fff !important' }}><span dir="ltr">About Us</span></Link></p>
                                        <p><Link to="/help" style={{ color: '#fff !important' }}><span dir="ltr">Help</span></Link></p>
                                        <p><Link to="testimonials" style={{ color: '#fff !important' }}><span dir="ltr">Testimononials</span></Link></p>
                                        <p><Link to="contact-time-express" style={{ color: '#fff !important' }}><span dir="ltr">Contact Time Express</span></Link></p>
                                        <p><Link to="/improving-lives" style={{ color: '#fff !important' }}><span dir="ltr">Improving Lives</span></Link></p>
                                        <p><Link to="/medical-services" style={{ color: '#fff !important' }}><span dir="ltr">Medical Services</span></Link></p>
                                        <p><Link to="/sonoco-thermosafe" style={{ color: '#fff !important' }}><span dir="ltr">Sonoco Thermosafe</span></Link></p>
                                        <p><Link to="/careers" style={{ color: '#fff !important' }}><span dir="ltr">Careers</span></Link></p>
                                        <p><Link to="/blogs" style={{ color: '#fff !important' }}><span dir="ltr">Blogs</span></Link></p>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-lg-3 col-xl-3">
                            <div className="block mb-4 mb-lg-0">
                                <h5 className="footer-nav-title text-light">Time Express Services</h5>
                                <div className="office1">
                                    <div className="block mb-4 mb-lg-0">

                                        <p><Link to="/client-zone" style={{ color: '#fff !important' }}><span dir="ltr">Client Zone</span></Link></p>
                                        <p><Link to="/become-a-partner" style={{ color: '#fff !important' }}><span dir="ltr">Partner Zone</span></Link></p>
                                        <p><Link to="/send/local-courier-same-day-courier" style={{ color: '#fff !important' }}><span dir="ltr">Time Services</span></Link></p>
                                        <p><Link to="/track/same-day-delivery/" style={{ color: '#fff !important' }}><span dir="ltr">Same Day Service</span></Link></p>
                                        <p><Link to="/track/next-day-delivery" style={{ color: '#fff !important' }}><span dir="ltr">Next Day  Service</span></Link></p>
                                        <p><Link to="/track/bullet-delivery" style={{ color: '#fff !important' }}><span dir="ltr">Bullet Service</span></Link></p>
                                        <p><Link to="/track/sunday-delivery" style={{ color: '#fff !important' }}><span dir="ltr">Sunday Service</span></Link></p>
                                        <p><Link to="/prohibited-restricted-items" style={{ color: '#fff !important' }}><span dir="ltr">Prohibited & Restricted Items</span></Link></p>
                                        <p><Link to="/packaging-advice" style={{ color: '#fff !important' }}><span dir="ltr">Packaging Advice</span></Link></p>
                                        <p><Link to="customs-clearance-services" style={{ color: '#fff !important' }}><span dir="ltr">Customs Clearance Services</span></Link></p>
                                        {/* <p><Link to="" style={{ color: '#fff !important' }}><span dir="ltr">Blog</span></Link></p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-xl-3">
                            <div className="block mb-4 mb-lg-0">
                                <h5 className="footer-nav-title text-light">Join Our Community</h5>
                                <div className="office1">
                                    <div className="block mb-4 mb-lg-0">

                                        <p><Link to="/become-a-driver" style={{ color: '#fff !important' }}><span dir="ltr">Become a Driver</span></Link></p>
                                        <p><Link to="/become-a-partner" style={{ color: '#fff !important' }}><span dir="ltr">Become a Partner</span></Link></p>
                                        <h5 className="footer-nav-title text-light">Delivery To..</h5>
                                        <p><Link to="/send-parcel-to-usa" style={{ color: '#fff !important' }}><span dir="ltr">Send A Parcel To USA</span></Link></p>
                                        <p><Link to="/send-parcel-to-uk" style={{ color: '#fff !important' }}><span dir="ltr">   Send A Parcel To UK</span></Link></p>
                                        <p><Link to="/send/IN" style={{ color: '#fff !important' }}><span dir="ltr">Send A Parcel To India</span></Link></p>
                                        <p><Link to="/send/PH" style={{ color: '#fff !important' }}><span dir="ltr"> Send A Parcel To Philippines</span></Link></p>
                                        <p><Link to="/send/FR" style={{ color: '#fff !important' }}><span dir="ltr"> Send A Parcel To France</span></Link></p>
                                        <p><Link to="/send/DE" style={{ color: '#fff !important' }}><span dir="ltr">Send A Parcel To Germany</span></Link></p>
                                        <p><Link to="/send/CN" style={{ color: '#fff !important' }}><span dir="ltr">Send A Parcel To China</span></Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-xl-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="block mb-4 mb-lg-0">
                                        <div className="col-md-12">
                                            <h5 className="footer-nav-title text-light">General</h5>
                                            <div className="office1">
                                                <div className="block mb-4 mb-lg-0">

                                                    <p><Link to="/terms-conditions" style={{ color: '#fff !important' }}><span dir="ltr">
                                                        Terms & Conditions</span></Link></p>
                                                    <p><Link to="/privacy-cookies" style={{ color: '#fff !important' }}><span dir="ltr">Privacy & Cookies</span></Link></p>
                                                </div>
                                            </div>
                                            <div className="office1"  style={{ marginTop: "7%" }}>
                                                <div className="block mb-4 mb-lg-0">
                                                    <p><a href="tel:600503327" style={{ color: '#fff !important' }}><span dir="ltr">{faPhone1} &nbsp; &nbsp;600 50 3327</span></a></p>
                                                    <p><a href="mailto:info@timexpress.ae?subject=Time Express Enquiry" style={{ color: '#fff !important' }}><span dir="ltr">{faEnvelope1} &nbsp; &nbsp;info@timexpress.ae</span></a></p>

                                                </div>
                                            </div>

                                            {/* <div className="download-link" style={{ marginTop: "10%" }}> 
                                               
                                                <a href="https://apps.apple.com/ae/app/time-express-parcels/id1234922025" target="_blank" className="app-link"> <img src={apple} alt="apple" style={{ width: "150px" }} /></a>
												https://itunes.apple.com/ae/app/time-express-parcels/id1234922025?ls=1&mt=8
                                                </div>
                                                <div className="col-md-12">
                                                <a href="https://play.google.com/store/apps/details?id=com.timeexpress.ae" className="android-link" target="_blank"> <img src={android} alt="android" style={{ width: "150px" }} /></a>
                                                </div> */}
                                            {/* </div> */}

                                            <div className="col-md-12">
                                            <a href={downloadapp} download="Time Express" target="_blank"><img src={android} alt="android" style={{ width: "150px" }} /></a>
                                              </div>
                                            <ul className="social_icon">
                                                <li>
                                                    <a href="https://www.facebook.com/TimeExpressParcels/" target="_blank">
                                                       {fafacebook}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/timeexpressparcel" target="_blank">
                                                      {fainsta}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/time-express-parcels/" target="_blank">
                                                        {falinkedin}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.youtube.com/channel/UCl3obiSmUZ4t2BMpfR2KKxQ" target="_blank">
                                                      {fayoutube}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="block mb-4 mb-lg-0">
                                        <div className="col-md-12">
                                            <h5 className="footer-nav-title text-light">General</h5>
                                            <div className="office1">
                                                <div className="block mb-4 mb-lg-0">
                                                    <p><a href="tel:600503327" style={{ color: '#fff !important' }}><span dir="ltr">600 50 3327</span></a></p>
                                                    <p><a href="mailto:info@timexpress.ae?subject=Time Express Enquiry" style={{ color: '#fff !important' }}><span dir="ltr">{faEnvelope1}info@timexpress.ae</span></a></p>

                                                </div>
                                            </div>

                                            <div className="download-link" style={{ marginTop: "25%" }}>
                                                <div className="col-md-12" style={{ marginBottom: "12%" }}>
                                                <a href="https://itunes.apple.com/ae/app/time-express-parcels/id1234922025?ls=1&mt=8" target="_blank" className="app-link"> <img src={apple} alt="apple" style={{ width: "150px" }} /></a>
                                                </div>
                                                <div className="col-md-12">
                                                <a href="https://play.google.com/store/apps/details?id=com.timeexpress.ae" className="android-link" target="_blank"> <img src={android} alt="android" style={{ width: "150px" }} /></a>
                                                </div>
                                            </div>


                                            <ul className="social_icon">
                                                <li>
                                                    <a href="https://www.facebook.com/TimeExpressParcel/" target="_blank">
                                                       {fafacebook}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/timeexpressparcel" target="_blank">
                                                      {fainsta}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/time-express-parcels/" target="_blank">
                                                        {falinkedin}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.youtube.com/channel/UCl3obiSmUZ4t2BMpfR2KKxQ" target="_blank">
                                                      {fayoutube}
                                                    </a>
                                                </li>
                                            </ul>

                                        </div>

                                    </div>
                                </div> */}
                            </div>

                        </div>

                    </div>
                </div>
            </footer>
            <div className="copyright">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="block copyright-content mb-2 mb-md-0">
                                <p className="m-0 text-light foottext">© 2022 TIME EXPRESS PARCELS | ALL RIGHTS RESERVED. </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
    )
}
