import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate ,Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from '../../Business.css';
import banner from '../../images/banners/client.jpg'
export default function Client_zone() {

    const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;
    const navigate = useNavigate();
    const [parcels, setparcels] = useState()
    const [sendpath, setsendpath] = useState()
    const [loggeduser, setloggeduser] = useState({
        username: "",
        password: ""
    })

    function saveOrder2(event){
  
        event.preventDefault();
        const data = new FormData();
        //data.append("file[]", file);
       
        data.append("userid",loggeduser.username)
        data.append("password",loggeduser.password)
        console.log(data)
        let url = "http://timeexpress.dnsalias.com:880/onlinebooking/checklogin.aspx";
      
        axios.post(url, data, {
            // receive two parameter endpoint url ,form data
          })
          .then((res) => {
            // then print response status
           console.log(res.data)
          },error=>{
            alert(error);
          });
      
      
      }

      function clientzone_login(){
       // window.location.assign('http://timeexpress.dnsalias.com:880/onlinebooking/Index.aspx', '_blank')
        window.open('http://timeexpress.dnsalias.com:880/onlinebooking/Index.aspx', '_blank');
      }

      function dashboardLogin(){
        const API_PATH=`${INTEGRA_URL}dashboard_login.php`;
        if(loggeduser.username !="" && loggeduser.password !=""){
           
            axios({
                method: 'post',
                url: `${API_PATH}`,
                data: loggeduser
            })
            .then((res)=>
            {
               console.log(res.data)
                if(res.data>0){
                    console.log('dashboard'+res.data)

                    sessionStorage.setItem('accountno',res.data);
                  navigate('/dashboard')
               
                 }else{
                     alert("password missmatch")
                 }
            }
            )
            .catch((err)=>console.log(err))

        }else{
            console.log("Enter username and password")
        }

      }
    return (
        <div className="clientzone">

<Helmet>
        <title>Time Express | Client Zone</title>
      </Helmet>
            <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 your_business text-center client_zone">
                            <div className="business_content">
                               
                                <h1 className='h1-title'>CLIENT ZONE</h1></div>
                            <div className="container">
                                <div className="row">

                                    

                                    <div className="col-md-5" style={{ border: "1px solid", marginTop: "6%", marginBottom: "6%", background: "#fff" }}>
                                      
                                        <div id="primary" className="content-area" style={{ float: 'left', padding: '3%', width: "100%" }}>
                                            <h3 style={{ textTransform: "uppercase", padding: "3%" }}><span>Sign in to your account</span></h3>
                                            {/* <main id="main" className="site-main" role="main">
                                                <form >
                                                    <input type="text" name="userid" onChange={(e) => setloggeduser({ ...loggeduser, username: e.target.value })} placeholder="Username" id="username" className="form-control mb-3" required  />
                                                    <input type="text" name="password" onChange={(e) => setloggeduser({ ...loggeduser, password: e.target.value })} placeholder="Password" id="password"  className="form-control  mb-3" required  />
                                                    <button onClick={clientzone_login} type="button" className="btn  btn-sm" style={{ backgroundColor: '#7b3995', color: "#fff", padding: "2%", width: "19%" }}>Login</button>
                                                </form>
                                            </main> */}
                                            <button onClick={clientzone_login} type="button" className="btn  btn-sm" style={{ backgroundColor: '#7b3995', color: "#fff", padding: "2%", width: "19%" }}>Login</button>
                                               
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5" style={{ border: "1px solid", marginTop: "6%", marginBottom: "6%", background: "#fff" }}>
                                        <div id="primary" className="content-area" style={{ float: 'left', padding: '3%', width: "100%" }}>
                                            <h3 style={{ textTransform: "uppercase", padding: "3%" }}><span>CUSTOMER DASHBOARD</span></h3>
                                            {sessionStorage.getItem('accountno')?(
                                            <p>Please <Link to="/dashboard">click</Link> here to view the dashboard</p>
                                            ):
                                            ( <main id="main" className="site-main" role="main">
                                            <form>
                                                <input type="text" placeholder='username' className="form-control mb-3" required onChange={(e) => setloggeduser({ ...loggeduser, username: e.target.value })} />
                                                <input type="text" placeholder='password' className="form-control  mb-3" required onChange={(e) => setloggeduser({ ...loggeduser, password: e.target.value })} />
                                                <button type="button" onClick={dashboardLogin} className="btn  btn-sm" style={{ backgroundColor: '#7b3995', color: "#fff", padding: "2%", width: "19%" }}>Login</button>
                                            </form>
                                        </main>)
                                        }
                                           
                                        </div>
                                    </div>

                                </div>
                            </div>





                        </div>
                    </div>
                </div>


            </section>

        </div>
    )
}
