import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function Rating() {
    
    const location = useLocation();

    React.useEffect(() => {
        window.location.replace('https://g.page/r/CbwSwB8ZdZsOEAI/review')
      }, [])

  return (
    <div>
      <p style={{textAlign:"center"}}>You will be redirected to Google rating.</p>
    {/* {location.state.orderno}
    {location.state.payment_page} */}
   
    </div>
  )
}
