import React, { useEffect } from "react";
import { saveAs } from "file-saver";
const url=window.location.href
const filename = url.split("/").pop();
const DOMAIN_URL = process.env.REACT_APP_DOMAIN;
//const filename="347443-slide1.png";
//const filename="347443-slide1.png";
const filepath=`${DOMAIN_URL}uploads/${filename}`

export default function Download() {
  useEffect(()=>{
    saveAs(
      filepath,
      filename
    );
   },[])
 
 
  return (
    <div>
     
    </div>
  );
}
