import axios from 'axios';
import React, { useState ,forwardRef, useRef, useEffect } from 'react'
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import {useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import banner from '../../images/banners/login.jpg'
import loading from '../../images/spin.gif'
import logo from '../../images/logo-purple.png'
import { country_code_array } from './functions';
const ComponentToPrint = forwardRef((props, ref) => {
    return <div ref={ref}>hello 1 world</div>;
  });

 

export default function After_payment_bulkorder_domestic() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;
  const [loadingshow, setloadingshow] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams();
  const orderno=searchParams.get("ref")
  const [orderid, setorderid] = useState()
  const location = useLocation();
  let newDate = new Date()
    let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
const [errormsg, seterrormsg] = useState('checking the payment status.')
const [msgcolor, setmsgcolor] = useState('#000')
const [downloadlink, setdownloadlink] = useState()
 const API_payment=`${INTEGRA_URL}check_bulk_payment_done.php`; 
 const userid=sessionStorage.getItem('userid');
 const navigate = useNavigate();
 // const API_payment=`${INTEGRA_URL}check_payment.php`;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_payment}`,
      data: orderno
    })
    .then((res)=>{
   
if(res.data.captured==1){
  seterrormsg('You will be redirected to label page');
   generatelabel(res.data.batch_id)
}else{
  setmsgcolor('red')
  seterrormsg('Payment Failed.');
}
    //navigate('/bulk-domestic-awb-label', {state:{userid:userid,batch_id:res.data.batch_id,awbcontent:res.data.awbresult,awbcount:res.data.awbresult.length}})
   
   
   //navigate('/bulk-awb-label', {state:{userid:userid,batch_id:res.data.batch_id,awbcontent:res.data.awbresult,awbcount:res.data.awbresult.length}})


    })
    .catch((err)=>console.log(err))
      }, [orderno])


      function generatelabel(val){
        console.log(val)
        const API_payment=`${INTEGRA_URL}create_batch_awb_domestic.php`; 
        axios({
          method: 'post',
          url: `${API_payment}`,
          data: {batchid:val}
        })
        .then((res)=>{
console.log(res.data)
         setTimeout(() =>  navigate('/bulk-domestic-awb-label', {state:{userid:userid,batch_id:val}}), 1000); 
         loadingshow(false)
        

   
        })
        .catch((err)=>console.log(err))

      }
      

  return (
    <div class="awblabel ">
        <Helmet>
        <title>Time Express | After Payment</title>
      </Helmet>
        <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
        <div className="container"><div className="row">
          <div className="col-md-12 col-sm-12 your_business text-center">
            <div className="business_content">
              <h2 style={{display:'none'}}>ORDER DETAIL</h2>
              <h1 className='h1-title'>ORDER DETAIL</h1>
            </div>
          </div>
        </div>
        </div>
      </section>

      
<div>
<p style={{color:`${msgcolor}`,padding:'4%',fontSize:'22px',textAlign:'center',paddingBottom:"0"}}>{errormsg}</p> 
{loadingshow?(
      <p style={{textAlign:"center",marginTop:"1%",paddingBottom:"2%"}}><img src={loading} style={{width:"2%"}}/></p> 
    ):null}
</div>
       
        </div>
    

      
  
  )

}
