import React from 'react'
import laptop from '../../images/How_it_works.png'
import label from '../../images/label.jpg'
import banner from '../../images/banners/login.jpg'
import loading from '../../images/spin.gif'
import { useState } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
export default function Smart_send() {
    
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
       const [passerror, setpasserror] = useState('')
const [passsuccess, setpasssuccess] = useState('')
const [loadingshow, setloadingshow] = useState(false)
const [enquiry, setenquiry] = useState({
    name:'',
    phone:'',
    platform:''
    })
    const [sector, setsector] = useState([])

    const [stsmsg, setstsmsg] = useState()
    //const API_PATH='http://localhost:8000/smart_send_enquiry.php';
    const API_PATH=`${SERVER_URL}smart_send_enquiry.php`;
   function sendEnquiry(){
    if(enquiry.name!="" && enquiry.phone!="" && enquiry.company_name!="" && enquiry.platform!=""&& enquiry.address!=""&& enquiry.city!="" && sector!=""){
        setstsmsg('')
        setloadingshow(true)
    axios({
        method:"post",
        url:`${API_PATH}`,
        data:{enquiry:enquiry,sector:sector}
    })
    .then((res)=>{
    console.log(res.data)
    setloadingshow(false)
   setstsmsg(res.data.message)
    })
    .catch(err=>console.log(err))
   }else{
    setstsmsg('Please enter the required fields')
   }
}
  return (
    <div>
        <Helmet>
        <title>Time Express | Smart Send</title>
      </Helmet>
          <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
             <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">SMART SEND</h1>
                            <p>Print your labels and book in seconds.</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
               <div className="row">
                   <div className="col-md-6">
                       <img src={laptop} style={{width:"100%"}}/>
                   </div>
                   <div className="col-md-6" style={{paddingLeft:"6%"}}>
                   <h4>Shopify</h4>
                   <p>Instantly book & get your parcel quote.</p>
           <h4>WooCommerce</h4>
           <p>Enter “from” and “to” address information along with your shipment details and print labels to place on your package.</p>
           <h4>Magento</h4>
           <p>Ship internationally to 220 countries. A Time Express courier will come to your door step to collect your parcel.</p>
          
                   </div>
               </div>
               <form> 
               <div className="row">
               <h4>Start an Account with Us</h4>
                  
          
<div className="col-md-6">
<div className="form-group"> <label>Full Name*</label>
<br /> <span className="wpcf7-form-control-wrap FirstName">
<input type="text" name="FirstName" onChange={(e)=>setenquiry({...enquiry,name:e.target.value})}  size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="fullname" aria-required="true" aria-invalid="false" placeholder="Please Enter Your First Name" />
</span>
</div>
<div className="form-group"> <label>Company Name*</label>
<br /> <span className="wpcf7-form-control-wrap CompanyName">
<input type="text" name="CompanyName" onChange={(e)=>setenquiry({...enquiry,company_name:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="company" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Company Name" />
</span>
</div>
<div className="form-group"> <label>Email Address*</label>
<br /> <span className="wpcf7-form-control-wrap email">
<input type="email" name="email" onChange={(e)=>setenquiry({...enquiry,email:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Email Address" />
</span>
</div>
<div className="form-group"> <label>Telephone*</label>
<br /> <span className="wpcf7-form-control-wrap telephone">
<input type="tel" name="telephone" onChange={(e)=>setenquiry({...enquiry,phone:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel form-control" id="tel" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Telephone" />
</span>
</div>
<div className="form-group"> <label>Address*</label>
<br /> <span className="wpcf7-form-control-wrap Address">
<input type="text" name="Address" onChange={(e)=>setenquiry({...enquiry,address:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="address" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Address" />
</span>
</div>
<div className="form-group"> <label>Town/City*</label>
<br /> <span className="wpcf7-form-control-wrap TownCity">
<input type="text" name="TownCity" onChange={(e)=>setenquiry({...enquiry,city:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="town" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Town or City" />
</span>
</div>
</div>
                   <div className="col-md-6" style={{paddingLeft:"6%"}}>
                   

                
<div className="form-group"> <label>Ecommerce Platform</label>
<br /> <span className="wpcf7-form-control-wrap TownCity">
<select name="Courier" onChange={(e)=>setenquiry({...enquiry,platform:e.target.value})} className="wpcf7-form-control wpcf7-select form-control" id="courier" aria-invalid="false">
<option value="Please Select">Please Select</option>
<option value="Shopify">Shopify</option>
<option value="WooCommerce">WooCommerce</option>
<option value="Magento">Magento</option>
</select>
</span>
</div>

<div className="form-group"> <label>Vendor Type</label>
<br /> <span className="wpcf7-form-control-wrap TownCity">
<select name="Courier" onChange={(e)=>setenquiry({...enquiry,vendor:e.target.value})} className="wpcf7-form-control wpcf7-select form-control" id="courier" aria-invalid="false">
<option value="Please Select">Please Select</option>
<option value="1">Single Vendor</option>
<option value="2">Multi Vendor</option>
</select>
</span>
</div>

<div className="form-group"> <label>Shipping To</label>
<br /> <span className="wpcf7-form-control-wrap TownCity">
<span className="wpcf7-list-item first">
<input type="checkbox" name="Sectors" onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}}  defaultValue="Domestic" />
<span className="wpcf7-list-item-label">Domestic</span>
</span>
<span className="wpcf7-list-item">
<input type="checkbox" name="Sectors" onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="International" />
<span className="wpcf7-list-item-label">International</span>
</span>
</span>
</div>
<div className="col-md-12">
<div className="form-group textarea_reilan"> <label className="textarea_label "> Any further information?</label>
<br /> <span className="wpcf7-form-control-wrap your-message">
<textarea name="your-message" onChange={(e)=>setenquiry({...enquiry,message:e.target.value})} cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Write additional information in this box..." defaultValue={""} />
</span>
</div>
</div>
<button type="button" className="btn  btn-sm" onClick={sendEnquiry}  style={{backgroundColor:'#7b3995',color:"#fff",padding:"2%",width:"19%",float:"right"}}>Register</button>
 <p>{stsmsg}</p>
 {loadingshow?(
      <p style={{textAlign:"center",marginTop:"2%"}}><img src={loading} style={{width:"5%"}}/></p> 
    ):null}
</div>
         
               </div>
               </form>
               <div className="row">
                   
                 
                    

                   
                 
               
                 
                   </div>
          
    </div>
    </div>
    </main>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
