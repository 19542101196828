import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom'
import banner from '../../images/banners/login.jpg'
export default function PricingLogin() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const navigate=useNavigate()
    const [users, setusers] = useState()
    const [loggeduser, setloggeduser] = useState({
        username:"",
        password:""
    })
   
    
  

    function login(){
        const API_PATH=`${SERVER_URL}login.php`;
        //const API_PATH='http://localhost:8000/login.php';
        if(loggeduser.username !="" && loggeduser.password !=""){
           
            axios({
                method: 'post',
                url: `${API_PATH}`,
                data: loggeduser
            })
            .then((res)=>
            {
               console.log(res.data)
                if(res.data.status===true){
                    console.log(res.data.userid)
                    sessionStorage.setItem('userid',res.data.userid);
                    check_corporate_discount(res.data.userid)
                  
               
                 }else{
                     alert("password missmatch")
                 }
            }
            )
            .catch((err)=>console.log(err))

        }else{
            console.log("Enter username and password")
        }
   
      
      
    }

    function check_corporate_discount(user){
        const API_PATH1=`${SERVER_URL}check_corporate_discount.php`;
        axios({
            method: 'post',
            url: `${API_PATH1}`,
            data: user
        })
        .then((res)=>
        {
      
                sessionStorage.setItem('is_corporate',res.data.status);
                navigate('/pricing')
        })
        .catch((err)=>console.log(err))
       
    }

  return (
    <div>
         <Helmet>
        <title>Time Express | Login</title>
      </Helmet>
    <section className="your_business_page fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
         <div className="container"><div className="row">
             <div className="col-md-12 col-sm-12 your_business text-center">
                 <div className="business_content">
                     
                     <h1 className="mainhead">LOGIN</h1>
                 </div>
             </div>
         </div>
         </div>
     </section>
     <div className="pages_content">
     <div className="container">
     <div className="row">
         <div className="col-md-3"></div>
         <div className="col-md-6 loginbox" style={{border:"1px solid",marginTop:"6%",marginBottom:"6%"}}>
         <div id="primary" className="content-area" style={{float: 'left', padding: '3%',width:"100%"}}>
     <main id="main" className="site-main" role="main">
     <form>
                 
            <input type="text" placeholder='username' className="form-control mb-3" required onChange={(e)=>setloggeduser({...loggeduser,username:e.target.value})}/>
           
            <input type="password" placeholder='password' className="form-control  mb-3"  required onChange={(e)=>setloggeduser({...loggeduser,password:e.target.value})}/>
            <button type="button" className="btn  btn-sm" onClick={login} style={{backgroundColor:'#7b3995',color:"#fff",padding:"2%",width:"19%"}}>Login</button>
       <Link className="loginaction" to="/register">Are you a member?</Link>
       <Link className="loginaction" to="/reset-password">Forgot Password</Link>
        </form>
</main>
</div>
         </div>
   
</div>
</div>
</div>
</div>
  )
}
