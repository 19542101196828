import axios from 'axios';
import e from 'cors';
import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSearchParams } from 'react-router-dom';
import loading from '../../../../images/spin.gif'
import banner from '../../../../images/banners/collect.jpg';
export default function Schedule_pickup() {
    const date=new Date();
    const today= date.getDay()
    const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;

    

   // const [searchParams, setSearchParams] = useSearchParams();
  //const awbno=searchParams.get("awb")
    let params = new URLSearchParams(document.location.search);
    let awbno = params.get("awbno"); 
  const [loadingshow, setloadingshow] = useState(false)
    //let result = date.setDate(date.getDate() + 1);
    //const [startDate, setStartDate] = useState(new Date(result));
    const [startDate, setStartDate] =useState(today==6?new Date(date.setDate(date.getDate()+2)):new Date(date.setDate(date.getDate()+1)))
   
    
    const date2=new Date();
    
    const today2= date2.getDay()
    //alert(startDate);
     const [startDate2, setStartDate2] =useState(today2==6?new Date(date2.setDate(date2.getDate()+2)):new Date(date2.setDate(date2.getDate()+1)))
    
     
     //Ovasave Pickup date
      const dateOva=new Date();
      const todayOva= dateOva.getDay() 
      const hoursOva= dateOva.getHours()

      const [startOvaDate, setStartOvaDate] =useState(todayOva==5?new Date(dateOva.setDate(dateOva.getDate()+3)):todayOva==6 && hoursOva>=12?new Date(dateOva.setDate(dateOva.getDate()+3)):new Date(dateOva.setDate(dateOva.getDate()+2)))
    
      const date3=new Date();    
      const today3= date3.getDay()
      const hoursOva3= date3.getHours()
      const [startDate3, setStartDate3] =useState(today3==5?new Date(date3.setDate(date3.getDate()+3)):today3==6 && hoursOva3>=12?new Date(date3.setDate(date3.getDate()+3)):new Date(date3.setDate(date3.getDate()+2)))
     
      //console.log(startOvaDate);

    const [schedule, setschedule] = useState({shipping_date:startDate})
    const API_PATH = `${INTEGRA_URL}schedule_pickup.php`;
    const [msg, setmsg] = useState()
    function Schedule_pickup(){
      setloadingshow(true)
      console.log(schedule)
      
      axios({
        method:"post",
        url:`${API_PATH}`,
        data:schedule
      })
      .then((res)=>{
        setloadingshow(false)
        setmsg(res.data)
    
      })
      .catch(err=>console.log(err))
      
    } 

    const isWeekday = (date) => {
      const day = date.getDay();
      return day !== 0 ;
    }

    function setscheduledate(vars){
     setschedule({...schedule,shipping_date:vars})
     setStartDate(vars)
     setStartOvaDate(vars)
    }

    useEffect(() => {  
        
      if(awbno>1){
        setschedule({...schedule,awb:awbno,type:'pickup','customer':'ovasafe','shipping_date':startOvaDate})
      }else{
        setschedule({...schedule,'customer':'normal'})
      }
    
    }, [])
    
  return (
    <div>

<section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                            
                            <h1 className='h1-title'>SCHEDULE YOUR PICK-UP AND DELIVERY TIME</h1>
<h5 style={{color:'#fff',fontWeight:"bold"}}>We're here to help</h5>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
            <h4>SHIPPING CONVENIENCE</h4>
            <p>Depending on the service that has been purchased by the sender and if you are having a busy schedule and want the convenience of selecting a specific 2 hour time slot for delivering your parcel this option is for you. Simply click on the date and time to let us know your preference, we will do our best to meet your request.</p>
<p>If the sender has not used a service that provides a two-hour delivery window then it won’t be available.</p>
             </div>


             <div id="contact_form_show" className="become_driver">
            <h4>Get Started (Submit Form)</h4><p />

            <form action="#">
        <div className="form-row row" style={{marginBottom:"6px"}}>
          <div className="form-group col-md-6" >
        {awbno && awbno>1?(
          <input type="text" disabled value={awbno} onChange={(e)=>setschedule({...schedule,awb:e.target.value})} className="form-control" id="company_name" placeholder="AWB Number*" />
        ):(
<input type="text" value={awbno} onChange={(e)=>setschedule({...schedule,awb:e.target.value})} className="form-control" id="company_name" placeholder="AWB Number*" />
        )}
          </div>
          <div className="form-group col-md-6">
          {awbno&&awbno>1?(  
            <select className="form-control" id="mySelect"  disabled onChange={(e)=>setschedule({...schedule,type:e.target.value})} style={{padding: "0.625em"}}>
                                     <option value id="please_select">Select</option>
                                     <option data-tray="one"  selected value="pickup">Pickup</option>
                                     <option data-tray="two"  value="delivery">Delivery</option>
            </select>
          ):(
            <select className="form-control" id="mySelect"   onChange={(e)=>setschedule({...schedule,type:e.target.value})} style={{padding: "0.625em"}}>
                                     <option value id="please_select">Select</option>
                                     <option data-tray="one"   value="pickup">Pickup</option>
                                     <option data-tray="two"  value="delivery">Delivery</option>
            </select>
          )}
          </div>
          </div>

          <div className="form-row row" style={{marginTop:"2px"}}>
          <div className="form-group col-md-6">
            <select className="form-control" id="mySelect" onChange={(e)=>setschedule({...schedule,timeframe:e.target.value})}  style={{padding: "0.625em"}} >
                                     <option value id="please_select">Select</option>
                                     <option value="7:00-9:00">7.00 AM - 9.00 AM</option>
                                     <option value="9:00-11:00">9.00 AM - 11.00 AM</option>
                                     <option value="11.00-1:00">11.00 AM - 1.00 PM</option>
                                     <option value="1:00-15:00">1.00 PM - 3.00 PM</option>
                                     <option value="15:00-17:00">3.00 PM - 5.00 PM</option>
                                     <option value="17:00-19:00">5.00 PM - 7.00 PM</option>
                                     <option value="19:00-21:00">7.00 PM - 9.00 PM</option>
            </select>
          </div>
          <div className="form-group col-md-6">
            {awbno&&awbno>1?(
              <DatePicker className="form-control shipping_date"   filterDate={isWeekday} name="shipping_date" dateFormat='dd/MM/yyyy' minDate={startDate3} selected={startOvaDate} onChange={(date)=>setscheduledate(date)} />
            ):(
              <DatePicker className="form-control shipping_date"   filterDate={isWeekday} name="shipping_date" dateFormat='dd/MM/yyyy' minDate={startDate2} selected={startDate} onChange={(date)=>setscheduledate(date)} />
            
            )}  



          </div>                                                    
        </div>
        <button type="button" style={{marginTop:"3%",background:"#800080",border:"none"}} onClick={Schedule_pickup} className="btn btn-primary" >SEND</button>
      </form>
      <p style={{textAlign:"center"}}>{msg}</p>
      {loadingshow?(
      <p style={{textAlign:"center",marginTop:"2%"}}><img src={loading} style={{width:"5%"}}/></p> 
    ):null}
     
            </div>

            </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}

