import React from 'react'
import { Helmet } from 'react-helmet'
import Sidebar from './Sidebar'

export default function Tracking() {


  
  return (
    

<div className="my_account_tracking">
<Helmet>
        <title>Time Express | Tracking</title>
      </Helmet>
            <div className="container myparctab" >

                <div className="container-fluid my_quote">
                    <div className="row">

                        <Sidebar />
                        <div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">
                          
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <h3 className="comaprison_hedding">TRACK YOUR PARCEL</h3>
                                <form name="tracking" method="get"  id="tracking_track" >
                                 <ul className="business_region">
                                    <li><input required id="TrackingCode" className="form-control" name="awbno" placeholder="Tracking number"  type="text" /></li>
                            <li>
                                <button className="btn form-control" type="submit">Track</button></li></ul></form></div>
                            </div>
                            </div>
                            </div>
                      </div>

    </div>
  )
}
