import axios from 'axios'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import '../../Custom.css'
import '../../Loader.css'
import banner from '../../images/banners/login.jpg';
import banner2 from '../../images/banners/fine-arts.jpg';
import banner3 from '../../images/banners/fine-arts-2.jpg';
import banner4 from '../../images/banners/fine-arts-3.jpg';
export default function Fine_arts_enquries() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [enquiry, setenquiry] = useState({
    name:'',
    phone:''
    })
  
    const [sector, setsector] = useState([])
    const [stsmsg, setstsmsg] = useState()
    const [loading, setloading] = useState('')
    //const API_PATH='http://localhost:8000/send_enquiry.php';
    const API_PATH=`${SERVER_URL}fine_arts_enquiry.php`;

    console.log(sector);
   function sendEnquiry(){
    if(enquiry.name!="" && enquiry.phone!="" && enquiry.company_name!="" && enquiry.parcelcount!=""&& enquiry.address!=""&& enquiry.city!=""){
        setstsmsg('');
        setloading('show');
        console.log(enquiry);
       axios({
            method:"post",
            url:`${API_PATH}`,
            data:{enquiry:enquiry,sector:sector}
        })
        .then((res)=>{
            console.log(res.data)        
            setloading('');
            setstsmsg(res.data.message)
        })
        .catch(err=>console.log(err))
   }else{
    setstsmsg('Please enter the required fields')
   }
}
  return (
    <div className="salequery">
        <Helmet>
        <title>Time Express | Fine Arts Enquiries</title>
      </Helmet>
       <section className="your_business_page fw bgN" style={{ backgroundImage:  `url(${banner4})`, backgroundAttachment: 'fixed', backgroundSize: '', padding: '200px 0px 80px' }}>
               <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">FINE ARTS ENQUIRIES</h1>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
                <div className="formsec" style={{background:"#fff",padding:"4% 3% 7% 3%"}}>
            <div className="entry-content">
<div role="form" className="wpcf7" id="wpcf7-f159-p160-o1" lang="en-US" dir="ltr">
<div className="screen-reader-response">
<p role="status" aria-live="polite" aria-atomic="true" />
<ul />
</div>
<form  className="wpcf7-form init" noValidate="novalidate" data-status="init">
<div style={{display: 'none'}}> <input type="hidden" name="_wpcf7" defaultValue={159} /> <input type="hidden" name="_wpcf7_version" defaultValue="5.4.2" /> <input type="hidden" name="_wpcf7_locale" defaultValue="en_US" /> <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f159-p160-o1" /> <input type="hidden" name="_wpcf7_container_post" defaultValue={160} /> <input type="hidden" name="_wpcf7_posted_data_hash" defaultValue />
</div>
<div className="form_enquiry">
<div className="row">
<div className="col-md-12">
<h3 style={{paddingTop: '0px'}}>Your contact details</h3>
</div>
<div className="col-md-6">
<div className="form-group"> <label>Full Name*</label>
<br /> <span className="wpcf7-form-control-wrap FirstName">
<input type="text" name="FirstName" onChange={(e)=>setenquiry({...enquiry,name:e.target.value})}  size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="fullname" aria-required="true" aria-invalid="false" placeholder="Please Enter Your First Name" />
</span>
</div>
<div className="form-group"> <label>Company Name*</label>
<br /> <span className="wpcf7-form-control-wrap CompanyName">
<input type="text" name="CompanyName" onChange={(e)=>setenquiry({...enquiry,company_name:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="company" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Company Name" />
</span>
</div>
<div className="form-group"> <label>Address*</label>
<br /> <span className="wpcf7-form-control-wrap Address">
<input type="text" name="Address" onChange={(e)=>setenquiry({...enquiry,address:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="address" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Address" />
</span>
</div>
<div className="form-group"> <label>Town/City*</label>
<br /> <span className="wpcf7-form-control-wrap TownCity">
<input type="text" name="TownCity" onChange={(e)=>setenquiry({...enquiry,city:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="town" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Town or City" />
</span>
</div>
</div>
<div className="col-md-6">
<div className="form-group"> <label>Location Makani Code</label>
<br /> <span className="wpcf7-form-control-wrap CompanyMakanicode">
<input type="text" name="CompanyMakanicode" onChange={(e)=>setenquiry({...enquiry,zipcode:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text form-control" id="cpost" aria-invalid="false" placeholder="Please Enter Your Company Makani code" />
</span>
</div>
<div className="form-group"> <label>Nearest Landmank</label>
<br /> <span className="wpcf7-form-control-wrap NearestLandmark">
<input type="text" name="NearestLandmark" onChange={(e)=>setenquiry({...enquiry,landmark:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text form-control" id="dpost" aria-invalid="false" placeholder="Please Enter Your Nearest Landmark" />
</span>
</div>
<div className="form-group"> <label>Email Address*</label>
<br /> <span className="wpcf7-form-control-wrap email">
<input type="email" name="email" style={{height: "46px", backgroundColor: "#f7f7f7", borderRadius: "2px"}} onChange={(e)=>setenquiry({...enquiry,email:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Email Address" />
</span>
</div>
<div className="form-group"> <label>Telephone*</label>
<br /> <span className="wpcf7-form-control-wrap telephone">
<input type="tel" name="telephone" style={{height: "46px", backgroundColor: "#f7f7f7", borderRadius: "2px"}} onChange={(e)=>setenquiry({...enquiry,phone:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel form-control" id="tel" aria-required="true" aria-invalid="false" placeholder="Please Enter Your Telephone" />
</span>
</div>
</div>
<hr style={{borderColor: '#ccc', borderWidth: '2px', float: 'left', margin: '40px 0 60px', width: '100%'}} />
<div className="col-md-12">
<h3 style={{paddingTop: "0px"}}>A little about your business</h3>
</div>
<div className="col-md-6">
<div className="form-group"> <label>Shipments Sent Per Year*</label>
<br /> <span className="wpcf7-form-control-wrap ParcelsSentPerYear">
<input type="number" name="ParcelsSentPerYear" onChange={(e)=>setenquiry({...enquiry,parcelcount:e.target.value})} className="wpcf7-form-control wpcf7-number wpcf7-validates-as-number form-control" id="parcel" aria-invalid="false" placeholder="Please enter a value for volume" />
</span>
</div>
<div className="form-group"> <label>Gallery</label>
<br /> <span className="wpcf7-form-control-wrap B2B">
<input type="number" name="gallery" onChange={(e)=>setenquiry({...enquiry,gallery:e.target.value})} className="wpcf7-form-control wpcf7-number wpcf7-validates-as-number form-control" id="gallery" aria-invalid="false" placeholder="" />
</span>
</div>
<div className="form-group"> <label>Private Collection</label>
<br /> <span className="wpcf7-form-control-wrap B2C">
<input type="number" name="pc" onChange={(e)=>setenquiry({...enquiry,pc:e.target.value})} className="wpcf7-form-control wpcf7-number wpcf7-validates-as-number form-control" id="pc" aria-invalid="false" placeholder="" />
</span>
</div>
<div className="form-group"> <label>Existing Gallery?</label>
<br /> <span className="wpcf7-form-control-wrap couriers">
<input type="text" name="eg" onChange={(e)=>setenquiry({...enquiry,eg:e.target.value})} size={40} className="wpcf7-form-control wpcf7-text form-control" style={{height: "38px", backgroundColor: "#fff"}} id="eg" aria-invalid="false" placeholder="" />
</span>
</div>
<div className="form-group dum_reilan"> <label>How did you find out about Timex Fine Arts?</label>
<br /> <span className="wpcf7-form-control-wrap Courier">
<select name="Courier" onChange={(e)=>setenquiry({...enquiry,method:e.target.value})} className="wpcf7-form-control wpcf7-select form-control" id="courier" aria-invalid="false">
<option value="Please Select">Please Select</option>
<option value="Recommended">Recommended</option>
<option value="Trade show">Trade show</option>
<option value="Trade press">Trade press</option>
<option value="Word of mouth">Word of mouth</option>
<option value="Google">Google</option>
<option value="Direct Marketing">Direct Marketing</option>
<option value="Reseller">Reseller</option>
<option value="Existing Client">Existing Client</option>
<option value="Previously traded">Previously traded</option>
<option value="Previous contact">Previous contact</option>
<option value="Other">Other</option>
</select>
</span>
</div>
</div>
<div className="col-md-6">

<div className="form-group sectors_reilan"> 
    <label style={{display: 'block'}}>Sector</label>
    <br /> 
    <span className="wpcf7-form-control-wrap Sectors">
        <span className="wpcf7-form-control wpcf7-checkbox wpcf7-exclusive-checkbox sectors" id="sectors">				
            
            <span className="wpcf7-list-item first">
            <input type="checkbox" name="Sectors" onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="Specialized Transportation for Fine Arts" />
            <span className="wpcf7-list-item-label">Specialized Transportation for Fine Arts</span>
            </span>			
            
            <span className="wpcf7-list-item">
            <input type="checkbox" name="Sectors"  onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="Exhibition and gallery logistics" />
            <span className="wpcf7-list-item-label">Exhibition and gallery logistics</span>
            </span>			
            
            <span className="wpcf7-list-item">
            <input type="checkbox" name="Sectors"  onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="Installations and handling of Valuable Art Pieces" />
            <span className="wpcf7-list-item-label">Installations and handling of Valuable Art Pieces</span>
            </span>			
            
            <span className="wpcf7-list-item">
            <input type="checkbox" name="Sectors"  onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="Customised Crating" />
            <span className="wpcf7-list-item-label">Customised Crating</span>
            </span>			
            
            <span className="wpcf7-list-item">
            <input type="checkbox" name="Sectors"  onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="Fine art storage" />
            <span className="wpcf7-list-item-label">Fine art storage</span>
            </span>		
            
            <span className="wpcf7-list-item">
            <input type="checkbox" name="Sectors"  onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="Consultancy" />
            <span className="wpcf7-list-item-label">Consultancy</span>
            </span>	
            
            <span className="wpcf7-list-item">
            <input type="checkbox" name="Sectors"  onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="Sculptures Handling and Installations" />
            <span className="wpcf7-list-item-label">Sculptures Handling and Installations</span>
            </span>
            
            <span className="wpcf7-list-item">
            <input type="checkbox" name="Sectors"  onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="Freight and Customs Clearance for Art Works" />
            <span className="wpcf7-list-item-label">Freight and Customs Clearance for Art Works</span>
            </span>				
                        
            <span className="wpcf7-list-item last">
            <input type="checkbox" name="Sectors"  onChange={(e)=>{ if (e.target.checked) {setsector([...sector,{id:e.target.value}])}else{ setsector(sector.filter((sector1) => sector1.id !== e.target.value))}}} defaultValue="Other" />
            <span className="wpcf7-list-item-label">Other</span>
            </span>
            
        </span>
    </span>
</div>



</div>
<div className="col-md-12">
<div className="form-group textarea_reilan"> <label className="textarea_label "> Any further information?</label>
<br /> <span className="wpcf7-form-control-wrap your-message">
<textarea name="your-message" style={{height: "180px"}} onChange={(e)=>setenquiry({...enquiry,message:e.target.value})} cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Write additional information in this box..." defaultValue={""} />
</span>
</div>
</div>
<div className="sumbit_btn" style={{textAlign: 'center', float: 'none'}}>
{/* <input type="submit" defaultValue="SUBMIT" className="wpcf7-form-control wpcf7-submit" /> */}

{
     loading != ""?(
        <>
            <button disabled type="button" onClick={sendEnquiry}>Submit</button>
            <span className="n-loader"></span><span>Loading...</span>
        </>
     ):(
        <>            
            <button type="button" onClick={sendEnquiry}>Submit</button>
        </>
     )
}
</div>
<p style={{textAlign:"center"}}>{stsmsg}</p>
</div>
</div>
<div className="wpcf7-response-output" aria-hidden="true" />
</form>
</div>
<h1 style={{display: 'none'}}>Sales Enquiries</h1>
</div>
</div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
