import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import '../../Custom.css'
import banner from '../../images/banners/login.jpg'
export default function Open_wallet() {
  return (
    <div className="improvelive">
       <Helmet>
        <title>Time Express | Open Wallet Account</title>
      </Helmet>
      <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                 <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                        <h1 className="mainhead">OPEN A WALLET ACCOUNT</h1>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content improvelive">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
                <div className="sec">
                <div className="entry-content">
<div className="become_driver rate-national">
<div className="border_text">
<h1 style={{display: 'none'}}>Open a wallet account</h1>
<h3>Discounts on pre-payment</h3>
<h4>6 EASY STEPS TO OPEN YOUR ACCOUNT</h4>
<p>By creating a Time Express account you will get the following flexibilities.</p>

<p>• Speed your way through the checkout</p>
<p>• Avoid having to pay by credit card or cash for every transaction</p>
<p>• Discount on bundle Wallet payments</p>
<p>• Track your orders easily</p>
<p>• Keep a record of all your payments and bookings</p>

<p>Obtain discounted rates for door-to-Door parcels managed from one portal for all your express shipping needs; if you require to speak to someone you may email a Call Back Request in subject line. To create a wallet account please follow below process;</p>

<p>• Complete and submit the online account registration form</p>
<p>• Receive an email with your user name and password</p>
<p>• Go to the <Link to="/register">REGISTER</Link> section</p>
<p>• Select “MY WALLET” from left hand side menu</p>
<p>• Click on “PAY NOW” and select the amount you want to deposit</p>
<p>• Book your parcel and simply pay using the “PAY BY WALLET” option</p>

</div>
<p>
<Link to="/register" style={{padding: '14px 16px', backgroundColor: 'purple', display: 'inline-block', color: '#fff', marginTop: '15px',textDecoration:"none",borderRadius:"6px"}}>Register Now</Link>
</p>
</div>
</div>
                </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
