import React from 'react'
import laptop from '../../images/How_it_works.png'
import blog1 from '../../images/blogs/1.jpg'
import blog2 from '../../images/blogs/2.png'
import blog3 from '../../images/blogs/3.png'
import blog4 from '../../images/blogs/4.jpg'
import blog5 from '../../images/blogs/5.jpg'
import blog6 from '../../images/blogs/6.png'
import blog7 from '../../images/blogs/7.png'
import blog8 from '../../images/blogs/8.png'
import blog9 from '../../images/blogs/9.png'
import blog10 from '../../images/blogs/10.jpg'
import blog11 from '../../images/blogs/11.jpg'
import blog12 from '../../images/blogs/12.jpg'
import blog13 from '../../images/blogs/13.jpg'
import blog14 from '../../images/blogs/14.jpeg'
import blog15 from '../../images/blogs/15.jpg'
import label from '../../images/label.jpg'
import banner from '../../images/banners/login.jpg'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
export default function Articles() {
  return (
    <div>
           <Helmet>
        <title>Time Express | Blogs</title>
      </Helmet>
          <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
             <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                 
                            <h1 className='h1-title'>WELCOME TO TIME EXPRESS BLOGS</h1>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
               <div className="row">
               <div className="container-fluid py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5" style={{maxWidth: '500px'}}>
            {/* <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Blog Post</h5> */}
            <h1 className="display-4">Our Blogs</h1>
          </div>
          <div className="row g-5">
          <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog15} alt="" />
                <div className="p-4">
                  <h4>How To Send A Parcel For Less
With Time Express
</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
               <Link to="/blogs/how-to-send-a-parcel-for-less-with-time-express"> <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></Link>
             
                </div>
              </div>
            </div>
          <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog14} alt="" />
                <div className="p-4">
                  <h4>Time Express Parcels helps B2B & B2C…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
               <Link to="/blogs/expand-your-brand-into-gcc"> <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></Link>
             
                </div>
              </div>
            </div>
          <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog1} alt="" />
                <div className="p-4">
                  <h4>Shipping to the UAE has never been…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
               <Link to="/blogs/incredible-logistics"> <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></Link>
                 
                </div>
              </div>
            </div>
           
            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog2} alt="" />
                <div className="p-4">
                  <h4>Accessing New Markets for Growth...</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
                <Link to="/blogs/global-reach">  <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></Link>
              
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog3} alt="" />
                <div className="p-4">
                  <h4>Do You Need to Register a Company…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
                <Link to="/blogs/competitive-prices">  <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></Link>
                 
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog4} alt="" />
                <div className="p-4">
                  <h4>Becoming an Entrepreneur in the Logistics Industry</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
                <a href="https://middleeast.businesschief.com/signin" target="_blank"> <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></a>
                 
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog5} alt="" />
                <div className="p-4">
                  <h4>Shopping online, internationally? …</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
                <a href="https://gulfnews.com/living-in-uae/ask-us/shopping-online-internationally-here-is-how-you-can-calculate-customs-charges-to-the-uae-1.1590686274342" target="_blank">  <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></a>
                 
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog6} alt="" />
                <div className="p-4">
                  <h4>Middle East Customs Guide January 2019…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
                <a href="https://mydhl.express.dhl/ae/en/ship/delivery-services.html" target="_blank"><button style={{minWidth:'100px'}} className="blogbtn">Read More</button></a>
                 
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog7} alt="" />
                <div className="p-4">
                  <h4>DHL eCommerce Solutions…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
                <a href="https://www.dhl.com/content/dam/dhl/local/au/dhl-ecommerce/documents/pdf/au-dhl-ecommerce-toolkit-au-classic.pdf" target="_blank"><button style={{minWidth:'100px'}} className="blogbtn">Read More</button></a>
                 
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog8} alt="" />
                <div className="p-4">
                  <h4>Packing Guide…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
                <a href="https://www.dhl.com/content/dam/downloads/g0/express/shipping/packaging/dhl_express_packing_guide_en.pdf" target="_blank"> <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></a>
                 
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog9} alt="" />
                <div className="p-4">
                  <h4>Five Keys to Overcoming…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
               <Link to="/blogs/overcoming-dedicated-fleet-productivity"> <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></Link>
                 
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog10} alt="" />
                <div className="p-4">
                  <h4>Time Express: Top 10 ME supply…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
                <a href="https://businesschief.eu/top10/time-express-top-10-me-supply-chain-and-logistics-trends" target="_blank">  <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></a>
                 
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog11} alt="" />
                <div className="p-4">
                  <h4>Compelling Reasons Why You Should…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
                <Link to="/blogs/7-compelling-reasons-why-you-should-use-a-parcel-service-reseller"><button style={{minWidth:'100px'}} className="blogbtn">Read More</button></Link>
                 
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog12} alt="" />
                <div className="p-4">
                  <h4>How to save costs in shipping…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
               <Link to="/blogs/how-to-save-costs-in-shipping"> <button style={{minWidth:'100px'}} className="blogbtn">Read More</button></Link>
                 
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6">
              <div className="bg-light rounded overflow-hidden">
                <img className="img-fluid w-100" src={blog13} alt="" />
                <div className="p-4">
                  <h4>How to take your business online…</h4>
                  </div>
                  
                <div className="justify-content-between border-top p-4" style={{textAlign:'center'}}>
               <Link to="/blogs/how-to-take-your-business-online/"><button style={{minWidth:'100px'}} className="blogbtn">Read More</button></Link> 
                 
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
               </div>
          
    </div>
    </div>
    </main>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
