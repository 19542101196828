import React from 'react'

import blog1 from '../../../images/blogs/1.jpg'

import banner from '../../../images/banners/login.jpg'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
export default function IncredibleLogistics() {
  return (
    <div>
           <Helmet>
        <title>Time Express | Articles</title>
      </Helmet>
          <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
             <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                            <h2>INCREDIBLE LOGISTICS</h2>
                         
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <div className="pages_content">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
           <div className="entry-content">
           <div className="become_driver">
               <div className="row">
               <div className="container-fluid py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5" >
            <img src={blog1} style={{width:'100%'}} />
          </div>
          <div className="row ">
            <div className="col-md-12">
              <div className="bg-light rounded overflow-hidden">
              <h4>Shipping to the UAE has never been easier interesting facts on United Arab Emirates</h4>
              <ul className='bloglist'>

              <li>Less than 10% of are Emiratis – the rest are expatriates from over 200 countries residing in the UAE.</li>
<li>Jebel Ali Free Trade Zone hosts 7,100 companies including over 160 of the global Fortune 500.</li>
<li>Top 10 ports: Jebel Ali Port DP World handles 15 million containers per year and is one of the Top 10 Global Ports.</li>
<li>Jebel Ali Port handles over 45% of transshipment containers which are resent into GCC.</li>
<li>UAE economy based on Oil & Gas, but tourism and hospitality generate most of Dubai income.</li>
<li>Home to world’s largest shopping mall Dubai Mall, tallest building Burj Khalifa and longest automated metro system.</li>
<li>Brand Dubai: instantly recognizable landmark destination with unique global identity.</li>
<li>Top 10 Cargo Airports: Dubai World Central (DWC) New Airport handles over 220,000 tons of cargo per year (the size of London Heathrow + Chicago Ohara airports combined).</li>
<li>Expo 2020 will be hosted in Dubai in October 20 2020 with a theme of Connecting Minds, Creating the Future” to make a difference in future of humanity.</li>
<li>The 3 themes for Expo 2020 are: Opportunity, Mobility, Sustainability.</li>
<li>Expo 2020 expenditure is expected to be $18.1 bi, there will be 25 Million visitors expected to attend Expo 2020 in Dubai.</li>
              </ul>
              </div>
            </div>
            
          </div>
        </div>
      </div>
               </div>
          
    </div>
    </div>
    </main>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
