import React from 'react'
import { Helmet } from 'react-helmet';
import '../../Custom.css'
import banner from '../../images/banners/driver.jpg'
export default function Become_driver() {
  return (
    <div className="becomedriver">
          <Helmet>
        <title>Time Express | Become A Driver</title>
      </Helmet>
       <section className="your_business_page fw" style={{ background: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                <div className="container"><div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                           
                            <h1 className='h1-title'>BECOME A DRIVER</h1>
                           
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <div className="pages_content ">
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content">
<div className="become_driver post_driver ">
<div className="ourservies"><h2>MAKE GOOD MONEY.</h2>
<h2>DRIVE WHEN YOU WANT. NO OFFICE, NO BOSS.</h2>
<h2>WHY DRIVE WITH TIME EXPRESS PARCELS</h2><h2>SET YOUR OWN TIME SCHEDULE</h2>
<p>&nbsp;</p>
<p>When you drive with Time Express Parcels, you decide when and how long to work. So you’ll never have to choose between earning a living and living life.</p>
<p><strong>EARN ON YOUR OWN MONEY. </strong>. It’s easy to make money helping people get around. How much is completely up to you. With so many riders in every city, there’s opportunity at every turn.</p>
<p><strong>LET THE APP SHOW YOU THE WAY. </strong>Just tap our App and go. You’ll get the jobs you need to deliver and turn-by-turn directions, tools to help you earn more, and 24/7 support. If you don’t have a smartphone, we can connect you to one.</p>
<p><strong>HOW IT WORKS. </strong>A day behind the wheel… When you’re ready to drive, just open the app and trip requests will start coming in. We’ll show you who you’re picking up and provide directions to their location and destination. Once the trip is complete, we’ll find another nearby request. Shippers are friendly and respectful of your van. And only you decide when to stop driving for the day.</p>
<p><strong>EARNINGS. </strong>We get you the parcels for you to drive and deliver. The fares you earn are automatically deposited in your bank account. Use the app to track how much you make on every trip. For making the app available to you, we deduct a service fee.</p>
<p><strong>REWARDS. </strong>You’re in the driver’s seat to lead your life. So reward yourself with discounts on fuel, vehicle maintenance, cell phone bills, and more. Be your own boss. Reduce your daily expenses and take home extra money—the more you drive, the more you earn.</p>
<p><strong>BECOME A DRIVER. </strong>It’s easy to get started, SIGN UP IN MINUTES AND JOIN OUR TEAM. Tell us a little about yourself and your van.</p>
<p><strong>SHARE SOME DOCUMENTS. </strong>We’ll need to send us to <a href="mailto:info@www.timexpress.ae">info@timexpress.ae</a> your license, registration, proof of insurance, and the necessary information to run a background check.</p>
<p><strong>GET THE APP AND GO. </strong>Once you’re approved to drive with Time Express Parcels as an independent contractor, we’ll provide everything you need to be a success on the road.</p></div>
</div><h1 style={{display: 'none'}}>become driver</h1><p /></div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </div>
  )
}
