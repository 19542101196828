import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import Sidebar from './Sidebar';
import loading from '../../../images/spin.gif'
// import laptopcsv from '../../../images/laptopcsv.PNG'
import laptopcsv2 from '../../../images/laptopcsv-2.png'
// import laptopcsv3 from '../../../images/laptopcsv-3.png'
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCloudArrowUp,faCloudArrowDown,faCheck,faFileCsv,faMedal,faRulerHorizontal,faFileImport,faCubesStacked,faUser } from '@fortawesome/free-solid-svg-icons'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

export default function Bulk_orders() {
  const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const [modalIsOpen2, setmodalIsOpen2] = useState(false)
  const [modalIsOpen3, setmodalIsOpen3] = useState(false)
  const [showload, setshowload] = useState(false)
  const uploadicon = <FontAwesomeIcon icon={faCloudArrowUp} style={{height:'15px'}} />
  const downloadicon = <FontAwesomeIcon icon={faCloudArrowDown} style={{height:'15px'}}/>
  const circle = <FontAwesomeIcon icon={faUser} className="bulkicons" />
  const csvfile = <FontAwesomeIcon icon={faFileImport} className="bulkicons" />
  const medal = <FontAwesomeIcon icon={faCubesStacked} className="bulkicons" />
  const dash = <FontAwesomeIcon icon={faRulerHorizontal} />
 
  function setModalIsOpenToFalse() {
    setmodalIsOpen(false)
  }

  function setModalIsOpenToTrue() {
    setmodalIsOpen(true)
  }

  function setModalIsOpenToFalse2() {
    setmodalIsOpen2(false)
  }

  function setModalIsOpenToFalse3() {
    setmodalIsOpen3(false)
  }
const [invoiceid, setinvoiceid] = useState()
  function setModalIsOpenToTrue2(orderid) {
    setinvoiceid(orderid)
    setmodalIsOpen2(true)
  }

  const [loadingshow, setloadingshow] = useState(false)
  const [error, seterror] = useState()
  const [passsuccess, setpasssuccess] = useState()
  const [parcelcount, setparcelcount] = useState(0)
  const [parceldetailscount, setparceldetailscount] = useState(0)
  const [parceldetailscontent, setparceldetailscontent] = useState('')
  const [selectedparcels, setselectedparcels] = useState([])
  const [selectedparcelsrate, setselectedparcelsrate] = useState([])
  const [image_info, setimage_info] = useState({
    filedetails: {
      ordercsv: ''
    }

  })

  function updatefiles(val, k) {
    const clonedListing = { ...image_info.filedetails };

    setimage_info({
      filedetails: {
        ...clonedListing,
        ordercsv: val
      }
    });

  }

  const [invoices, setinvoices] = useState({
    filedetails: {
      invoice: ''
    }

  })

  function updateinvoicefiles(val, k, invoiceval) {
    const clonedListing = { ...invoices.filedetails };

    setinvoices({
      filedetails: {
        ...clonedListing,
        invoice: val
      }
    });

  }
const [errormsg, seterrormsg] = useState('')
  const [ordertypes, setordertypes] = useState()
  sessionStorage.removeItem('batch_id');
  const userid = sessionStorage.getItem('userid');
  // const batch_id = sessionStorage.getItem('batch_id');
  const [batch_id, setbatch_id] = useState()
  function upload_csv() {
    seterrormsg('')
    setloadingshow(true)
    setselectedparcels([])
    selectedparcels.splice()
    const data = new FormData();
    data.append("ordercsv[]", image_info.filedetails.ordercsv);
    data.append("userid", userid);
    
    setordertypes(showblock)
    data.append("ordertypes", showblock);
    let url = `${SERVER_URL}bulk_upload.php`;

    axios.post(url, data, {
    })
      .then((res) => { console.log('response:',res.data)
      if(res.data.success=='1'){
        sessionStorage.removeItem('batch_id');
       // console.log('output ', res.data.response_arr)
       sessionStorage.setItem('batch_id',res.data.batch_id)
        setloadingshow(false)
        // get_batches(res.data.batch_id)
        setbatch_id(res.data.batch_id)
      
res.data.response_arr.forEach(orders => {

  selectedparcels.push(orders.orderid)
 selectedparcelsrate.push(orders.rate)
});
        setparceldetailscontent(res.data.response_arr)
        setparceldetailscount(res.data.response_arr.length)
        console.log(res.data.response_arr.length)
       // getbatches(res.data.batch_id)
        toggleModal()
}else{
  setloadingshow(false)
  seterrormsg(res.data.error_msg)
}
      }, error => {
        alert(error);


      });
  }

  const [isChecked, setisChecked] = useState(true)
const [invoiceupload, setinvoiceupload] = useState(false)
  const [fileuploadmsg, setfileuploadmsg] = useState('')
  function upload_invoice(){
       const data= new FormData();
       data.append('orederid',invoiceid);
       data.append("invoice[]", invoices.filedetails.invoice);
       let url=`${SERVER_URL}invoice_upload.php`
       axios.post(url,data,{})
       .then((res)=>{console.log('invoice_',res.data)
       setfileuploadmsg('invoice uploaded successfully')
       setinvoiceupload(true)
      //  setTimeout (() => {
      //   setUpmodal(false);
      //   window.location.reload();
      //  }, 1000)

       document.querySelector('.receipt'+invoiceid).innerHTML = 'change invoice';

      //  const boxes = document.querySelectorAll('.box'+invoiceid);
      //  boxes.forEach(box => {
        
      //   box.style.pointerEvents='none';
      // });


       })
       .error((err)=> setfileuploadmsg('error uploading invoice'))

  }

  const [finalprice, setfinalprice] = useState()
  function checkSelected(val,price){

    const uniqueNames =  selectedparcels.filter((item,
      index) => selectedparcels.indexOf(item) === index);
    
    if(uniqueNames.includes(val)){
 var index = uniqueNames.indexOf(val);
 uniqueNames.splice(index, 1);
 
setselectedparcels(uniqueNames)
 var index2 = selectedparcelsrate.indexOf(price);
 selectedparcelsrate.splice(index2, 1);
//  setfinalprice((parseFloat(finalprice) + 10.00).toFixed(2))
 setfinalprice((parseFloat(finalprice) - parseFloat(price)).toFixed(2))
    }else{

      const uniqueNames =  selectedparcels;
        uniqueNames.push(val)
 selectedparcelsrate.push(price)
 

setselectedparcels(uniqueNames)
 setfinalprice((parseFloat(finalprice) + parseFloat(price)).toFixed(2))
    }
  
  }

  console.log('the selected:',selectedparcels)

 function getbatches(val){alert(1)
  getTotalprice(val);
  setparceldetailscontent('');
  setparceldetailscount('');
  const data= new FormData();
  data.append('batch_id',val);
  data.append("userid", userid);
  data.append("current", "");
  let url=`${SERVER_URL}get_batch_orders.php`
  axios.post(url,data,{})
.then((res)=>{console.log(res.data)

  // res.data.response_arr.forEach(orders => {
 
  //   selectedparcels.push(orders.orderid)
  //  selectedparcelsrate.push(orders.rate)
  // });

  setparceldetailscontent(res.data.response_arr)
  setparceldetailscount(res.data.response_arr.length)
})
.catch(err=>console.log(err))

 }

useEffect(()=>{

  getTotalprice(batch_id);
  setparceldetailscontent('');
  setparceldetailscount('');
  const data= new FormData();
  data.append('batch_id',batch_id);
  data.append("userid", userid);
  data.append("current", "");
  let url=`${SERVER_URL}get_batch_orders.php`
  axios.post(url,data,{})
.then((res)=>{console.log(res.data)

  res.data.response_arr.forEach(orders => {
 
    selectedparcels.push(orders.orderid)
   selectedparcelsrate.push(orders.rate)
  });

  setparceldetailscontent(res.data.response_arr)
  setparceldetailscount(res.data.response_arr.length)
})
.catch(err=>console.log(err))
},[batch_id])

function findsum(){
return selectedparcelsrate;
}

function setModalIsOpenToTrue3(batch) {

  setmodalIsOpen3(true)
}


const [wallet, setwallet] = useState()
const [insuff, setinsuff] = useState(false)
const [paymentmethod, setpaymentmethod] = useState('Online')
const [paynow, setPaynow] = useState(false);
const [balshow, setBalshow] = useState(false);
const [ballow, setBallow] = useState(false);
function alterpaymethod(val) {
  setPaynow(false);   
  setBalshow(false);
  setBallow(false);
  if (val == 'COC' || val == 'COD') {
    setPaynow(true);
    if (paymentmethod != val) {
      setpaymentmethod(val)
     console.log(parseFloat(finalprice))
      setfinalprice((parseFloat(finalprice) + 10.00).toFixed(2))
    }

  } else if (val == 'PBW') {    
    setBalshow(true);
    if (paymentmethod != val) {
      if (paymentmethod == 'COD' || paymentmethod == 'COC') {
        setfinalprice((parseFloat(finalprice) - 10.00).toFixed(2))
      }

      const API_PATH2 = `${SERVER_URL}get_wallet.php`;

      axios({
        method: "post",
        url: `${API_PATH2}`,
        data: userid
      })
        .then((res) => {
          console.log(userid)
          setwallet(res.data.wallet)
          if (Number(finalprice) > Number(res.data.wallet)) {
            setinsuff(true)         
            setBallow(true);
          }
          else{
            setPaynow(true);
          }
          setpaymentmethod(val)
        })
        .catch((err) => console.log(err))
    }
  } else {
    setPaynow(true);
    if (paymentmethod != val) {
      if (paymentmethod == 'COD' || paymentmethod == 'COC') {
        setfinalprice((parseFloat(finalprice) - 10.00).toFixed(2))
      }
      setpaymentmethod(val)


    }
  }

}

// const [awbcontent, setawbcontent] = useState('')
// const [awbcount, setawbcount] = useState('')
const navigate = useNavigate();
function pay_batchuplod(){
  const API_PATH2 = `${INTEGRA_URL}create_batch_awb.php`;
  setshowload(true)
  
  if(paymentmethod == "COC" || paymentmethod == "COD" || paymentmethod == "PBW"){    
   
    axios({
      method: "post",
      url: `${API_PATH2}`,
      data: {userid:userid,paymentmethod:paymentmethod,selectedparcels:selectedparcels,batch_id:batch_id, finalprice:finalprice,ordertype:ordertypes}
    })
      .then((res) => {
        console.log('awb response: ',res.data)
        setshowload(false)
        // ,awbcontent:res.data.awbresult,awbcount:res.data.awbresult.length
       if(ordertypes=='domestic'){
       navigate('/bulk-domestic-awb-label', {state:{userid:userid,paymentmethod:paymentmethod,selectedparcels:selectedparcels,batch_id:batch_id, finalprice:finalprice}})
   
       }else{
       navigate('/bulk-awb-label', {state:{userid:userid,paymentmethod:paymentmethod,selectedparcels:selectedparcels,batch_id:batch_id, finalprice:finalprice}})
   
       }
      
      })
      .catch((err) => console.log(err))

  }else{
    
    const API_payment = `${SERVER_URL}generate_bulk_payment.php`;
    axios({
      method: 'post',
      url: `${API_payment}`,
      data: {userid:userid,finalprice:finalprice,batch_id:batch_id,selectedparcels:selectedparcels,ordertypes:ordertypes}
    })
      .then((res) => {
        console.log(res.data)
        //setloadingshow(false)
        //navigate(res.data)

    navigate('/checkout', { state: { orderno:batch_id, payment_page: res.data } })

      })
      .catch((err) => console.log(err));

  }
}


const [rcmodal, setCmodal] = useState(false);
const toggleModal = () => {
  seterrormsg('')
  setCmodal(!rcmodal);
  loadingshow(false)
 
};

const [upmodal, setUpmodal] = useState(false);
  function setModalIsOpenToTrue44(orderid) {
    setinvoiceid(orderid);
    setUpmodal(!upmodal);    
    setfileuploadmsg();
  }

const [paymodal, setPaymodal] = useState(false);
  function setModalIsOpenToTrue33(orderid) {
    setPaymodal(!paymodal);  
    setPaynow(false);
    setBalshow(false);    
    setBallow(false);
  }

const getTotalprice = async ($type = "") => {
  const API_PATH = `${SERVER_URL}get_total_orderprice.php`;
  const passbatch_id = {
    type: "getTotal",
    batch_id: $type.toString()
  };

  await axios
    .post(API_PATH, passbatch_id)
    .then(res => {
      console.log(res);
      setfinalprice(res.data.final_price);
    })
    .catch((err) => {
      console.log(err);
    })
}

const [showblock, setshowblock] = useState('')
function displayBlock(val){

 if(showblock==val){
  setshowblock('')
 }else{
  setshowblock(val)
 }
}



  return (
    <div className="wallet-plan bulkupload">
      <Helmet>
        <title>Time Express | Bulk Orders</title>
      </Helmet>



<Tooltip id="export_tooltip" />
<Tooltip id="import_tooltip" />
<Tooltip id="domestic_tooltip" />

      <div className="container myparctab bulkupload" >
        {/* Upload CSV Modal */}
          <button onClick={toggleModal} className="btn-rcmodal" style={{ display: "none" }}>Open</button>    
          {rcmodal && (
            <div className="rcmodal">
              <div onClick={toggleModal} className="rcoverlay"></div>
              <div className="rcmodal-content">
                <h5>Upload File</h5>
                <h4>Drag &amp; Drop to Upload</h4>
                <p>or</p>
                <label>Upload Your File</label>
                <p style={{ height: "1px" }}></p>
                <input type="file" className="inp-file" onChange={(e) => updatefiles(e.target.files[0], 'ordercsv')} />
                
                <p style={{ height: "1px" }}></p>
                <button type="button" className="btn-rmodal" onClick={upload_csv}>Upload File</button>
                {loadingshow?(
      <p style={{textAlign:"center",marginTop:"2%"}}><img src={loading} style={{width:"5%"}}/></p> 
    ):null}
    <p style={{color:'red'}}>{errormsg}</p>
                <button className="close-rcmodal" onClick={toggleModal}><strong></strong>x</button>
              </div>
            </div>
          )}
          {/* End Upload CSV Modal */}

          
        {/* Upload Invoice Modal */}
        <button onClick={setModalIsOpenToTrue44} className="btn-rcmodal" style={{ display: "none" }}>Open</button>    
          {upmodal && (
            <div className="rcmodal">
              <div onClick={setModalIsOpenToTrue44} className="rcoverlay"></div>
              <div className="rcmodal-content">
                <h5>Upload Invoice -{invoiceid}</h5>
                <h4>Drag &amp; drop to upload</h4>
                <p>or</p>
                <label>upload your file</label>

                <input type="file" className="inp-file" onChange={(e) => updateinvoicefiles(e.target.files[0], 'invoice',invoiceid)} />

                <p style={{ height: "1px" }}></p>
                <button type="button" className="btn-rmodal" onClick={upload_invoice}>Upload Invoice</button>
                {
                  fileuploadmsg?(
                    <p >{fileuploadmsg}</p>
                  ):null
                }

                <button className="close-rcmodal" onClick={setModalIsOpenToTrue44}><strong></strong>x</button>
              </div>
            </div>
          )}
          {/* End Upload Invoice Modal */}

          {/* Payment Modal */}
            <button onClick={setModalIsOpenToTrue33} className="btn-rcmodal" style={{ display: "none" }}>Open</button>    
              {paymodal && (
                <div className="rcmodal">
                  <div onClick={setModalIsOpenToTrue33} className="rcoverlay"></div>
                  <div className="rcmodal-content payment">
                    {/* <h5>Choose Payment Option</h5> */}
                    <h4 style={{fontWeight: '600'}}>- Choose Payment Option -</h4>
                    
                    <p style={{ height: "15px" }}></p>

                    <div className="row">
                      <p className='ptotal'>Total Amount:{finalprice}</p>                      
                      <p style={{ height: "15px" }}></p>
                      <div className="col-md-4"><input type="radio" name="pay_method" value="online"  onClick={() => alterpaymethod('Online')}/> Card Payment</div>
                      {ordertypes && ordertypes=='export'?(
                        <div className="col-md-4"><input type="radio" name="pay_method" value="coc" onClick={() => alterpaymethod('COC')}/> Cash On Collection</div>
                     
                      ):ordertypes=='import'?(
<div className="col-md-4"><input type="radio" name="pay_method" value="coc" onClick={() => alterpaymethod('COD')}/> Cash On Delivery</div>
                     
                      ):null}
                       <div className="col-md-4"><input type="radio" name="pay_method" value="pbw" onClick={() => alterpaymethod('PBW')} /> Pay By Wallet</div>
                    </div>
                    
                    <p style={{ height: "15px" }}></p>

                    {balshow &&(  
                      <>
                        <ul className="balance">
                          <li>My Balance</li>
                          <li>AED {wallet}</li>
                        </ul>
                        {ballow &&(
                          <div id="less_wallet" style={{ textAlign: 'center', border: '1px solid red', margin: '0 auto', width: '310px' }}>
                            <p style={{ marginBottom: '6px' }}>Insufficient balance</p>
                            <Link to="/my-account/my-wallet" style={{ marginBottom: '6px' }}>Add Wallet</Link>
                          </div>
                        )}  
                      </>                   
                      
                    )}

                    {paynow &&(
                      
                      <div className="row bulkpaybtn" >
                        <p style={{ height: "15px" }}></p>
                        <button type="button" className="btn-rmodal" onClick={pay_batchuplod} >PAY NOW</button>
                      </div>
                    )}

{showload?(
      <p style={{textAlign:"center",marginTop:"2%"}}><img src={loading} style={{width:"5%"}}/></p> 
    ):null}
                    <button className="close-rcmodal" onClick={setModalIsOpenToTrue33}><strong></strong>x</button>
                  </div>
                </div>
              )}
            {/* End Payment Modal */}



        <Modal isOpen={modalIsOpen} ariaHideApp={false}>
          <>
            <button onClick={setModalIsOpenToFalse} style={{ float: "right" }}>x</button>
            <h5>Upload File1</h5>
            <h4>Drag &amp; drop to upload</h4>
            <p>or</p>
            <label>upload your file</label>

            <input type="file" onChange={(e) => updatefiles(e.target.files[0], 'ordercsv')} />

            <button type="button" className="btn  btn-sm" onClick={upload_csv} style={{ backgroundColor: '#7b3995', color: "#fff", padding: "1%", width: "19%" }}>Upload File</button>

            <div className="modal-footer">
           
              <button type="button" className="btn btn-default" onClick={setModalIsOpenToFalse}>Close</button>
            </div>


          </>
        </Modal>

        <Modal isOpen={modalIsOpen2} ariaHideApp={false}>
          <>
            <button onClick={setModalIsOpenToFalse2} style={{ float: "right" }}>x</button>
            <h5>Upload Invoice -{invoiceid}</h5>
            <h4>Drag &amp; drop to upload</h4>
            <p>or</p>
            <label>upload your file</label>

            <input type="file" onChange={(e) => updateinvoicefiles(e.target.files[0], 'invoice',invoiceid)} />

            <button type="button" className="btn  btn-sm" onClick={upload_invoice} style={{ backgroundColor: '#7b3995', color: "#fff", padding: "1%", width: "19%" }}>Register</button>
            {fileuploadmsg?(
  <p >{fileuploadmsg}</p>
):null}
           
            <div className="modal-footer">
            <button type="button" className="btn btn-default" onClick={setModalIsOpenToFalse2}>Close</button>
            </div>


          </>
        </Modal>



    
        <div className="container-fluid my_quote">
          <div className="row">

            <Sidebar />
            
            <div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">
                <div className="row" style={{textAlign:'center'}}>
                    <div className="col-md-4 circle" style={{position: "relative"}}>
                        {circle}
                        <p style={{fontSize:'13px'}}>Sign in/ Register with your Time Express corporate account</p>
                    </div>
                    <div className="col-md-4 circle" style={{position: "relative"}}>
                        {csvfile}
                        <p style={{fontSize:'13px'}}>Upload your CSV with Bulk Upload</p>
                    </div>
                    <div className="col-md-4">
                        {medal}
                        <p style={{fontSize:'13px'}}>Start sending multiple parcels in one go!</p>
                    </div>
                  
                </div>
                <div className="row" style={{marginTop:'5%'}}>
<div className="col-md-8">
<h4 style={{color:'#2c4079'}}>Upload your Spreadsheets here</h4>
           <p className='stepsupload'>Our Spreadsheet upload tool is flexible and allows you to upload CSV file by mapping your columns to the
            information that we require.
           </p>
           <p className='stepsupload'>Our user manual can be found <a href={require("../../../images/Bulk_Upload.pdf")} download="Bulk_upload_steps" >here</a> and the country codes can be downloaded from <a href={require("../../../images/Country_Codes.pdf")} download="country_codes">here.</a></p>


    <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb",marginTop:"7%" }}>
                <div className="head" style={{ background: "#f5f5f5", padding: "1%" }} onClick={()=>displayBlock('export')}>
                  <h3 className="comaprison_hedding bulkuploadhead" >Bulk Upload Export - International <span className='bulkinfo' data-tooltip-id="export_tooltip" data-tooltip-content="For your international shipments departing from the
UAE">?</span><span style={{float:'right'}} >+</span></h3>
                 </div>
                {showblock && showblock=='export'?(
 <div className="row" style={{ textAlign: "center", padding: "15px", margin: "15px"}} >
 <div className="col-md-12">
<div className="row">
  <div className="col-md-6">
  <a href={require("../../../images/bulk_template/Bulk_upload_export.csv")} download="Bulk_export" className="panel-title my-wallet-rec " style={{ margin: '0 0 10px', position: 'relative', display: 'inline-block', backgroundColor: '#d85b12', color: '#fff' }}>{downloadicon} Download Template </a>
                 

  </div>
  <div className="col-md-6">
  <a onClick={toggleModal} className="panel-title my-wallet-rec " id="endsubscript" style={{ margin: '0 0 10px', position: 'relative', display: 'inline-block', backgroundColor: '#d85b12', color: '#fff' }}>{uploadicon} Upload Template</a>

  </div>
</div>
 
 
    
</div>
</div>
                ):null}
               
              </div>     
  {/* import block */}

  <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb" }}>
                <div className="head" style={{ background: "#f5f5f5", padding: "1%" }} onClick={()=>displayBlock('import')}>
                  <h3 className="comaprison_hedding bulkuploadhead" >Bulk Upload Import - International <span className='bulkinfo' data-tooltip-id="import_tooltip" data-tooltip-content="For your international shipments arriving into the UAE ">?</span><span style={{float:'right'}} >+</span></h3>
                 </div>
                {showblock && showblock=='import'?(
 <div className="row" style={{ textAlign: "center", padding: "15px", margin: "15px"}} >
 <div className="col-md-12">
 <div className="row">
  <div className="col-md-6">
  <a href={require("../../../images/bulk_template/Bulk_upload_import.csv")} download="Bulk_import" className="panel-title my-wallet-rec " style={{ margin: '0 0 10px', position: 'relative', display: 'inline-block', backgroundColor: '#d85b12', color: '#fff' }}>{downloadicon} Download Template</a>

  </div>
  <div className="col-md-6">
  <a onClick={toggleModal} className="panel-title my-wallet-rec " id="endsubscript" style={{ margin: '0 0 10px', position: 'relative', display: 'inline-block', backgroundColor: '#d85b12', color: '#fff' }}>{uploadicon} Upload Template</a>

  </div>
</div>

</div>
</div>
                ):null}
               
              </div>
              {/* Domestic Block */}
              <div className="col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #c3bbbb" }}>
                <div className="head" style={{ background: "#f5f5f5", padding: "1%" }} onClick={()=>displayBlock('domestic')}>
                  <h3 className="comaprison_hedding bulkuploadhead">Bulk Upload Domestic <span className='bulkinfo' data-tooltip-id="domestic_tooltip" data-tooltip-content="For your domestic shipments within the UAE">?</span> <span style={{float:'right'}} >+</span></h3>
                 </div>
                {showblock && showblock=='domestic'?(
 <div className="row" style={{ textAlign: "center", padding: "15px", margin: "15px"}} >
 <div className="col-md-12">

 <div className="row">
  <div className="col-md-6">
    
  <a href={require("../../../images/bulk_template/Bulk_upload_domestic.csv")} download="Bulk_domestic" className="panel-title my-wallet-rec " style={{ margin: '0 0 10px', position: 'relative', display: 'inline-block', backgroundColor: '#d85b12', color: '#fff' }}>{downloadicon} Download Template</a>

  </div>
  <div className="col-md-6">
  <a onClick={toggleModal} className="panel-title my-wallet-rec " id="endsubscript" style={{ margin: '0 0 10px', position: 'relative', display: 'inline-block', backgroundColor: '#d85b12', color: '#fff' }}>{uploadicon} Upload Template</a>

  </div>
</div>

  </div>
</div>
                ):null}
              
              </div>
                   
</div>
<div className="col-md-4">
<img src={laptopcsv2} style={{width:'100%'}}/>
</div>

                </div>
           
            

            

              
              {
                finalprice && finalprice != "" ?(
                  <>      
                    <div id="admin-main-control" className="col-md-12 p-x-3 p-y-1" style={{marginTop:'3%'}}>
                      <div className="content-title m-x-auto">
                        <h6><b>My Parcels</b></h6>
                      
                      </div>

                      <table className="table table-bordered myparceltable">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">REFERENCE</th>
                            <th scope="col">SEND FROM</th>
                            <th scope="col">SEND TO</th>
                            <th scope="col">STATUS</th>
                            <th scope="col">WEIGHT</th>
                            <th scope="col">PRICE</th>
                            {/* <th scope="col">STATUS</th> */}
                            <th scope="col" colSpan='3'>QUICK SOLUTIONS</th>
                          </tr>
                        </thead>
                        <tbody>
                        
                          {
                            [...Array(parceldetailscount)].map((e,i)=><>{
                              parceldetailscount && parceldetailscount[i]!=''?(

                                <tr className={`box${parceldetailscontent[i]['orderid']}`} >
                                  <td>{parceldetailscontent[i]['orderid']}</td>
                                  <td>{parceldetailscontent[i]['shipper_country']}</td>
                                  <td>{parceldetailscontent[i]['consignee_country']}</td>
                                  <td>PENDING</td>
                                  <td>{parceldetailscontent[i]['final_weight']}</td>
                                  <td>{parceldetailscontent[i]['rate']}</td>
                                  <td>
                                    {/* <a onClick={()=>setModalIsOpenToTrue2(`${parceldetailscontent[i].orderid}`)} className="receipt"  style={{backgroundColor: '#2c407a', border: 'none',color: '#fff', fontSize: '20px', padding: '7px 9px', borderRadius: '6px'}}> upload invoice</a> */}
                                    
                                    {
                                      parceldetailscontent[i]['invoice_name'] && parceldetailscontent[i]['invoice_name'] != ""?(
                                        <>
                                          <a onClick={()=>setModalIsOpenToTrue44(`${parceldetailscontent[i].orderid}`)} className={`receipt${parceldetailscontent[i]['orderid']}`}  style={{backgroundColor: '#d85b12', border: 'none',color: '#fff', fontSize: '20px', padding: '7px 9px', borderRadius: '6px'}}> change invoice</a>
                                        </>
                                      ):(
                                        <>
                                          <a onClick={()=>setModalIsOpenToTrue44(`${parceldetailscontent[i].orderid}`)} className={`receipt${parceldetailscontent[i]['orderid']}`}  style={{backgroundColor: '#2c407a', border: 'none',color: '#fff', fontSize: '20px', padding: '7px 9px', borderRadius: '6px'}}> upload invoice</a>
                                        </>
                                      )
                                    }                                    
                                    </td>
                                  <td><input type="checkbox"  defaultChecked={isChecked} defaultValue={parceldetailscontent[i]['orderid']} onChange={(e)=>checkSelected(parceldetailscontent[i]['orderid'],parceldetailscontent[i]['rate'])}/></td>
                                </tr>
                              ):null

                            }</>)
                          }
                 
                          
                          <tr>
                            <td colSpan={6}><b>Total:</b><span style={{float:'right'}}>AED {finalprice}</span></td>
                            <td colSpan={2} className='bulkpay' onClick={()=>setModalIsOpenToTrue33(`${batch_id}`)} style={{background:'#d85b12'}}>PAY NOW</td>
                          </tr>
                        
                        </tbody>
                      </table>

                    </div>
                    
                  </>
                ):null
              }
              
            </div>





          </div>



        </div>
      </div>
    </div>
  )
}
